import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {caseSteps, legalNoticeAttributes, legalNoticeDescriptions, legalNoticeResults} from "../Data/optionsData";


/*{field: 'opponent',subField: "fullName", labelName: 'Karşı Taraf', type: "object", linkedTo: "id"},
{field: 'description', headerName: 'Tebligat İçeriği', type: "text", flex: 1, visible: true,},
{field: 'attribute', headerName: 'Tebligat Niteliği', type: "text", flex: 1, visible: true,},
{field: 'result', headerName: 'Sonuç', type: "text", flex: 1, visible: true,},
{field: 'legalNoticeDate', headerName: 'Tebliğ Tarihi', type: "date", flex: 1, visible: true,},
{field: 'barcode', headerName: 'Barkod No', type: "text", flex: 1, visible: true,},*/
function LegalNoticeCreate() {
  const legalNoticeId = new URLSearchParams(window.location.search).get('id')
  const caseFileId = new URLSearchParams(window.location.search).get('caseFileId')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const caseFileEditTemplate = {
    id: legalNoticeId,
    returnPage: "back",
    fields: [
      {field: 'opponentId', subField: "fullName", associatedFieldName: "opponent", labelName: 'Karşı Taraf', type: "object", objectName: "Opponent", required: true, visible: true},
      {field: 'description', labelName: 'Tebligat İçeriği', type: "searchable", required: true, options: legalNoticeDescriptions},
      {field: 'attribute', labelName: 'Tebligat Niteliği', type: "searchable", required: true, options: legalNoticeAttributes},
      {field: 'result', labelName: 'Sonuç', type: "searchable", required: false, options: legalNoticeResults},
      {field: 'legalNoticeDate', labelName: 'Tebliğ Tarihi', type: "date", required: false},
      {field: 'barcode', labelName: 'Barkod No', type: "text", required: false}
    ],
    defaultRecordValues:{
      clientId,
      caseFileId
    },
    query: {
      objectName: "LegalNotice",
      recordId: legalNoticeId,
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        },
        {
          objectName: "Opponent",
          fieldName: 'opponent'
        },
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={caseFileEditTemplate}/>

    </div>
  );
}

export default LegalNoticeCreate;
