import * as React from 'react';
import {useEffect, useState} from 'react';
import {Avatar, Fab, ListItemAvatar} from "@mui/material";
import apiService from "../api/apiService";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import AddIcon from "@mui/icons-material/Add";
import CrList from "../components/crud/CrList";

export default function UserManagement() {
  const userListTemplate = {
    subPageKey: "user",
    returnPage: "back",
    fields: [
      {field: 'fullName', headerName: 'Adı Soyadı', type: "text", flex: 1, visible: true, mobileHeader: "onlyName"},
      {field: 'username', headerName: 'Kullanıcı Adı', type: "text", flex: 1, visible: true},
      {field: 'disabled', headerName: 'Aktiflik', type: "disabled", flex: 1, visible: true},
      {field: 'group',subField: "name", headerName: 'Büro', type: "object", flex: 1, visible: true},
      {field: 'title', headerName: 'Görevi', type: "text", flex: 1, visible: true},
      {field: 'email', headerName: 'E-Posta', type: "text", flex: 1, visible: true},
      {field: 'phoneNumber', headerName: 'Telefon No', type: "text", flex: 1, visible: true},
      {field: 'role', headerName: 'Uygulama Rolü', type: "role", flex: 1, visible: true},

    ],
    query:{
      objectName: "User",
      order: [
        ['createdAt', 'DESC']
      ],
      include: [
        {
          objectName: "UserGroup",
          fieldName: 'group'
        }
      ]
    },
    messages: {
      pageHeader: "Personel Listesi ",
      newRecord: "Yeni Personel"
    },
    disabledActions: {
      remove: true,
      detail: true
    }
  }

  return (
    <>
      <CrList template={userListTemplate}/>
    </>
  );
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
