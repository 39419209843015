import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {caseSteps, legalNoticeDescriptions, legalNoticeStatus} from "../Data/optionsData";


function WitnessCreate() {
  const witnessId = new URLSearchParams(window.location.search).get('id')
  const caseFileId = new URLSearchParams(window.location.search).get('caseFileId')


  const witnessEditTemplate = {
    id: witnessId,
    returnPage: "back",
    fields: [
      {field: 'fullName', labelName: 'Tanık Adı Soyadı', type: "text", required: true, options: caseSteps},
      {field: 'tc', labelName: 'Tanık T.C. Kimlik Nı', type: "text", required: true, options: caseSteps},
      {field: 'legalNoticeStatus', labelName: 'Tanık Tebligat Bilgisi', type: "searchable", required: true, options: legalNoticeStatus},
      {field: 'witnessDate', labelName: 'Tanıklık Tarihi', type: "date", required: true},
      {field: 'witnessAddress', labelName: 'Tanık Adresi', type: "textarea", required: false},
      {field: 'note', labelName: 'Notlar', type: "textarea", required: false},
    ],
    defaultRecordValues:{
      caseFileId
    },
    query: {
      objectName: "Witness",
      recordId: witnessId,
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={witnessEditTemplate}/>

    </div>
  );
}

export default WitnessCreate;
