import * as React from 'react';
import {useState} from 'react';
import {Button, FormGroup, Paper, TextField} from "@mui/material";
import apiService from "../api/apiService";
import {publish} from "../util/eventBus";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import { sha512 } from 'js-sha512';


export default function Register() {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [rePassword, setRePassword] = useState("");
  let [officeName, setOfficeName] = useState("");
  let [fullName, setFullName] = useState("");
  let [taxNo, setTaxNo] = useState("");
  let [taxAuthority, setTaxAuthority] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  async function register(event) {
    event.preventDefault();
    const passRgxp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if(username.length < 5){
      publish("alert", {severity: "error", message: "Kullanıcı adı 5 karakter veya daha uzun olmalıdır."})
      return
    }else if(password !== rePassword){
      publish("alert", {severity: "error", message: "Girilen parolalar aynı değil."})
      return
    }else if(!passRgxp.test(password)) {
        publish("alert", {severity: "error", message: "Şifreniz en az 6 karakter olmalıdır. İçerisinde en az bir büyük harf, bir küçük harf ve bir rakam içermelidir."})
       return
      }

    //publish("loading", true);
    let registerRes = await apiService.register({username, password: sha512(password), officeName, fullName,taxNo,taxAuthority,phoneNumber, email})
    publish("loading", false);
    if (registerRes.status === 200 && !(registerRes.data.error)) {
      publish("alert", {severity: "info", message: "Kullanıcı başarıyla oluşturuldu şimdi giriş yapabilirsiniz."})
      navigate("/")
    } else if (registerRes.status === 200 && registerRes.data.payload === "USER_ALREADY_REGISTERED") {
      publish("alert", {severity: "error", message: "Belirtilen kullanıcı adı sistemde zaten kayıtlı. Lütfen başka bir kullanıcı adı deneyiniz"})
    } else {
      publish("alert", {severity: "error", message: "Bağlantı hatası"})
    }
  }

  return (
    <div style={{
      textAlign: "center",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    }}>
      <Paper elevation={3} sx={{width: {xs: "80%", sm: "50%", md: "40%"}, marginTop: {xs: "5%", sm: "5%", md: "5%"}}}
             style={{
               padding: 14,
               textAlign: "center",
               backgroundColor: "rgba(57,57,61,0.01)"
             }}>
        <img style={{width: "40%"}} src={"/logo192.png"}/>
        <h2>Yeni Kayıt</h2>

          <form style={{display: "flex", justifyContent: "center"}} onSubmit={register}>
            <Box sx={{width: {xs: '100%', sm: '80%', md: '80%', lg: '%80'}}}>
            <FormGroup style={{padding: 0, height: "100%", width: "100%", display: "flex", gap: 12}}>
              <TextField id="standard-basic" label="Büro Adı" variant="outlined"
                         onChange={(event) => {
                           setOfficeName(event.target.value);
                         }}
                         size="small"
                         required={true}
                         value={officeName}/>
              <TextField id="standard-basic" label="Ad Soyad / Kurum Adı" variant="outlined"
                         onChange={(event) => {
                           setFullName(event.target.value);
                         }}
                         size="small"
                         required={true}
                         value={fullName}/>
{/*              <TextField id="standard-basic" label="T.C. Kimlik No / Vergi No" variant="outlined"
                         onChange={(event) => {
                           setTaxNo(event.target.value);
                         }}
                         size="small"
                         required={true}
                         value={taxNo}/>
              <TextField id="standard-basic" label="Vergi Dairesi" variant="outlined"
                         onChange={(event) => {
                           setTaxAuthority(event.target.value);
                         }}
                         size="small"
                         value={taxAuthority}/>*/}
              <TextField id="standard-basic" label="Telefon Numarası" variant="outlined"
                         onChange={(event) => {
                           setPhoneNumber(event.target.value);
                         }}
                         size="small"
                         required={true}
                         value={phoneNumber}/>


              <TextField id="new-email" label="E-Posta" variant="outlined"
                         onChange={(event) => {
                           setEmail(event.target.value);
                         }}
                         required={true}
                         type="email"
                         size="small"
                         value={email}/>
              <TextField id="name" label="Kullanıcı Adı" variant="outlined"
                         onChange={(event) => {
                           setUsername(event.target.value);
                         }}

                         required={true}
                         size="small"
                         value={username}/>
              <TextField id="new-password" label="Şifre" variant="outlined" type="password"
                         onChange={(event) => {
                           setPassword(event.target.value);
                         }}
                         required={true}
                         size="small"
                         value={password}/>
              <TextField id="re-password" label="Şifre Tekrar" variant="outlined" type="password"
                         onChange={(event) => {
                           setRePassword(event.target.value);
                         }}
                         required={true}
                         size="small"
                         value={rePassword}/>

              <Button style={{height: 50, marginTop: 20}} type="submit" variant="contained">Kayıt Ol</Button>
            </FormGroup>
            </Box>
          </form>
      </Paper>
    </div>
);
}
