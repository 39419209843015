import * as React from 'react';
import {useEffect, useState} from 'react';
import {Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import apiService from "../api/apiService";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {publish} from "../util/eventBus";
import {sha512} from "js-sha512";

export default function CreateUser() {
  const id = new URLSearchParams(window.location.search).get('id')
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [role, setRole] = React.useState("USER");
  const [groups, setGroups] = React.useState([]);

  const [group, setGroup] = React.useState();
const activeOptions = [{key: false, value: "Aktif"}, {key: true, value: "Pasif"}];
  useEffect(() => {
    initGroup();
  }, []);


  useEffect(() => {
    initUser();
  }, [groups]);

  async function initUser() {
    if (id) {
      const userRes = await apiService.fetchUser(id);
      if (userRes.status === 200) {
        const user = userRes.data.payload;
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setFullName(user.fullName);
        setUsername(user.username);
        setPassword(user.password);
        setTitle(user.title);
        setDisabled(user.disabled);
        setEmail(user.email);
        setPhoneNumber(user.phoneNumber);
        setRole(user.role);
        setGroup(groups.find((g) => g.id === user.group.id));
      }
    } else {
      setGroup(groups[0]);
    }
  }

  async function initGroup() {
    const groupListRes = await apiService.fetchGroupList();
    if (groupListRes.status === 200) {
      setGroups(groupListRes.data.payload)
    }
  }

  async function createUser(event) {
    event.preventDefault();
    const passRgxp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

    if(username.length < 5){
      publish("alert", {severity: "error", message: "Kullanıcı adı 5 karakter veya daha uzun olmalıdır."})
      return
    }else if(!passRgxp.test(password)) {
      publish("alert", {severity: "error", message: "Şifre en az 6 karakter olmalıdır. İçerisinde en az bir büyük harf, bir küçük harf ve bir rakam içermelidir."})
      return
    }

    const user = {
      id: id,
      role: role,
      username: username,
      fullName: fullName,
      firstName: firstName,
      lastName: lastName,
      password: sha512(password),
      phoneNumber: phoneNumber,
      disabled: disabled,
      title: title,
      email: email,
      groupId: group?.id
    }

    if (!id) {
      const createUserRes = await apiService.createUser(user);
      if (createUserRes.status === 200 && !(createUserRes.data.error)) {
        navigate('/users')
      }else if (createUserRes.status === 200 && createUserRes.data.payload === "USERNAME_ALREADY_TAKEN") {
        publish("alert", {severity: "error", message: "Belirtilen kullanıcı adı sistemde zaten kayıtlı. Lütfen başka bir kullanıcı adı deneyiniz"})
      }
    } else {
      user.id = id;
      const editUserRes = await apiService.editUser(user);
      if (editUserRes.status === 200 && !(editUserRes.data.error)) {
        navigate('/users')
      }else if (editUserRes.status === 200 && editUserRes.data.payload === "USERNAME_ALREADY_TAKEN") {
        publish("alert", {severity: "error", message: "Belirtilen kullanıcı adı sistemde zaten kayıtlı. Lütfen başka bir kullanıcı adı deneyiniz"})
      }
    }
  }

  return (
    <form style={{display: "flex", justifyContent: "center"}} onSubmit={createUser}>
      <Box sx={{width: {xs: '100%', sm: '80%', md: '50%'}}}>
        <FormGroup
          style={{
            padding: 14,
            height: "100%",
            width: "100%",
            display: "flex",
            gap: 8
          }}>
          <h3 style={{
            textAlign: "center",
            padding: 0,
            margin: 0,
            marginTop: -4,
            marginBottom: 6
          }}>{!id ? "Yeni Personel Oluştur" : "Personel Düzenle"}</h3>


          <TextField required={true} id="create-name" label="İsim" variant="outlined"
                     onChange={(event) => {
                       setFullName(event.target.value);
                     }}
                     value={fullName}/>
          <TextField id="create-title" label="Görevi" variant="outlined"
                     onChange={(event) => {
                       setTitle(event.target.value);
                     }}
                     value={title}/>
          <TextField id="create-gsm" label="Telefon" variant="outlined"
                     onChange={(event) => {
                       setPhoneNumber(event.target.value);
                     }}
                     value={phoneNumber}/>
          <TextField required={true} id="create-usern" label="E-Posta" variant="outlined"
                     onChange={(event) => {
                       setEmail(event.target.value);
                     }}
                     type="email"
                     value={email}/>
          <TextField required={true} id="create-usern" label="Kullanıcı Adı" variant="outlined"
                     onChange={(event) => {
                       setUsername(event.target.value);
                     }}
                     value={username}/>
          <TextField required={!id} id="create-pass" label="Şifre" variant="outlined" type="password"
                     onChange={(event) => {
                       setPassword(event.target.value);
                     }}
                     value={password}/>

          {user.role === "ROOT_ADMIN" && <Autocomplete
            value={group || null}
            onChange={(event, newValue) => {
              setGroup(newValue);
            }}
            getOptionLabel={(option) => option.name}
            id="controllable-states-demo"
            options={groups}
            disableClearable
            sx={{width: "100%"}}
            renderInput={(params) => <TextField {...params} label="Büro"/>}
          />}

          <Autocomplete
            value={activeOptions.find((a)=>a.key === disabled) || null}
            onChange={(event, newValue) => {
              setDisabled(newValue.key);
            }}
            getOptionLabel={(option) => option.value}
            id="controllable-states-demo"
            options={activeOptions}
            disableClearable
            sx={{width: "100%"}}
            renderInput={(params) => <TextField {...params} label="Aktiflik"/>}
          />

          <FormControlLabel control={<Checkbox
            checked={role === "ADMIN" || role === "ROOT_ADMIN"}
            onChange={(event) => {
              setRole(event.target.checked ? "ADMIN" : "USER");
            }}
            inputProps={{'aria-label': 'controlled'}}
          />} label="Yönetici"/>


          <Button style={{height: 50, marginTop: 20}} variant="contained" type="submit"
          >{id ? "Düzenle" : "Oluştur"}</Button>

        </FormGroup>
      </Box>
    </form>
  );
}
