export const addressType = [
  {key: "Elektronik Tebligat Adresi"},
  {key: "Yurtiçi İkametgah Adresi"},
  {key: "Yurtiçi İşyeri Adresi"},
  {key: "Depo Adresi"},
  {key: "Askerlik Adresi"},
  {key: "Yurtdışı İkametgah Adresi"},
  {key: "Yurtdışı İşyeri Adresi"},
  {key: "Cezaevi Adresi"}
]
export const allowedCityAddressType = ['Yurtiçi İkametgah Adresi', 'Yurtiçi İşyeri Adresi', 'Depo Adresi', 'Askerlik Adresi', 'Cezaevi Adresi']

export const nationalityType = [
  {key: "T.C."},
  {key: "Yabancı Uyruklu"}
]

export const caseSteps = [
  {key: "DAVA DİLEKÇESİ"},
  {key: "TENSİP ZAPTI"},
  {key: "CEVAP DİLEKÇESİ"},
  {key: "CEVABA CEVAP DİLEKÇESİ"},
  {key: "İKİNCİ CEVAP DİLEKÇESİ"},
  {key: "ÖN İNCELEME ZAPTI"},
  {key: "ÖN İNCELEME DURUŞMASI/CELSE 1"},
  {key: "CELSE 1"},
  {key: "CELSE 2"},
  {key: "CELSE 3"},
  {key: "CELSE 4"},
  {key: "CELSE 5"},
  {key: "CELSE 6"},
  {key: "CELSE 7"},
  {key: "CELSE 8"},
  {key: "CELSE 9"},
  {key: "CELSE 10"},
  {key: "CELSE 11"},
  {key: "CELSE 12"},
  {key: "CELSE 13"},
  {key: "CELSE 14"},
  {key: "CELSE 15"},
  {key: "CELSE 16"},
  {key: "CELSE 17"},
  {key: "CELSE 18"},
  {key: "CELSE 19"},
  {key: "CELSE 20"},
  {key: "CELSE 21"},
  {key: "CELSE 22"},
  {key: "CELSE 23"},
  {key: "CELSE 24"},
  {key: "CELSE 25"},
  {key: "CELSE 26"},
  {key: "CELSE 27"},
  {key: "CELSE 28"},
  {key: "CELSE 29"},
  {key: "CELSE 30"},
  {key: "CELSE 31"},
  {key: "CELSE 32"},
  {key: "CELSE 33"},
  {key: "CELSE 34"},
  {key: "CELSE 35"},
  {key: "KARAR"},
  {key: "GEREKÇELİ KARAR"},
  {key: "İSTİNAF BAŞVURU DİLEKÇESİ"},
  {key: "İSTİNAF CEVAP DİLEKÇESİ"},
  {key: "İSTİNAF İLAMI"},
  {key: "İSTİNAF ONAMA"},
  {key: "İSTİNAF GERİ GÖNDERME"},
  {key: "YARGITAY BAŞVURU"},
  {key: "DANIŞTAY BAŞVURU"},
  {key: "YARGITAY KARAR İLAMI"},
  {key: "DANIŞTAY KARAR İLAMI"},
  {key: "ANAYASA MAHKEMESİ BAŞVURU DİLEKÇESİ"},
  {key: "YARGITAY ONAMA"},
  {key: "YARGITAY GERİ GÖNDERME"},
  {key: "DANIŞTAY ONAMA"},
  {key: "DANIŞTAY GERİ GÖNDERME"}
]

export const caseSpecialSteps = [
  {key: "Keşif"},
  {key: "Bilirkişi"},
  {key: "Islah"},
]

export const legalNoticeDescriptions = [
  {key: "Dava Dilekçesi"},
  {key: "Cevap Dilekçesi"},
  {key: "Cevaba Cevap Dilekçesi"},
  {key: "İkinci Cevap Dilekçesi"},
  {key: "Duruşma Zaptı"},
  {key: "Bilirkişi Raporu"},
  {key: "Islah Dilekçesi"},
  {key: "İstinaf Dilekçesi"},
]

export const legalNoticeAttributes = [
  {key: "İlk Tebligat"},
  {key: "İkinci Tebligat"},
  {key: "E-Tebligat"},
  {key: "21'e Göre"},
  {key: "35'e Göre"},
  {key: "Avukata Tebligat"},
]

export const legalNoticeResults = [
  {key: "Talep Hazırlanıyor"},
  {key: "Talep Gönderildi"},
  {key: "Tebligat Hazırlanıyor"},
  {key: "Yolda"},
  {key: "Tebliğ Oldu"},
  {key: "İade"},
]

export const legalNoticeStatus = [
  {key: "Tebliğ Yapıldı"},
  {key: "Tebliğ Yapılmadı"},
]

export const documentGeneralType = [
  {key: "Gelen Evrak"},
  {key: "Giden Evrak"},
]

export const documentType = [
  {key: "Çek"},
  {key: "Senet"},
  {key: "Sözleşme"},
]

export const caseFileType = [
  {key: "Dava"},
  {key: "İcra"},
  {key: "D.İş"},
  {key: "Talimat"},
  {key: "Soruşturma"},
]

export const taskType = [
  {key: "SAİR İŞLEM"},
  {key: "ARABULUCULUK - TOPLANTI"},
  {key: "CEZAEVİ - GÖRÜŞME"},
  {key: "GERİ GÖNDERME MERKEZİ"},
  {key: "İCRA DAİRESİ - DOSYA İNCELEME"},
  {key: "İCRA DAİRESİ - DOSYA KAPAK HESABI"},
  {key: "İCRA DAİRESİ - DOSYA KAPAMA"},
  {key: "İCRA DAİRESİ - HACİZ FEKKİ"},
  {key: "İCRA DAİRESİ - HACİZ İŞLEMİ"},
  {key: "İCRA DAİRESİ - HACİZ KATILIM"},
  {key: "İCRA DAİRESİ - KEŞİF KATILIM"},
  {key: "İCRA DAİRESİ - SAİR İŞLEM"},
  {key: "İCRA DAİRESİ - SATIŞ İŞLEMLERİ"},
  {key: "İCRA DAİRESİ - TEBLİĞ İŞLEMLERİ"},
  {key: "KOLLUK - DOSYA İNCELEME"},
  {key: "KOLLUK - İFADE"},
  {key: "KOLLUK - SAİR İŞLEM"},
  {key: "MAHKEME - DOSYA İNCELEME"},
  {key: "MAHKEME - DURUŞMA"},
  {key: "MAHKEME - KEŞİF  KATILIM"},
  {key: "MAHKEME - SAİR İŞLEM"},
  {key: "SAVCILIK - DOSYA İNCELEME"},
  {key: "SAVCILIK - SAİR İŞLEM"},
  {key: "SAVCILIK - SORGU"},
  {key: "SULH CEZA - DOSYA İNCELEME"},
  {key: "SULH CEZA - SAİR İŞLEM"},
  {key: "SULH CEZA - SORGU"},
]
