import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {subscribe} from "../util/eventBus";
import AlertM from '@mui/material/Alert';

export default function Alert() {
  let [alert, setAlert] = useState(undefined);

  useEffect(() => {
    subscribe("alert", (alert) => {
      setAlert(alert);
      setTimeout(()=>{
        setAlert(undefined)
      }, 7000)
    });
  }, []);

  if(!alert)
    return null;

  return (
    <Paper sx={{width: {xs: '100%', sm: '40%', md: '30%'}, flexShrink: {md: 0}}} style={{padding: 4, position: "absolute", top: 0, zIndex: 9999, right: 0}}>
      <AlertM variant="outlined" severity={alert.severity}>
        {alert.message}
      </AlertM>
    </Paper>
  );
}
