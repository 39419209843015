import * as React from "react";
import {useEffect, useState} from "react";
import {Button, DialogContent, DialogTitle, TextField, useMediaQuery} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {trTR} from "@mui/x-date-pickers/locales";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DialogActions from "@mui/material/DialogActions";
import {useDialog} from "../provider/DialogProvider";
import moment from "moment";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import apiService from "../api/apiService";
import {useNavigate} from "react-router-dom";

export default function EventDetail({options, onClose}) {
  const theme = useTheme();
  const [openDialog, closeDialog] = useDialog();
  const [open, setOpen] = useState(false);
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  let title = options.title || "";
  let startDate = options.start;
  let endDate = options.end;

  const close = () => {
    closeDialog()
    setOpen(false)
  }

  function showDetail() {
    navigate("/" + options.route + "?id=" + options.routeId)
  }

  function showCreateEdit() {
    openDialog({
      children: (
        <Box>
          <DialogTitle>{options.end ? "Hatırlatıcı Düzenle" : "Yeni Hatırlatıcı Ekle"}</DialogTitle>
          <DialogContent style={{
            width: greaterThanSm ? 500 : "100%",
            padding: 28,
            display: "flex",
            flexDirection: "column",
            gap: 24
          }}>
            <TextField key="eventTitle" required={true} id="eventTitle" label="Başlık"
                       variant="filled"
                       size="small"
                       defaultValue={title}
                       onChange={(event) => title = event.target.value}/>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr"
                                  localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DateTimePicker label="Başlangıç"
                              id="startDate"
                              value={dayjs(startDate)}
                              defaultValue={dayjs(startDate)}
                              closeOnSelect
                              size="small"
                              onChange={(newValue) => {
                                startDate = newValue;
                              }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr"
                                  localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DateTimePicker label="Bitiş"
                              id="endDate"
                              value={dayjs(endDate)}
                              defaultValue={dayjs(endDate)}
                              closeOnSelect
                              size="small"
                              onChange={(newValue) => {
                                endDate = newValue;
                              }}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={async () => {
              const body = {
                objectName: "Event",
                ids: [options.id],
              }
              const deleteResponse = await apiService.cmnDelete(body);
              if (deleteResponse.status === 200) {
                close();
                onClose();
              }
            }}>
              Sil
            </Button>
            <Button color="inherit" onClick={() => close()}>
              İptal
            </Button>
            <Button color="primary" onClick={async () => {
              if (!title)
                return

              if (!options.id) {
                const body = {
                  objectName: "Event",
                  recordValues: {
                    title: title,
                    start: startDate,
                    end: endDate
                  }
                }
                const createResponse = await apiService.cmnCreate(body);
                if (createResponse.status === 200) {
                  close();
                  onClose();
                }
              } else {
                const body = {
                  objectName: "Event",
                  recordId: options.id,
                  recordValues: {
                    title: title,
                    start: startDate,
                    end: endDate
                  }
                }
                const createResponse = await apiService.cmnEdit(body);
                if (createResponse.status === 200) {
                  close();
                  onClose();
                }
              }
            }}>
              {options.id ? "Düzenle" : "Oluştur"}
            </Button>
          </DialogActions>
        </Box>
      ),
    onClose: ()=> {
      close();
      onClose();
    }});
  }

  useEffect(() => {
    if (options && !open) {
      if (options.opType === "create" || (options.opType === "edit" && !options.type)) {
        startDate = options.start;
        if(!options.end)
          endDate = moment(options.start).add(60, 'minutes').toDate();
        setOpen(true)
        showCreateEdit()
      }else{
        showDetail()
      }
    }
  }, [options]);

  return (<></>);
}
