import * as React from "react";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CrList from "../components/crud/CrList";

function Warrants() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  const warrantListTemplate = {
    subPageKey: "warrant",
    returnPage: "warrats",
    fields: [
      {field: 'client',subField: "fullName", headerName: 'Müvekkil', type: "object", flex: 1, visible: true},
      {field: 'no', headerName: 'Vekaletname Belge No', type: "text", flex: 1, visible: true,},
      {field: 'notaryName', headerName: 'Noter Adı', type: "text", flex: 1, visible: true,},
      {field: 'wageNo', headerName: 'Yevmiye No', type: "text", flex: 1, visible: greaterThanSm,},
      {field: 'wageDate', headerName: 'Yevmiye Tarihi', type: "date", flex: 1, visible: greaterThanSm},
      {field: 'note', headerName: 'Note', type: "text", flex: 1, visible: greaterThanSm},
    ],
    query:{
      objectName: "Warrant",
      order: [
        ['notaryName', 'ASC']
      ],
      include: [
        {
          objectName: "Client",
          fieldName: 'client'
        }]
    },
    messages: {
      pageHeader: "Vekalet Listesi",
      newRecord: "Yeni Vekalet"
    }
  }

  return (
    <div>
      <CrList template={warrantListTemplate}/>

    </div>
  );
}

export default Warrants;
