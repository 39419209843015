import * as React from "react";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CrList from "../components/crud/CrList";

function ExpenseNotes() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  const expenseNoteListTemplate = {
    subPageKey: "expense-note",
    returnPage: "back",
    fields: [
      {field: 'date', headerName: 'Tarih', type: "date", flex: 1, visible: true,},
      {field: 'name', headerName: 'İşlem Adı', type: "text", flex: 1, visible: true,},
      {field: 'clients', parentFieldName: "caseFile", subField: "fullName", headerName: 'Müvekkil', type: "objectList", flex: 1, visible: true, mobileHeader: "onlyName", linkedTo: {path: "client",param: "id"}},
      {field: 'caseFile',subField: "officeFileNo", headerName: 'Ofis Dosya No', type: "object", flex: 1, visible: true, mobileHeader: "onlyName", linkedTo: "id"},
      {field: 'courtName',parentFieldName: "caseFile", headerName: 'Mahkeme Adı', type: "object", flex: 1, visible: true},
      {field: 'courtFileName',parentFieldName: "caseFile", headerName: 'Mahkeme Dosya No', type: "object", flex: .9, visible: true, mobileHeader: "onlyName", linkedTo: "id"},
      {field: 'transactionType', headerName: 'İşlem Aracı', type: "text", flex: 1, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
      {field: 'type', headerName: greaterThanSm ? 'İşlem Tipi' : "Tür", type: "text", flex: 1, visible: true,},
      {field: 'amount', headerName: 'Tutar', type: "currency", flex: 1, visible: true,},
    ],
    query:{
      objectName: "ExpenseNote",
      order: [
        ['createdAt', 'DESC']
      ],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile',
          include: [
            {
              objectName: "Client",
              fieldName: 'clients'
            }
          ]
        }
      ],
    },
    messages: {
      pageHeader: "Harcama Listesi",
      newRecord: "Yeni Harcama"
    }
  }

  return (
    <div>
      <CrList template={expenseNoteListTemplate}/>

    </div>
  );
}

export default ExpenseNotes;
