import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import apiService from "../api/apiService";
import {useNavigate} from "react-router-dom";

export default function Group() {
  const id = new URLSearchParams(window.location.search).get('id')
  const navigate = useNavigate();
  let [group, setGroup] = useState();


  async function init() {
    const groupRes = await apiService.fetchGroup(id);
    if (groupRes.status === 200) {
      setGroup(groupRes.data.payload);
    }
  }

  async function deleteGroup() {
    const groupDeleteRes = await apiService.deleteGroup(id);
    if (groupDeleteRes.status === 200) {
      navigate('/groups')
    }
  }

  useEffect(() => {
    init();
  }, []);

  if(!group)
    return undefined;

  return (
    <div style={{
      backgroundColor: "#fff",
      padding: 14,
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 8
    }}>
      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Grup Adı: </b>
        <span>{group.name}</span>
      </div>





      <Button style={{height: 50, marginTop: 20}} variant="contained" onClick={()=> window.location.assign('/createGroup?id=' + group?.id)}>Düzenle</Button>
      <Button style={{height: 50, marginTop: 20}} variant="outlined" color="error" onClick={deleteGroup}>Sil</Button>



    </div>
  );
}
