import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import Login from "./pages/Login";
import Alert from "./components/Alert";
import {trTR} from '@mui/material/locale';
import Loading from "./components/Loading";
import {AddToHomeScreen} from "react-pwa-add-to-homescreen";
import DialogProvider from "./provider/DialogProvider";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyADFN6idRn9RW3XAG8JKCp8KG1_QlQFGNo",
  authDomain: "avapp-1fe49.firebaseapp.com",
  projectId: "avapp-1fe49",
  storageBucket: "avapp-1fe49.appspot.com",
  messagingSenderId: "123274862235",
  appId: "1:123274862235:web:c674a8bf703f08d72cb03e",
  measurementId: "G-NDJ4Z5Z947"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#1976d2"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#1976d2"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1976d2",
          color: "rgba(255, 255, 255, 0.42);",
          borderRadius: 24,
          marginLeft: 10
        }
      },
    }
  }
},trTR);

const root = ReactDOM.createRoot(document.getElementById('root'));
const user = localStorage.getItem("user");

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <DialogProvider>
      {user ? <App/> : <Login/>}
      <Alert/>
      <Loading/>
{/*      <AddToHomeScreen skipFirstVisit={false} translate={
        {
          headline: "Uygulamayı Yükleyin",
          addHomeScreen: "Uygulamayı Yükleyin",
          bottomline: "",
          chromiumInstall: "Bu uygulamayı ana ekrana eklemek istiyorsanız alttaki butonu kullanabilirsiniz.",
          safariTapShare: '"Paylaş" butonuna tıklayın',
          safariAddHomeScreen: '"Ana Ekrana Ekle" seçeneğini seçin',
          buttonInstall: "YÜKLE"
        }
      } styles={{button: {backgroundColor : "#db0e15"}}} cookie={{expireDays: 1}}/>*/}
        </DialogProvider>
    </ThemeProvider>,
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
