import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {caseSpecialSteps} from "../Data/optionsData";

function CaseFileSpecialStepCreate() {
  const caseFileSpecialStepId = new URLSearchParams(window.location.search).get('id')
  const caseFileId = new URLSearchParams(window.location.search).get('caseFileId')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const caseFileEditTemplate = {
    id: caseFileSpecialStepId,
    returnPage: "back",
    fields: [
      {field: 'name', labelName: 'Özel Durum', type: "searchable", required: true, options: caseSpecialSteps},
      {field: 'decisionDate', labelName: 'Karar Tarihi', type: "date", required: true},
      {field: 'dueDate', labelName: 'Son Gün Tarihi', type: "date", required: true},
      {field: 'note', labelName: 'Notlar', type: "textarea", required: false}
    ],
    defaultRecordValues:{
      clientId,
      caseFileId
    },
    query: {
      objectName: "CaseFileSpecialStep",
      recordId: caseFileSpecialStepId,
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={caseFileEditTemplate}/>

    </div>
  );
}

export default CaseFileSpecialStepCreate;
