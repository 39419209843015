import axios from "axios";
import getAuthHeader from "./getAuthHeader";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL//'http://localhost:3003'//process.env.REACT_APP_API_URL, // <- ENV variable
});

apiClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
      },
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.request.responseURL.includes("check")
    ) {
      localStorage.clear();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const { get, post, put, patch, delete: destroy } = apiClient;
export { get, post, put, patch, destroy };
