import {CircularProgress} from "@mui/material";
import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {subscribe} from "../util/eventBus";

export default function Loading() {
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    subscribe("loading", (state) => {
      setLoading(state);
    });
  }, []);

  if (!loading)
    return null;

  return (
    <Paper sx={{color: '#30a269'}} style={{
      padding: 4,
      position: "absolute",
      top: 0,
      width: "100%",
      height: '100%',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.60)"
    }}>
      <CircularProgress color="inherit" size="5rem"/>
    </Paper>
  );
}
