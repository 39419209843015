import Box from "@mui/material/Box";
import {Button, Card, CardActions, CardContent, DialogContent, DialogTitle, Paper, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from "react";
import Divider from "@mui/material/Divider";
import apiService from "../api/apiService";
import DialogActions from "@mui/material/DialogActions";
import {publish} from "../util/eventBus";
import {useDialog} from "../provider/DialogProvider";
import {useNavigate} from "react-router-dom";
import ExpenseTotal from "./ExpenseTotal";

export default function MobileCardList({template, columns, rows}) {
  const navigate = useNavigate();
  const [openDialog, closeDialog] = useDialog();
  const [searchKey, setSearchKey] = React.useState("");
  const [expanded, setExpanded] = React.useState(undefined);
  const [listCount, setListCount] = React.useState(10);

  let list = rows?.filter((r)=>{
    for (const c of columns) {
      let addToList = false;
      if((c.valueGetter ? c.valueGetter({value: r[c.field]}):r[c.field])?.toLocaleString().toLowerCase().includes(searchKey.toLowerCase())){
        addToList = true;
        return true;
      }
    }
  })

  list = list.slice(0,listCount);


  return (
    <Box sx={{display: "flex", flexDirection:"column", justifyContent: "center", paddingBottom: 0}}>

      {template.subPageKey === 'expense-note' && <ExpenseTotal list={list}/>}
      <TextField id="searchKey" label="Ara"
                 variant="outlined"
                 size="small"
                 style={{backgroundColor: "#f1f1f1", width: "100%", marginBottom: 12}}
                 onChange={(event) => {
                   setSearchKey(event.target.value);
                 }}
                 value={searchKey}/>


      {list.map((row) => {
        return <Card sx={{minWidth: 275, marginBottom: 1.333, backgroundColor: "#eaf2ff",mb:1.3, '&:last-child': { mb: 0 }}}
                     onClick={() => {
/*                       if(!template.disabledActions.detail)
                        navigate(`/${template.subPageKey}?id=${row.id}&${template.subPageParams ? template.subPageParams : ""}`);*/
                       if (!template.disabledActions.detail) {
                         let subPageRelativeParams = "";
                         let subPageParams = template.subPageParams ? ("&" + template.subPageParams) : "";
                         template.subPageRelativeParams?.forEach((subPageRelativeParam) => {
                           subPageRelativeParams += ("&" + subPageRelativeParam.param + "=" + rows.find((r) => r.id === row.id)[subPageRelativeParam.field][subPageRelativeParam.prop])
                         })
                         navigate(`/${template.subPageKey}?id=${row.id}${subPageParams}${subPageRelativeParams}`);
                       }
                     }}>
          <CardContent sx={{position: "relative",pb:10, '&:last-child': { pb: 0 }}}>
            <IconButton style={{position: "absolute", right: 8, top: 2}} aria-label="more" onClick={async (e) => {
              e.stopPropagation();
              setExpanded(expanded === row.id ? undefined: row.id );
            }}>
              <MoreIcon style={{fill: "#000"}}/>
            </IconButton>
            {columns.filter((c)=> c.mobileHeader).map((column, i) => {
              if (column.mobileHeader === "onlyName") {
                return (<><div style={{display: "flex", flexDirection: "row", gap: 0, marginBottom: 8}}>
                  <span style={{minWidth: "9rem",maxWidth: "9rem" ,fontWeight: "bold", fontSize: 14}}>{column.headerName}:</span>
                  <span style={{fontWeight: "bold", fontSize: 17, textWrap: "nowrap"}}>
                    {column.renderCell ? column.renderCell({row, id: row.id}) : (column.valueGetter ? column.valueGetter({value: row[column.field], row, id: row.id}):row[column.field])?.toLocaleString()}
                  </span>

                </div><Divider/></>)
              }
            })}
            {columns.filter((c)=> !c.mobileHeader).map((column, i) => {
              return (<div style={{display: "flex", flexDirection: "row", gap: 0}}>
                <span style={{minWidth: "9rem",maxWidth: "9rem" ,fontWeight: "bold", fontSize: 14}}>{column.headerName}:</span>
                <span style={{fontWeight: "normal", marginRight: 20,fontSize: 14}}>
                    {column.renderCell ? column.renderCell({row, id: row.id}) : (column.valueGetter ? column.valueGetter({value: row[column.field], row, id: row.id}):row[column.field])?.toLocaleString()}
                </span>
              </div>)
            })}


            {expanded === row.id && <Paper style={{position: "absolute", right: 56, top: 0, display: "flex", flexDirection: "row", paddingRight: 8, paddingLeft: 8}}>
              {!template.disabledActions?.edit && <IconButton aria-label="edit" onClick={(e) => {
                e.stopPropagation();
                navigate(`/create-${template.subPageKey}?id=${row.id}`);
              }}>
                <EditIcon style={{fill: "#1976d2"}}/>
              </IconButton>}

              {!template.disabledActions?.remove && <IconButton aria-label="delete" onClick={async (e) => {
                e.stopPropagation();
                openDialog({
                  children: (
                    <>
                      <DialogTitle>Uyarı!</DialogTitle>
                      <DialogContent>
                        Kayıt kalıcı olarak silinecektir. <br/> Silmek istediğinizden emin misiniz?
                      </DialogContent>
                      <DialogActions>
                        <Button color="primary" onClick={() => closeDialog()}>
                          İptal
                        </Button>
                        <Button color="error" onClick={async () => {
                          closeDialog()
                          await apiService.cmnDelete({...(template.query), ids: [row.id]})
                          publish("refresh_"+template.query.objectName);
                        }}>
                          Sil
                        </Button>
                      </DialogActions>
                    </>
                  )
                });
              }}>
                <DeleteIcon style={{fill: "#e11506"}}/>
              </IconButton>}
            </Paper>}
          </CardContent>
        </Card>
      })}

      {(rows.length !== list.length && !searchKey) && <Button style={{height: 50, marginTop: 20}} size="small" variant="contained"
               onClick={() => setListCount(listCount + 10)}
               type="submit">Daha Fazla Görüntüle</Button>}
    </Box>
  );
}
