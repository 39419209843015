import * as React from 'react';
import CrDisplayOne from "../components/crud/CrDisplayOne";

export default function Warrant() {
  const warrantId = new URLSearchParams(window.location.search).get('id')

  const warrantFetchTemplate = {
    id: warrantId,
    subPageKey: "warrant",
    fields: [
      {field: 'no', labelName: 'Vekaletname Belge No', type: "text"},
      {field: 'notaryName', labelName: 'Noter Adı', type: "text"},
      {field: 'wageNo', labelName: 'Yevmiye No', type: "text"},
      {field: 'wageDate', labelName: 'Yevmiye Tarihi', type: "date"},
      {field: 'note', labelName: 'Note', type: "text"}
    ],
    query: {
      objectName: "Warrant",
      recordId: warrantId,
    },
    messages: {}
  }

  return (
    <div>

      <CrDisplayOne template={warrantFetchTemplate}/>

    </div>
  );
}
