import * as React from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import CrDisplayOne from "../components/crud/CrDisplayOne";
import CrList from "../components/crud/CrList";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import {allowedCityAddressType} from "../Data/optionsData";

export default function Opponent() {
  const opponentId = new URLSearchParams(window.location.search).get('id')

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const lessThanSm = useMediaQuery(theme.breakpoints.down("sm"));


  const opponentFetchTemplate1 = {
    id: opponentId,
    fields: [
      {field: 'createdAt', labelName: 'Kayıt Tarihi', type: "date"},
      {field: 'no', labelName: 'Müvekkil No', type: "text"},
      {field: 'type', labelName: 'Tür', type: "text"},
      {field: 'fullName', labelName: 'Adı Soyadı', type: "text", visible: {field: 'type', values: ['Şahıs']}},
      {field: 'fullName', labelName: 'Kurum Adı', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'tcTaxNo', labelName: 'TC No', type: "text", visible: {field: 'type', values: ['Şahıs']}},
      {field: 'tcTaxNo', labelName: 'TC No', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'nationality', labelName: 'Uyruk', type: "text", visible: {field: 'type', values: ['Şahıs']}},
      {field: 'mersisNo', labelName: 'MERSİS No', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'gsm1', labelName: 'Tel1', type: "text"},
      {field: 'gsm2', labelName: 'Tel2', type: "text"},
      {field: 'email', labelName: 'E-Posta', type: "text"},
      {field: 'note', labelName: 'Notlar', type: "textarea", hide: lessThanSm},
    ],
    query: {
      objectName: "Opponent",
      recordId: opponentId,
    },
    messages: {}
  }

  const opponentFetchTemplate2 = {
    id: opponentId,
    fields: [
      {
        sectionLabel: "Adres 1",
        fields:[
          {field: 'addressType1', labelName: 'Adres tipi', type: "text"},
          {field: 'city1', labelName: 'İl', type: "text"},
          {field: 'district1', labelName: 'İlçe', type: "text"},
          {field: 'address1', labelName: 'Adres', type: "text"},
        ]
      },
      {
        sectionLabel: "Adres 2",
        fields:[
          {field: 'addressType2', labelName: 'Adres tipi', type: "text"},
          {field: 'city2', labelName: 'İl', type: "text"},
          {field: 'district2', labelName: 'İlçe', type: "text"},
          {field: 'address2', labelName: 'Adres', type: "text"},
        ]
      },
      {
        sectionLabel: "Banka 1",
        fields:[
          {field: 'bankName1', labelName: 'Banka Adı', type: "text"},
          {field: 'iban1', labelName: 'IBAN', type: "text"},
        ]
      },
      {
        sectionLabel: "Banka 2",
        fields:[
          {field: 'bankName2', labelName: 'Banka Adı', type: "text"},
          {field: 'iban2', labelName: 'IBAN', type: "text"},
        ]
      },
      {
        sectionLabel: "Yetkili 1",
        fields:[
          {field: 'authorized_1_FullName', labelName: 'Adı Soyadı', type: "text"},
          {field: 'authorized_1_TC', labelName: 'TC Kimlik No', type: "text"},
          {field: 'authorized_1_GSM1', labelName: 'Tel1', type: "text"},
          {field: 'authorized_1_GSM2', labelName: 'Tel2', type: "text"},
          {field: 'authorized_1_addressType1', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_1_city1', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_1_district1', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_1_address1', labelName: 'Adres', type: "text"},
          {field: 'authorized_1_addressType2', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_1_city2', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_1_district2', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_1_address2', labelName: 'Adres', type: "text"}
        ]
      },
      {
        sectionLabel: "Yetkili 2",
        fields:[
          {field: 'authorized_2_FullName', labelName: 'Adı Soyadı', type: "text"},
          {field: 'authorized_2_TC', labelName: 'TC Kimlik No', type: "text"},
          {field: 'authorized_2_GSM1', labelName: 'Tel1', type: "text"},
          {field: 'authorized_2_GSM2', labelName: 'Tel2', type: "text"},
          {field: 'authorized_2_addressType1', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_2_city1', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_2_district1', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_2_address1', labelName: 'Adres', type: "text"},
          {field: 'authorized_2_addressType2', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_2_city2', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_2_district2', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_2_address2', labelName: 'Adres', type: "text"}
        ]
      },
      {field: 'note', labelName: 'Notlar', type: "textarea", hide: greaterThanSm},
    ],
    query: {
      objectName: "Opponent",
      recordId: opponentId,
    },
    messages: {}
  }

  return (
    <div>

      <Grid container spacing={0}>
        <Grid xs={12} sm={6} md={4} >
          <div><CrDisplayOne template={opponentFetchTemplate1}/></div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div><CrDisplayOne template={opponentFetchTemplate2}/></div>
        </Grid>
      </Grid>


    </div>
  );
}
