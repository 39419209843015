import * as React from "react";
import {DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter, trTR} from "@mui/x-data-grid";
import {useTheme} from "@mui/material/styles";
import {Button, DialogContent, DialogTitle, useMediaQuery} from "@mui/material";
import apiService from "../api/apiService";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDialog} from "../provider/DialogProvider";
import DialogActions from "@mui/material/DialogActions";
import {publish} from "../util/eventBus";
import {useNavigate} from "react-router-dom";
import ExpenseTotal from "./ExpenseTotal";

export default function DesktopDatagrid({template, columns, rows}) {
  const navigate = useNavigate();
  const [openDialog, closeDialog] = useDialog();
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  if (greaterThanMid) {
    if (!template.disabledActions?.edit)
      columns = columns.concat([
        {
          field: "Düzenle",
          type: "actions",
          headerName: "",
          flex: 0.1,
          visible: false,
          sortable: false,
          renderCell: (params) => {
            const onClick = (e) => {
              e.stopPropagation(); // don't select this row after clicking
              const row = params.row;
              navigate(`/create-${template.subPageKey}?id=${row.id}`);
            };

            return <IconButton onClick={onClick}><EditIcon style={{fill: "#1976d2"}}/></IconButton>;
          }
        }])

    if (!template.disabledActions?.remove)
      columns = columns.concat([{
        field: "Sil", type: "actions", headerName: "", flex: 0.1, sortable: false, renderCell: (params) => {
          const onDelete = async (e) => {
            e.stopPropagation();
            openDialog({
              children: (
                <>
                  <DialogTitle>Uyarı!</DialogTitle>
                  <DialogContent>
                    Kayıt kalıcı olarak silinecektir. <br/> Silmek istediğinizden emin misiniz?
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={() => closeDialog()}>
                      İptal
                    </Button>
                    <Button color="error" onClick={async () => {
                      closeDialog()
                      const row = params.row;
                      await apiService.cmnDelete({...(template.query), ids: [row.id]})
                      publish("refresh_"+template.query.objectName);
                    }}>
                      Sil
                    </Button>
                  </DialogActions>
                </>
              )
            });
          };

          return <IconButton style={{color: "red"}} onClick={onDelete}><DeleteIcon
            style={{fill: "#e11506"}}/></IconButton>;
        }
      }])
  }

  function MobileToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter/>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <DataGrid
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        slots={{toolbar: greaterThanSm ? GridToolbar : MobileToolbar}}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize: 20},
          },
        }}
        //rowHeight={40}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[20, 50, 100]}
        autoHeight
        //getRowHeight={() => 'auto'}
        isRowSelectable={() => {
          return !template.disabledActions?.detail
        }}
        rowSelection
        showCellVerticalBorder={false}
        onRowSelectionModelChange={(ids) => {
          if (!template.disabledActions?.detail) {
            let subPageRelativeParams = "";
            let subPageParams = template.subPageParams ? ("&" + template.subPageParams) : "";
            template.subPageRelativeParams?.forEach((subPageRelativeParam) => {
              subPageRelativeParams += ("&" + subPageRelativeParam.param + "=" + rows.find((r) => r.id === ids[0])[subPageRelativeParam.field][subPageRelativeParam.prop])
            })
            navigate(`/${template.subPageKey}?id=${ids[0]}${subPageParams}${subPageRelativeParams}`);
          }
        }}
        sx={{
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold !important', overflow: 'visible !important'
          },
          // disable cell selection style
          '.MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          // pointer cursor on ALL rows
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          }
        }}
      />
      {template.subPageKey === 'expense-note' && <ExpenseTotal list={rows}/>}
    </>
  );
}
