import * as React from 'react';
import CrDisplayOne from "../components/crud/CrDisplayOne";
import CrList from "../components/crud/CrList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Grid, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";

export default function CaseFile() {
  const caseFileId = new URLSearchParams(window.location.search).get('id')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const lessThanSm = useMediaQuery(theme.breakpoints.down("sm"));


  const caseFileFetchTemplate1 = {
    id: caseFileId,
    subPageKey: "case-file",
    fields: [
      {field: 'officeFileNo', labelName: 'Ofis Dosya No', type: "text"},
      {field: 'clients', subFields: [{field: 'fullName', type: 'object', linkedTo: {path: "client", param: "id"}}], labelName: 'Müvekkil', type: "objectList"},
      {
        field: 'opponents',
        subFields: [{field: 'fullName', type: 'object', linkedTo: {path: "opponent", param: "id"}}],
        labelName: 'Karşı Taraf',
        type: "objectList"
      },
      {field: 'city', labelName: 'İl', type: "text"},
      {field: 'tevziOffice', labelName: 'Tevzi Bürosu', type: "text"},
      {field: 'courtName', labelName: 'Mahkeme Adı', type: "text"},
      {field: 'courtFileName', labelName: 'Mahkeme Dosya No', type: "text"},
      {field: 'type', labelName: 'Dava Türü', type: "text"},
      {field: 'createdAt', labelName: 'İlk Kayıt Tarihi', type: "date"},
      {field: 'updatedAt', labelName: 'Son İşlem Tarihi', type: "date"},
      {field: 'note', labelName: 'Notlar', type: "textarea", hide: lessThanSm},
    ],
    query: {
      objectName: "CaseFile",
      order: [['createdAt', 'DESC']],
      recordId: caseFileId,
      include: [
        {
          objectName: "Client",
          fieldName: 'clients'
        },
        {
          objectName: "Opponent",
          fieldName: 'opponents'
        }
      ]
    },
    messages: {}
  }

  const caseFileFetchTemplate2 = {
    id: caseFileId,
    subPageKey: "case-file",
    fields: [
      {field: 'courtDateSteps', labelName: 'Son Aşama', type: "objectList", subFields: [{field: 'name', type: 'text'}, {field: 'date', type: 'date'}]},
/*
      {field: 'addedSpecialSteps', labelName: 'Özel Durum', type: "objectList", subFields: [{field: 'name', type: 'text'}, {field: 'dueDate', type: 'date'}]},
*/
      {field: 'addedTasks', labelName: 'Görev', type: "objectList", subFields: [{field: 'taskDefinition', type: 'text'}, {field: 'date', type: 'date'}]},
      {
        field: 'caseFiles',
        subFields: [{field: 'courtName', type: 'object', linkedTo: {path: "case-file", param: "id", selfParam: true}},
          {field: 'courtFileName', type: 'object', linkedTo: {path: "case-file", param: "id", selfParam: true}}],
        labelName: 'Bağlı Dosya',
        type: "objectList"
      },
/*      {
        field: 'cCaseFiles',
        subFields: [{field: 'courtName', type: 'object', linkedTo: {path: "case-file", param: "id", selfParam: true}},
          {field: 'courtFileName', type: 'object', linkedTo: {path: "case-file", param: "id", selfParam: true}}],
        labelName: null,
        type: "objectList"
      },*/
      {field: 'note', labelName: 'Notlar', type: "textarea", hide: greaterThanSm},
    ],
    query: {
      objectName: "CaseFile",
      order: [['createdAt', 'DESC']],
      recordId: caseFileId,
      include: [
        {
          objectName: "CaseFileStep",
          fieldName: 'courtDateSteps'
        },
        {
          objectName: "CaseFileSpecialStep",
          fieldName: 'addedSpecialSteps'
        },
        {
          objectName: "Task",
          fieldName: 'addedTasks'
        },
        {
          objectName: "CaseFile",
          fieldName: 'caseFiles',
          as: 'caseFiles'
        },
        {
          objectName: "CaseFile",
          fieldName: 'cCaseFiles',
          as: 'caseFiles'
        }
      ]
    },
    messages: {}
  }


  const caseFileStepsListTemplate = {
    subPageKey: "case-file-step",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'createdAt', headerName: 'Kayıt Tarihi', type: "datetime", flex: 1, visible: true,},
      {field: 'name', headerName: 'Dosya Aşaması', type: "text", flex: 1, visible: true,},
      {field: 'date', headerName: 'Aşama Tarihi', type: "datetime", flex: 1, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
      {field: 'caseFileCardId', relatedValueFieldName: 'caseFile', relatedValueSubFieldName: 'id', headerName: 'Dosya Kartına Ekle', type: "switch", flex: 1, visible: true,},
      {field: 'addToCalendar', headerName: 'Takvime Ekle', type: "switchTrueFalse", flex: 1, visible: true,},
    ],
    query: {
      objectName: "CaseFileStep",
      order: [['createdAt', 'DESC']],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Dosya Hakkında",
      newRecord: "Yeni Kayıt"
    },
    disabledActions: {
      detail: true
    }
  }


  const caseFileSpecialStepsListTemplate = {
    subPageKey: "case-file-special-step",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'name', headerName: 'Özel Durum', type: "text", flex: 1, visible: true,},
      {field: 'decisionDate', headerName: 'Karar Tarihi', type: "date", flex: 1, visible: true,},
      {field: 'dueDate', headerName: 'Son Gün Tarihi', type: "date", flex: 1, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
      {field: 'caseFileCardId', relatedValueFieldName: 'caseFile', relatedValueSubFieldName: 'id', headerName: 'Dosya Kartına Ekle', type: "switch", flex: 1, visible: true,},
      {field: 'addToCalendar', headerName: 'Takvime Ekle', type: "switchTrueFalse", flex: 1, visible: true,},
    ],
    query: {
      objectName: "CaseFileSpecialStep",
      order: [['createdAt', 'DESC']],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Özel Durum Aşamaları",
      newRecord: "Yeni Aşama"
    },
    disabledActions: {
      detail: true
    }
  }


  const legalNoticeListTemplate = {
    subPageKey: "legal-notice",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'opponent', subField: "fullName", headerName: 'Karşı Taraf', type: "object", flex: 1, visible: true, linkedTo: "id"},
      {field: 'description', headerName: 'Tebligat İçeriği', type: "text", flex: 1, visible: true,},
      {field: 'attribute', headerName: 'Tebligat Niteliği', type: "text", flex: 1, visible: true,},
      {field: 'result', headerName: 'Sonuç', type: "text", flex: 1, visible: true,},
      {field: 'legalNoticeDate', headerName: 'Tebliğ Tarihi', type: "date", flex: 1, visible: true,},
      {field: 'barcode', headerName: 'Barkod No', type: "barcode", flex: 1, visible: true,},
    ],
    query: {
      objectName: "LegalNotice",
      order: [['createdAt', 'DESC']],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        },
        {
          objectName: "Opponent",
          fieldName: 'opponent'
        },
      ],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Tebligat Bilgileri",
      newRecord: "Yeni Tebligat"
    },
    disabledActions: {
      detail: true
    }
  }


  const caseTaskListTemplate = {
    subPageKey: "task",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'createdAt', headerName: 'Kayıt Tarihi', type: "datetime", flex: 1, visible: true,},
      {field: 'taskDefinition', headerName: 'Görev Tanımı', type: "text", flex: 1, visible: true,},
      {field: 'date', headerName: 'Görevin Yapılacağı Tarih', type: "datetime", flex: 1, visible: true,},
      {field: 'dueDate', headerName: 'Görev Son Gün', type: "date", flex: 1, visible: true,},
      {field: 'description', headerName: 'Görev Detayı', type: "text", flex: 1, visible: true,},
      {field: 'appointer', headerName: 'Görevlendiren Kişi', type: "text", flex: 1, visible: true,},
      {field: 'responsible', headerName: 'Görevli Kişi', type: "text", flex: 1, visible: true,},
      {field: 'status', headerName: 'Görev Kontrol', type: "switchTrueFalse", flex: .6, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
      {field: 'caseFileCardId', relatedValueFieldName: 'caseFile', relatedValueSubFieldName: 'id', headerName: 'Dosya Kartına Ekle', type: "switch", flex: 1, visible: true,},
      {field: 'addToCalendar', headerName: 'Takvime Ekle', type: "switchTrueFalse", flex: 1, visible: true,},
    ],
    query: {
      objectName: "Task",
      order: [['createdAt', 'DESC']],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Görev Bilgileri",
      newRecord: "Yeni Görev"
    },
    disabledActions: {
      detail: true
    }
  }


  const witnessListTemplate = {
    subPageKey: "witness",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'fullName', headerName: 'Tanık Adı Soyadı', type: "text", flex: 1, visible: true,},
      {field: 'tc', headerName: 'Tanık TC Kimlik No', type: "text", flex: 1, visible: true,},
      {field: 'legalNoticeStatus', headerName: 'Tanık Tebligat Bilgisi', type: "text", flex: 1, visible: true,},
      {field: 'witnessDate', headerName: 'Tanıklık Tarihi', type: "date", flex: 1, visible: true,},
      {field: 'witnessAddress', headerName: 'Tanık Adresi', type: "text", flex: 1, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,}
    ],
    query: {
      objectName: "Witness",
      order: [['createdAt', 'DESC']],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Tanık Bilgileri",
      newRecord: "Yeni Tanık"
    },
    disabledActions: {
      detail: true
    }
  }


  const documentListTemplate = {
    subPageKey: "document",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'no', headerName: 'Evrak No', type: "text", flex: 1, visible: true,},
      {field: 'date', headerName: 'Evrak Kayıt Tarihi', type: "date", flex: 1, visible: true,},
      {field: 'type', headerName: 'Türü', type: "text", flex: 1, visible: true,},
      {field: 'receiver', headerName: 'Teslim Alan', type: "text", flex: 1, visible: true,},
      {field: 'receiverTC', headerName: 'TC No', type: "text", flex: 1, visible: true,},
      {field: 'deliverer', headerName: 'Teslim Eden', type: "text", flex: 1, visible: true,},
      {field: 'delivererTC', headerName: 'TC No', type: "text", flex: 1, visible: true,},
      {field: 'docType', headerName: 'Evrak Türü', type: "text", flex: 1, visible: true,},
      {field: 'documentTopic', headerName: 'Evrak Konusu', type: "text", flex: 1, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
    ],
    query: {
      objectName: "Document",
      order: [['createdAt', 'DESC']],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Evraklar",
      newRecord: "Yeni Evrak"
    },
    disabledActions: {
      detail: true
    }
  }


  const expenseNoteListTemplate = {
    subPageKey: "expense-note",
    subPageParams: (caseFileId) ? "caseFileId=" + caseFileId : "",

    returnPage: "back",
    fields: [
      {field: 'date', headerName: 'Tarih', type: "datetime", flex: 1, visible: true,},
      {field: 'name', headerName: 'İşlem Adı', type: "text", flex: 1, visible: true,},
      {field: 'transactionType', headerName: 'İşlem Aracı', type: "text", flex: 1, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
      {field: 'type', headerName: 'İşlem Tipi', type: "text", flex: 1, visible: true,},
      {field: 'amount', headerName: 'Tutar', type: "text", flex: 1, visible: true,},
    ],
    query: {
      objectName: "ExpenseNote",
      order: [['createdAt', 'DESC']],
      include: [],
      where: {
        caseFileId
      }
    },
    messages: {
      sectionHeader: "Dosya Hesap Defteri",
      newRecord: "Yeni Kayıt"
    },
    disabledActions: {
      detail: true
    }
  }


  const listTemplates = [
    caseFileStepsListTemplate,
    caseTaskListTemplate,
    //caseFileSpecialStepsListTemplate,
    legalNoticeListTemplate,
    witnessListTemplate,
    documentListTemplate,
    expenseNoteListTemplate
  ]

  return (
    <div>

      <Grid container spacing={0}>
        <Grid xs={12} sm={6} md={4}>
          <div><CrDisplayOne template={caseFileFetchTemplate1}/></div>
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <div><CrDisplayOne template={caseFileFetchTemplate2}/></div>
        </Grid>
      </Grid>

      <br/>
      {listTemplates.map((listTemplate) => {
        return <Accordion
          defaultExpanded={JSON.parse(sessionStorage.getItem('epanded_list_casefile'))?.[listTemplate.subPageKey]}
          onChange={(e, expanded) => {
            if (e !== null) {
              sessionStorage.setItem('epanded_list_casefile', JSON.stringify({
                ...JSON.parse(sessionStorage.getItem('epanded_list_casefile')),
                [listTemplate.subPageKey]: expanded
              }));
            }
          }}>
          <AccordionSummary sx={{
            backgroundColor: "#e8eff9",
            '.MuiAccordionSummary-content.Mui-expanded': {
              margin: 1
            },
            '.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '40px'
            },
          }}
                            style={{height: 40, minHeight: 40}}
                            expandIcon={<ExpandMoreIcon/>}
                            id={listTemplate.subPageKey}
          >
            <span style={{fontWeight: "bold", fontSize: 20}}>{listTemplate.messages.sectionHeader}</span>
          </AccordionSummary>
          <AccordionDetails style={{padding: 0}}>
            <Box>

              <CrList template={listTemplate}/>
            </Box>
          </AccordionDetails>
        </Accordion>
      })}
    </div>
  );
}
