import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import apiService from "../api/apiService";
import Divider from "@mui/material/Divider";
import {publish} from "../util/eventBus";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {caseSteps, documentGeneralType, documentType} from "../Data/optionsData";

export default function CreateGroup() {
  const id = new URLSearchParams(window.location.search).get('id')
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [taxAuthority, setTaxAuthority] = useState("");
  const [taxNo, setTaxNo] = useState("");


  const userGroupEditTemplate = {
    id: id,
    returnPage: "back",
    fields: [
      {field: 'name', labelName: 'Büro Adı', type: "text", required: true},
      {field: 'totalAllowedUser', labelName: 'Max Kullanıcı', type: "text", required: true},
      {field: 'subscriptionStart', labelName: 'Üyelik Başlangıç', type: "date", required: true},
      {field: 'subscriptionEnd', labelName: 'Üyelik Bitiş', type: "date", required: true},
      {field: 'taxAuthority', labelName: 'Vergi Dairesi', type: "text", required: true},
      {field: 'taxNo', labelName: 'Vergi No', type: "text", required: true},
    ],
    defaultRecordValues:{
    },
    query: {
      objectName: "UserGroup",
      recordId: id,
      include: [
        {
          objectName: "User",
          fieldName: 'users'
        }
      ]
    },
    messages: {
    }
  }

  return (
<>
<CrCreateEdit template={userGroupEditTemplate}/>
</>
  );
}
