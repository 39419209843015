import * as React from 'react';
import {useState} from 'react';
import {Button, Paper, TextField} from "@mui/material";
import apiService from "../api/apiService";
import {publish} from "../util/eventBus";
import {useLocation, useNavigate} from "react-router-dom";
import Register from "./Register";
import {sha512} from "js-sha512";

export default function Login() {
  let [username, setUsername] = useState([]);
  let [password, setPassword] = useState([]);
  let [smsPassword, setSmsPassword] = useState(undefined);
  let [smsInput, setSmsInput] = useState(false);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  async function login() {
    publish("loading", true);
    let loginRes = await apiService.login({username, password: sha512(password), smsPassword})
    publish("loading", false);
    if (loginRes.status === 200 && !(loginRes.data.error)) {
      localStorage.setItem("user", JSON.stringify(loginRes.data.payload.user))
      if (loginRes.data.payload.user.fsk)
        localStorage.setItem("fsk", JSON.stringify(loginRes.data.payload.user.fsk));
      localStorage.setItem("token", loginRes.data.payload.token)
      window.location.reload();
    } else if (loginRes.status === 200 && loginRes.data.error === "INCORRECT_USERNAME_OR_PASSWORD") {
      publish("alert", {severity: "error", message: "Hatalı kullanıcı adı veya şifre"})
    } else if (loginRes.status === 200 && loginRes.data?.payload === "TOO_MANY_PASSWORD_ATTEMPTS") {
      publish("alert", {severity: "error", message: "Çok fazla hatalı giriş yaptınız 5 dakika sonra tekrar deneyiniz"})
    } else if (loginRes.status === 200 && loginRes.data?.payload === "NO_PHONE_NUMBER") {
      publish("alert", {severity: "error", message: "Sistemde kayıtlı cep telefonunuz bulunmamaktadır."})
    }else if (loginRes.status === 200 && loginRes.data?.payload === "INCORRECT_SMS_PASSWORD") {
      publish("alert", {severity: "error", message: "Girilen SMS şifresi hatalı."})
      setSmsInput(false)
      setSmsPassword(undefined)
    }else if (loginRes.status === 200 && loginRes.data?.payload === "SMS_MESSAGE_SENT") {
      setSmsInput(true)
    } else {
      publish("alert", {severity: "error", message: "Bağlantı hatası"})
    }
  }
  if(pathname === '/register')
    return (<Register/>)

  return (
    <div style={{
      textAlign: "center",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    }}>
      <Paper elevation={3} sx={{width: {xs: "80%", sm: "50%", md: "30%"}, marginTop: {xs: "30%", sm: "25%", md: "10%"}}}
             style={{
               padding: 14,
               textAlign: "center",
               backgroundColor: "rgba(57,57,61,0.01)"
             }}>
        <img style={{width: "40%"}} src={"/logo192.png"}/>
        <h2>AvuPort</h2>
        <h4>AvuPort</h4>
        <div style={{
          textAlign: "center",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 12
        }}>
          {!smsInput && <>
            <TextField id="standard-basic" label="Kullanıcı Adı" variant="outlined"
                       onChange={(event) => {
                         setUsername(event.target.value);
                       }}
                       value={username}/>
            <TextField id="standard-basic" label="Şifre" variant="outlined" type="password"
                       onChange={(event) => {
                         setPassword(event.target.value);
                       }}
                       value={password}/>
          </>}

          {smsInput && <>
            <span>Lütfen cep telefonunuza gelen şifreyi giriniz</span>
            <TextField id="sms-password" label="SMS Şifresi" variant="outlined" type="outlined"
                       onChange={(event) => {
                         setSmsPassword(event.target.value);
                       }}
                       value={smsPassword}/>
          </>}
          <Button style={{height: 50, marginTop: 20}} variant="contained" onClick={login}>Giriş Yap</Button>
          <Button style={{height: 50, marginTop: 20}} variant="contained" onClick={()=>navigate('/register')}>Kayıt Ol</Button>
        </div>
      </Paper>
    </div>
  );
}
