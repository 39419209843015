import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {subscribe} from "../util/eventBus";
import AlertM from '@mui/material/Alert';
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";

export default function ExpenseTotal({list}) {
  let [values, setValues] = useState({
    expense: 0,
    income: 0,
    total: 0
  });
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    let expense = Number(0);
    let income = Number(0);
    list.forEach((i)=>{
      if(i.type.toLowerCase().includes("gelir") || i.type.toLowerCase().includes("GELİR".toLowerCase())){
        income += Number(i.amount)
      } else if(i.type.toLowerCase().includes("gider") || i.type.toLowerCase().includes("GİDER".toLowerCase())){
        expense += Number(i.amount)
      }
    });

    setValues({
      income, expense, total: income-expense
    })
  }, [list]);


  return (
    <div style={{display: 'flex', flexDirection: 'column',justifyContent: "right", alignContent: 'right', width: '100%', marginTop: 16, gap: 0, flexWrap: "wrap-reverse", marginBottom:8}}>
      <div style={{height: 30,width: greaterThanSm ? 250 : '%100', display: 'flex', flexDirection: 'row'}}>
        <span style={{width: greaterThanSm ? 130 : '40%', fontWeight: "bold", display: 'block', paddingRight: 8, border: "1px solid #e0e0e0", padding: 4}}>Toplam Gelir</span>
        <span style={{width: greaterThanSm ? 120:'60%',textAlign: "right",fontWeight: "bold", border: "1px solid #e0e0e0", padding: 4}}>{values.income.toLocaleString()}</span>
      </div>
      <div style={{height: 30,width: greaterThanSm ? 250 : '%100', display: 'flex', flexDirection: 'row'}}>
        <span style={{width: greaterThanSm ? 130 : '40%', display: 'block',fontWeight: "bold", paddingRight: 8, border: "1px solid #e0e0e0", padding: 4}}>Toplam Gider</span>
        <span style={{width: greaterThanSm ? 120:'60%',textAlign: "right", fontWeight: "bold", border: "1px solid #e0e0e0", padding: 4}}>{values.expense.toLocaleString()}</span>
      </div>
      <div style={{height: 30,width: greaterThanSm ? 250 : '%100', display: 'flex', flexDirection: 'row'}}>
        <span style={{width: greaterThanSm ? 130 : '40%', fontWeight: "bold", display: 'block', paddingRight: 8, border: "1px solid #e0e0e0", padding: 4}}>Toplam</span>
        <span style={{width: greaterThanSm ? 120:'60%',textAlign: "right",fontWeight: "bold", border: "1px solid #e0e0e0", padding: 4}}>{values.total.toLocaleString()}</span>
      </div>

    </div>
  );
}
