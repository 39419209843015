import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {cities} from "../Data/cityData";
import {allowedCityAddressType, caseFileType} from "../Data/optionsData";


function CaseFileCreate() {
  const caseFileId = new URLSearchParams(window.location.search).get('id')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const caseFileEditTemplate = {
    id: caseFileId,
    returnPage: "back",
    fields: [
      //{field: 'clientId', subField: "fullName", associatedFieldName: "client", labelName: 'Müvekkil', type: "object", objectName: "Client", required: true, visible: clientId === null},
      {field: 'officeFileNo', labelName: 'Ofis Dosya No', type: "text", required: true},
      {field: 'clientId', subField: "fullName", associatedFieldName: "clients", labelName: 'Müvekkil', type: "objectList", objectName: "Client", required: true, visible: clientId === null},
      {field: 'opponentId', subField: "fullName", associatedFieldName: "opponents", labelName: 'Karşı Taraf', type: "objectList", objectName: "Opponent", required: true, visible: true},
      {field: 'tevziOffice', labelName: 'Tevzi Bürosu', type: "text"},
      {field: 'city', labelName: 'İl', type: "autocomplete", options: cities},
      {field: 'type', labelName: 'Tür', type: "autocomplete", required: true, options: caseFileType},
      {field: 'courtName', labelName: 'Mahkeme Adı', type: "text", required: false, visible: {field: 'type', values: ['Dava', 'D.İş', 'Talimat', 'Soruşturma']}},
      {field: 'courtFileName', labelName: 'Mahkeme Dosya No', type: "text", required: false, visible: {field: 'type', values: ['Dava', 'D.İş', 'Talimat', 'Soruşturma']}},
      {field: 'courtName', labelName: 'İcra Dairesi', type: "text", required: false, visible: {field: 'type', values: ['İcra']}},
      {field: 'courtFileName', labelName: 'İcra Dairesi Birimi', type: "text", required: false, visible: {field: 'type', values: ['İcra']}},
      {field: 'note', labelName: 'Notlar', type: "textarea", required: false, placeholder: "Lütfen dosya hakkındaki özel notlarınızı bu alana yazınız."},
      {field: 'childCaseFileId', subField: "courtName", additionalField: "courtFileName", associatedFieldName: "caseFiles", labelName: 'Bağlı Dosyalar', type: "objectList", objectName: "CaseFile", required: false, visible: caseFileId !== null},
    ],
    defaultRecordValues:{

    },
    defaultAssociationValues:{
      Client: [clientId]
    },
    query: {
      objectName: "CaseFile",
      recordId: caseFileId,
      include: [
        {
          objectName: "Client",
          fieldName: 'clients'
        },
        {
          objectName: "Opponent",
          fieldName: 'opponents'
        },
        {
          objectName: "CaseFile",
          fieldName: 'caseFiles',
          as: 'caseFiles'
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={caseFileEditTemplate}/>

    </div>
  );
}

export default CaseFileCreate;
