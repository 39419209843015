import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";


function WarrantCreate() {
  const warrantId = new URLSearchParams(window.location.search).get('id')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const warrantEditTemplate = {
    id: warrantId,
    returnPage: "back",
    fields: [
      {field: 'clientId', subField: "fullName", associatedFieldName: "client", labelName: 'Müvekkil', type: "object", objectName: "Client", required: true, visible: clientId === null},
      {field: 'no', labelName: 'Vekaletname Belge No', type: "text", required: true},
      {field: 'notaryName', labelName: 'Noter Adı', type: "text", required: true},
      {field: 'wageNo', labelName: 'Yevmiye No', type: "text", required: true},
      {field: 'wageDate', labelName: 'Yevmiye Tarihi', type: "date", required: true},
      {field: 'note', labelName: 'Note', type: "textarea", required: false}
    ],
    defaultRecordValues:{
      clientId
    },
    query: {
      objectName: "Warrant",
      recordId: warrantId,
      include: [
        {
          objectName: "Client",
          fieldName: 'client'
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={warrantEditTemplate}/>

    </div>
  );
}

export default WarrantCreate;
