import * as React from 'react';
import CrDisplayOne from "../components/crud/CrDisplayOne";

export default function ExpenseNote() {
  const expenseNoteId = new URLSearchParams(window.location.search).get('id')

  const expenseNoteFetchTemplate = {
    id: expenseNoteId,
    subPageKey: "expense-note",
    fields: [
      {field: 'type', labelName: 'İşlem Tipi', type: "text"},
      {field: 'name', labelName: 'İşlem Adı', type: "text"},
      {field: 'date', labelName: 'Tarih', type: "date"},
      {field: 'transactionType', labelName: 'İşlem Aracı', type: "text"},
      {field: 'amount', labelName: 'Tutar', type: "text"}
    ],
    query: {
      objectName: "ExpenseNote",
      recordId: expenseNoteId,
    },
    messages: {}
  }

  return (
    <div>

      <CrDisplayOne template={expenseNoteFetchTemplate}/>

    </div>
  );
}
