import { get, post, put } from "./axiosClient";

const apiService = {
  login: (credentials) => post("/auth", credentials),
  register: (body) => post("/auth/register", body),
  fetchUserList: () => get("/user/list"),
  fetchUser: (id) => get("/user/" + id),
  createUser: (user) => post("/user", user),
  editUser: (user) => put("/user", user),
  deleteUser: (id) => post("/user/deleteAll", [id]),
  fetchGroupList: () => get("/group/list" ),
  fetchGroup: (id) => get("/group/" + id ),
  deleteGroup: (id) => post("/group/deleteAll", [id]),
  createGrouo: (group) => post("/group", group),
  editGroup: (group) => put("/group", group),
  setProfile: (user) => post("/user/profile", user),

  cmnList: (body) => post("/cmn/list", body),
  cmnDelete: (body) => post("/cmn/delete", body),
  cmnFetch: (body) => post("/cmn/fetch", body),
  cmnEdit: (body) => post("/cmn/edit", body),
  cmnCreate: (body) => post("/cmn/create", body),
  cmnSearch: (body) => post("/cmn/search", body),
  cmnSearchField: (body) => post("/cmn/searchField", body),

  fetchEvents: (body) => post("/event/list", body),

};

export default apiService;
