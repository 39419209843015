import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";

function CaseFileCreate() {
  const expenseNoteId = new URLSearchParams(window.location.search).get('id')
  const caseFileId = new URLSearchParams(window.location.search).get('caseFileId')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const caseFileEditTemplate = {
    id: expenseNoteId,
    returnPage: "back",
    fields: [
      {field: 'date', labelName: 'Tarih', type: "datetime", required: true},
      {field: 'caseFileId', subField: "courtName", additionalField: "courtFileName", associatedFieldName: "caseFile", labelName: 'Dava', type: "object", objectName: "CaseFile", required: true, visible: caseFileId === null},
      {field: 'name', labelName: 'İşlem Adı', type: "searchable", required: true,
        options: [
          {key: "DOSYA MASRAFI"},
          {key: "MASRAF AVANSI"},
          {key: "HARÇ"},
          {key: "PUL"},
          {key: "OFİS GİDER"},
          {key: "OFİS GELİR"}
        ]},
      {field: 'transactionType', labelName: 'İşlem Aracı', type: "searchable", required: true,
        options: [
          {key: "Elden"},
          {key: "Bankadan"}
        ]},
      {field: 'type', labelName: 'İşlem Tipi', type: "searchable", required: true,
        options: [
          {key: "GELİR"},
          {key: "GİDER"},
        ]},
      {field: 'amount', labelName: 'Tutar', type: "number", required: true},
      {field: 'note', labelName: 'Notlar', type: "textarea", required: false},
    ],
    defaultRecordValues:{
      clientId,
      caseFileId
    },
    query: {
      objectName: "ExpenseNote",
      recordId: expenseNoteId,
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile',
          include: [
            {
              objectName: "Client",
              fieldName: 'clients'
            }
          ]
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={caseFileEditTemplate}/>

    </div>
  );
}

export default CaseFileCreate;
