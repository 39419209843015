import * as React from "react";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CrList from "../components/crud/CrList";

function Tasks() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  const taskListTemplate = {
    subPageKey: "task",

    returnPage: "back",

    // büro kayıt
    // avukat oluşturma


    fields: [
      {field: 'createdAt', headerName: 'Kayıt Tarihi', type: "datetime", flex: .8, visible: true,},
      {field: 'taskDefinition', headerName: 'Görev Tanımı', type: "text", flex: 1, visible: true,},
      {field: 'date', headerName: 'Görevin Yapılacağı Tarih', type: "datetime", flex: .8, visible: true,},
      {field: 'dueDate', headerName: 'Görev Son Gün', type: "date", flex: .8, visible: true,},
      {field: 'description', headerName: 'Görev Detayı', type: "text", flex: 1, visible: true,},
      {field: 'appointer', headerName: 'Görevlendiren Kişi', type: "text", flex: 1, visible: true,},
      {field: 'responsible', headerName: 'Görevli Kişi', type: "text", flex: 1, visible: true,},
      {field: 'status', headerName: 'Görev Kontrol', type: "switchTrueFalse", flex: .6, visible: true,},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true,},
      {field: 'officeFileNo', parentFieldName: "caseFile", headerName: 'Ofis Dosya No', type: "object", flex: .7, visible: true},
      {field: 'clients', parentFieldName: "caseFile", subField: "fullName", headerName: 'Müvekkil', type: "objectList", flex: 1, visible: true, linkedTo: {path: "client",param: "id"}},
      {field: 'opponents', parentFieldName: "caseFile", subField: "fullName", headerName: 'Karşı Taraf', type: "objectList", flex: 1, visible: true, linkedTo: {path: "opponent",param: "id"}},
      {field: 'courtName',parentFieldName: "caseFile", headerName: 'Mahkeme Adı', type: "object", flex: 1, visible: true},
      {field: 'courtFileName',parentFieldName: "caseFile", headerName: 'Mahkeme Dosya No', type: "object", flex: .9, visible: true, mobileHeader: "onlyName", linkedTo: "id"},
      {field: 'addToCalendar', headerName: 'Takvime Ekle', type: "switchTrueFalse", flex: .6, visible: true,},
    ],
    query: {
      objectName: "Task",
      order: [
        ['createdAt', 'DESC']
      ],
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile',
          include: [
            {
              objectName: "Client",
              fieldName: 'clients'
            },
            {
              objectName: "Opponent",
              fieldName: 'opponents',
            }
          ]
        }
      ],
      where: {

      }
    },
    messages: {
      pageHeader: "Görev Listesi",
      newRecord: "Yeni Görev"
    },
    disabledActions: {
      detail: true
    }
  }

  return (
    <div>
      <CrList template={taskListTemplate}/>

    </div>
  );
}

export default Tasks;
