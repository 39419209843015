import * as React from 'react';
import {useEffect, useState} from 'react';
import apiService from "../../api/apiService";
import {Link, TextField, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import {subscribe} from "../../util/eventBus";
import {useTheme} from "@mui/material/styles";
import CrLink from "../CrLink";

export default function CrDisplayOne({template}) {
  let [record, setRecord] = useState();
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const lessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  async function init() {
    const response = await apiService.cmnFetch(template.query);
    if (response.status === 200) {
      setRecord(response.data.payload);
    }
  }

  useEffect(() => {
    init();
    subscribe("refresh_"+template.id, () => {
      init();
    });
  }, [template]);

  if(!record)
    return undefined;

  const DisplayFieldElement = ({field, values}) => {
    if(field.visible && !field.visible.values.includes(values[field.visible.field])){
      return <></>;
    }
    if(field.hide)
      return null;
    switch (field.type) {
      case "text":
        if(!values[field.field]){
          return null
        }
        return <div>
          {field.labelName && <b style={{}}>{field.labelName}: </b>}
          <span >{values[field.field]}</span>
        </div>
      case "textarea":
        return <div style={{maxWidth: "80%",marginTop: 12, display: "flex", flexDirection: "column",border: ".05rem solid rgb(91 87 67)", backgroundColor:"rgb(254 246 214)", padding: 16, borderRadius: 16}}>
          {field.labelName && <b style={{minWidth: 100}}>{field.labelName}</b>}
          <span style={{

          }}>{values[field.field]}</span>
        </div>
      case "date":
        return <div>
          {field.labelName && <b>{field.labelName}: </b>}
          <span>{new Date(values[field.field]).toLocaleString()}</span>
        </div>
      case "object":
        if(field.linkedTo){
          return <div>
            <CrLink href={"/"+field.linkedTo.path+"?id=" + (field.selfParam ? values[field.field].id : values.id)}>{values[field.field]}</CrLink>
          </div>
        }

        return <div>
          <b>{field.labelName}: </b>
          <span>{values[field.field][field.subField]}</span>
        </div>
      case "objectList":
        return <div style={{display: "flex", flexDirection: "row", marginTop:6, marginBottom: 6}}>
          <b style={{minWidth: 110}}>{field.labelName}: </b>
          <div style={{display: "flex", flexDirection: "column"}}>
            {values[field.field].map((subValue)=>{
                return <div style={{minWidth: greaterThanSm ? '20em' : 10, display: "flex", flexDirection: "column", gap: 0,marginBottom: 8, borderLeft: ".3rem solid #1976d2", backgroundColor:"rgba(25,118,210,0.1)", padding: 8, paddingLeft: 12}} >
                  {field.subFields.map((f) => {
                  return <DisplayFieldElement field={f} values={subValue}/>
                })}</div>
            })}
          </div>
        </div>
      default:
        return
    }
  }

  return (
    <div style={{
      padding: 14,
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 0
    }}>

      {template?.fields?.map((field) => {
        if(field.sectionLabel){
          if(field.visible && !field.visible.values.includes(record[field.visible.field])){
            return <></>;
          }
          let val = field.fields?.find((subField)=>record[subField.field] && record[subField.field] !== "");
          if(!val)
            return <></>;

          return <div style={{display: "flex", flexDirection: "row"}}>
            <span style={{fontWeight: "bold", fontSize: 18, minWidth: 100}}>{field.sectionLabel}</span>
            <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 0, borderLeft: ".3rem solid #1976d2", backgroundColor:"rgba(25,118,210,0.1)", padding: 8,
            marginTop: 6, marginBottom:6, minWidth: greaterThanSm ? '30em' : 10

          }}>
            {field.fields?.map((subField) => {
              return <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 8
              }}><DisplayFieldElement field={subField} values={record}/></div>
            })}
          </div></div>
        }
        return <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: 8
        }}><DisplayFieldElement field={field} values={record}/></div>
      })}

    </div>
  );
}
