import * as React from 'react';
import {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import WindowIcon from '@mui/icons-material/Window';
import PersonIcon from '@mui/icons-material/Person';
import TopicIcon from '@mui/icons-material/Topic';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Route, Routes, useNavigate} from "react-router-dom";
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Logo from "./images/logo_w.svg";
import BigCalendar from "./pages/BigCalendar";
import {Button, Menu, MenuItem, TextField} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import UserManagement from "./pages/UserManagement";
import GroupManagement from "./pages/GroupManagement";
import User from "./pages/User";
import Group from "./pages/Group";
import CreateUser from "./pages/CreateUser";
import CreateGroup from "./pages/CreateGroup";
import apiService from "./api/apiService";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {publish, subscribe} from "./util/eventBus";
import Clients from "./pages/Clients";
import CreateClient from "./pages/CreateClient";
import Client from "./pages/Client";
import WarrantCreate from "./pages/WarrantCreate";
import Warrant from "./pages/Warrant";
import Warrants from "./pages/Warrants";
import CaseFileCreate from "./pages/CaseFileCreate";
import CaseFile from "./pages/CaseFile";
import CaseFiles from "./pages/CaseFiles";
import ExpenseNoteCreate from "./pages/ExpenseNoteCreate";
import ExpenseNotes from "./pages/ExpenseNotes";
import ExpenseNote from "./pages/ExpenseNote";
import Opponents from "./pages/Opponents";
import OpponentCreate from "./pages/OpponentCreate";
import Opponent from "./pages/Opponent";
import CaseFileStepCreate from "./pages/CaseFileStepCreate";
import CaseFileSpecialStepCreate from "./pages/CaseFileSpecialStepCreate";
import LegalNoticeCreate from "./pages/LegalNoticeCreate";
import TaskCreate from "./pages/TaskCreate";
import WitnessCreate from "./pages/WitnessCreate";
import DocumentCreate from "./pages/DocumentCreate";
import Tasks from "./pages/Tasks";
import Home from "./pages/Home";
import Register from "./pages/Register";


const drawerWidth = 170;

function App(props) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(true);
  const [pagePermissions, setPagePermissions] = React.useState({});


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const fsk = (localStorage.getItem("fsk"));

  async function init() {
    const userRes = await apiService.fetchUser(user.id);
    if (userRes.status === 200) {
      localStorage.setItem("statsFavList", JSON.stringify(userRes.data.payload?.statsFavList));
      localStorage.setItem("permissions", JSON.stringify(userRes.data.payload?.group?.groupPermissions));
      localStorage.setItem("user", JSON.stringify(userRes.data.payload))

      setPagePermissions(userRes.data.payload?.group?.groupPermissions?.page)
    } else {
      localStorage.clear();
    }

    if(!sessionStorage.getItem('epanded_list_casefile')){
      sessionStorage.setItem('epanded_list_casefile', JSON.stringify({}))
    }
  }

  useEffect(() => {
    subscribe("globalSearchState", (state) => {
      setSearchOpen(state);
    });

    subscribe("globalSearchStateShow", (state) => {
      setShowSearch(state);
    });
    init();
  }, []);

  const drawer = (
    <div>
      <Toolbar sx={{bgcolor: "#fdfdfd"}}/>
      <Divider/>

      <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: 8, marginTop:8 }}>
        <span style={{lineHeight: "normal",fontWeight: "bold", fontSize: 14, color: "#fff", textAlign: "center", marginBottom: 8}}>{user.group.name}</span>
        <span style={{fontWeight: "normal", fontSize: 14, color: "#fff"}}>({user.firstName + " " + user.lastName})</span>
      </Box>

      <div style={{backgroundColor: "#fdfdfd", width:"95%", height: 8}}></div>

      <List>
        <ListItem key={"Ana Sayfa"} disablePadding>
          <ListItemButton onClick={() => navigate('/')}>
            <ListItemIcon>
              <WindowIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Ana Sayfa"} style={{color: "#fff", marginLeft: -20}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem key={"Müvekkiller"} disablePadding>
          <ListItemButton onClick={() => navigate('clients')}>
            <ListItemIcon>
              <PeopleIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Müvekkiller"} style={{color: "#fff", marginLeft: -20}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem key={"Karşı Taraf"} disablePadding>
          <ListItemButton onClick={() => navigate('opponents')}>
            <ListItemIcon>
              <PersonIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Karşı Taraf"} style={{color: "#fff", marginLeft: -20}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem key={"Dosya Listesi"} disablePadding>
          <ListItemButton onClick={() => navigate('case-files')}>
            <ListItemIcon>
              <TopicIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Dosya Listesi"} style={{color: "#fff", marginLeft: -20}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem key={"Görevler"} disablePadding>
          <ListItemButton onClick={() => navigate('tasks')}>
            <ListItemIcon>
              <AssignmentIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Görevler"} style={{color: "#fff", marginLeft: -20}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem key={"Defter"} disablePadding>
          <ListItemButton onClick={() => navigate('expense-notes')}>
            <ListItemIcon>
              <AutoStoriesIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Defter"} style={{color: "#fff", marginLeft: -20}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>




{/*        <ListItem key={"Vekalet Listesi"} disablePadding>
          <ListItemButton onClick={() => navigate('warrants')}>
            <ListItemIcon>
              <TextSnippetIcon style={{color: "#fff"}}/>
            </ListItemIcon>
            <ListItemText primary={"Vekalet Listesi"} style={{color: "#fff"}}/>
          </ListItemButton>
        </ListItem>
        <Divider/>*/}
      </List>
    </div>
  );


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{display: 'flex', backgroundColor: "#fdfdfd"}}>
      <CssBaseline/>
      <AppBar
        position="fixed"
        sx={{
          ml: {md: `${drawerWidth}px`},
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {xs: 'block', md: 'none'}}}
          >
            <MenuIcon/>
          </IconButton>
          <img src={Logo} alt="AvuPort" style={{width: 220, height: 48, marginRight: 16}}/>

          <Box flexGrow={1}></Box>
          {searchOpen &&
            <div style={{
              backgroundColor: "#fff",
              height: 48,
              marginRight: 8,
              borderRadius: 4,
              width: 250,
              paddingLeft: 4
            }}>
              <TextField style={{height: 20, marginTop: 0, width: 210}} id="standard-basic" label="Ara"
                         variant="standard"
                         size="small" margin="dense"
                         onChange={(event) => {
                           publish("globalSearch", event.target.value);
                         }}/>

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => {
                  setSearchOpen(false);
                  publish("globalSearch", "");
                }}
                style={{minWidth: 30, padding: 0, marginRight: 8, position: "absolute", marginTop: 12}}
              >
                <CloseIcon style={{fill: "#000", fontSize: 24}}/>
              </Button>
            </div>
          }


          <div>
{/*            {(!searchOpen && showSearch) && <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => {
                setSearchOpen(true)
              }}
              style={{minWidth: 30, padding: 0, marginRight: 8}}
            >
              <SearchIcon style={{fill: "#fff", fontSize: 24}}/>
            </Button>}*/}

            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{minWidth: 30, padding: 0}}
            >
              <SettingsIcon style={{fill: "#fff", fontSize: 24}}/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <div style={{
                paddingLeft: 16,
                paddingTop: 4,
                paddingBottom: 8,
                display: "flex",
                width: 300,
                alignContent: "center",
                gap: 12
              }}>
                <PersonIcon/>
                {user.firstName + " " + user.lastName}
              </div>

              <Divider/>

              {(user.role === 'ADMIN' || user.role === 'ROOT_ADMIN') && <MenuItem onClick={() => {
                navigate('/users')
              }}>Avukat Yönetimi</MenuItem>}
              {(user.role === 'ROOT_ADMIN') && <MenuItem onClick={() => {
                navigate('/groups')
              }}>Hukuk Bürosu Yönetimi</MenuItem>}
              <MenuItem onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}>Çıkış Yap</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', md: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth, height: '96%'},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: 'none', md: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth, height: '96%'},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: {xs: 1, md: 3},
          width: {xs: "-webkit-fill-available", sm: '-webkit-fill-available', md: `calc(100% - ${240}px)`}
        }}
      >
        <Toolbar/>

        <Routes>
          <Route exact path="/" element = {<Home/>}/>
          <Route exact path="/user" element = {<User/>}/>
          <Route exact path="/users" element = {<UserManagement/>}/>
          <Route exact path="/groups" element = {<GroupManagement/>}/>
          <Route exact path="/user-group" element = {<Group/>}/>
          <Route exact path="/create-user-group" element = {<CreateGroup/>}/>
          <Route exact path="/create-user" element = {<CreateUser/>}/>
          <Route exact path="/clients" element = {<Clients/>}/>
          <Route exact path="/create-client" element = {<CreateClient/>}/>
          <Route exact path="/client" element = {<Client/>}/>
          <Route exact path="/create-warrant" element = {<WarrantCreate/>}/>
          <Route exact path="/warrant" element = {<Warrant/>}/>
          <Route exact path="/warrants" element = {<Warrants/>}/>
          <Route exact path="/create-case-file" element = {<CaseFileCreate/>}/>
          <Route exact path="/case-file" element = {<CaseFile/>}/>
          <Route exact path="/case-files" element = {<CaseFiles/>}/>
          <Route exact path="/create-expense-note" element = {<ExpenseNoteCreate/>}/>ExpenseNoteCreate
          <Route exact path="/groups" element = {<GroupManagement/>}/>
          <Route exact path="/expense-notes" element = {<ExpenseNotes/>}/>
          <Route exact path="/expense-note" element = {<ExpenseNote/>}/>
          <Route exact path="/opponents" element = {<Opponents/>}/>
          <Route exact path="/create-opponent" element = {<OpponentCreate/>}/>
          <Route exact path="/opponent" element = {<Opponent/>}/>
          <Route exact path="/create-case-file-step" element = {<CaseFileStepCreate/>}/>
          <Route exact path="/create-case-file-special-step" element = {<CaseFileSpecialStepCreate/>}/>
          <Route exact path="/create-legal-notice" element = {<LegalNoticeCreate/>}/>
          <Route exact path="/create-task" element = {<TaskCreate/>}/>
          <Route exact path="/create-witness" element = {<WitnessCreate/>}/>
          <Route exact path="/create-document" element = {<DocumentCreate/>}/>
          <Route exact path="/tasks" element = {<Tasks/>}/>

        </Routes>
      </Box>
    </Box>
  );
}

export default App;
