import * as React from 'react';
import CrList from "../components/crud/CrList";

export default function GroupManagement() {

  const userGroupListTemplate = {
    subPageKey: "user-group",
    returnPage: "back",
    fields: [
      {field: 'name', headerName: 'Büro Adı', type: "text", flex: 1, visible: true},
      {field: 'disabled', headerName: 'Aktiflik', type: "disabled", flex: 1, visible: true},
      {field: 'users', headerName: 'Toplam Kullanıcı', type: "objectCount", flex: 1, visible: true},
      {field: 'totalAllowedUser', headerName: 'Max Kul. Sayısı', type: "text", flex: 1, visible: true},
      {field: 'subscriptionStart', headerName: 'Üyelik Başlangıç', type: "date", flex: 1, visible: true},
      {field: 'subscriptionEnd', headerName: 'Üyelik Bitiş', type: "date", flex: 1, visible: true},
      {field: 'taxAuthority', headerName: 'Vergi Dairesi', type: "text", flex: 1, visible: true},
      {field: 'taxNo', headerName: 'Vergi Numarası', type: "text", flex: 1, visible: true},
      {field: 'id', headerName: 'ID', type: "text", flex: 1, visible: true, mobileHeader: "onlyName"},
    ],
    query:{
      objectName: "UserGroup",
      order: [
        ['createdAt', 'DESC']
      ],
      include: [
        {
          objectName: "User",
          fieldName: 'users'
        }
      ]
    },
    messages: {
      pageHeader: "Büro Listesi ",
      newRecord: "Yeni Büro"
    },
    disabledActions: {
      remove: true,
      detail: true
    }
  }

  return (
    <>
      <CrList template={userGroupListTemplate}/>
    </>
  );
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0]?.[0]}${name.split(' ')[1]?.[0]}`,
  };
}
