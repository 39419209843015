import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import apiService from "../api/apiService";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

export default function User() {
  const id = new URLSearchParams(window.location.search).get('id')
  const navigate = useNavigate()
  let [user, setUser] = useState();


  async function init() {
    const userRes = await apiService.fetchUser(id);
    if (userRes.status === 200) {
      setUser(userRes.data.payload);
    }
  }

  async function deleteUser() {
    const userDeleteRes = await apiService.deleteUser(id);
    if (userDeleteRes.status === 200) {
      navigate('/users')
    }
  }

  useEffect(() => {
    init();
  }, []);

  if(!user)
    return undefined;

  return (

    <Box sx={{width: {xs: '100%', sm: '60%', md: '30%'}}} style={{
      padding: 14,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 8
    }}>
      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>İsim: </b>
        <span>{user.firstName}</span>
      </div>


      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Soyisim: </b>
        <span>{user.lastName}</span>
      </div>

      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Kullanıcı Adı: </b>
        <span>{user.username}</span>
      </div>


      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Rol: </b>
        <span>{user.role === 'ADMIN' ? 'Yönetici' : "Kullanıcı"}</span>
      </div>


      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Görevi: </b>
        <span>{user.title}</span>
      </div>


      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Telefon: </b>
        <span>{user.phoneNumber}</span>
      </div>


      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>E-Posta: </b>
        <span>{user.email}</span>
      </div>

      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 8
      }}>
        <b>Büro: </b>
        <span>{user.group?.name}</span>
      </div>


      <Button style={{height: 50, marginTop: 20}} variant="contained" onClick={()=> navigate('/create-user?id=' + user?.id)}>Düzenle</Button>
      <Button style={{height: 50, marginTop: 20}} variant="outlined" color="error" onClick={deleteUser}>Sil</Button>



    </Box>
  );
}
