import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import trLocale from '@fullcalendar/core/locales/tr';
import listPlugin from '@fullcalendar/list';
import {Button, ButtonGroup, useMediaQuery} from "@mui/material";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import BigCalendar from "./BigCalendar";




export default function Home({pagePermissions}) {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
<BigCalendar/>
    </div>
  );
}
