import * as React from "react";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CrList from "../components/crud/CrList";

function Clients() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  const clientListTemplate = {
    subPageKey: "client",
    fields: [
      {field: 'no', headerName: 'NO', type: "text", flex: .3, visible: true},
      {field: 'type', headerName: 'Tür', type: "autocomplete", required: true, flex: .4, visible: true},
      {field: 'fullName', headerName: 'Adı Soyadı / Kurum Adı', type: "text", flex: 1, visible: true, mobileHeader: "onlyName"},
      {field: 'tcTaxNo', headerName: 'TC No / Vergi No', type: "text", flex: 1, visible: true },
      {field: 'gsm1', headerName: 'Tel 1', type: "text", flex: .5, visible: true },
      {field: 'gsm1', headerName: 'Tel 2', type: "text", flex: .5, visible: true }
    ],
    query:{
      objectName: "Client",
      order: [
        ['fullName', 'ASC']
      ],
    },
    messages: {
      pageHeader: "Müvekkil Listesi",
      newRecord: "Yeni Müvekkil"
    },
    disabledActions: {
      remove: true
    }
  }

  return (
    <div>
      <CrList template={clientListTemplate}/>

    </div>
  );
}

export default Clients;
