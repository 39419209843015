import * as React from "react";
import {useEffect, useState} from "react";
import {Autocomplete, Button, FormGroup, TextField} from "@mui/material";
import apiService from "../../api/apiService";
import Box from "@mui/material/Box";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import {trTR} from '@mui/x-date-pickers/locales';
import {useNavigate} from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {DateTimePicker} from "@mui/x-date-pickers";


function CrCreateEdit({template}) {
  const [values, setValues] = useState(template.defaultRecordValues ? template.defaultRecordValues : {});
  let [associationValues, setAssociationValues] = useState(template.defaultAssociationValues ? template.defaultAssociationValues : {});
  let [dynamicOptions, setDynamicOptions] = useState({});
  const [temp, setTemp] = useState(template);
  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setTemp({...template})

    let manipulatedFields = [];
    template.fields.forEach((field)=>{
      if(field.sectionLabel){
        manipulatedFields = manipulatedFields.concat(field.fields)
      }else {
        manipulatedFields.push(field)
      }
    })
    template.fields = manipulatedFields;

  }, [template]);


  async function init() {
    if (template.id) {
      const response = await apiService.cmnFetch(template.query);
      if (response.status === 200) {
        let dynOpts = {};
        template.fields?.forEach((field) => {
          switch (field.type) {
            case "object":
              response.data.payload[field.field] = response.data.payload[field.associatedFieldName].id
              dynOpts = {...dynOpts, [field.field]: [response.data.payload[field.associatedFieldName]]}
              break
            case "objectList":
              associationValues = {...associationValues, [field.objectName]:  response.data.payload[field.associatedFieldName].map((v)=> v.id)}
              setAssociationValues({...associationValues });
              break
          }
        })
        setDynamicOptions({...dynOpts})
        setValues(response.data.payload)
      }
    }

    template.fields?.forEach((field, i) => {
      switch (field.type) {
        case "object":
          //if (!field.blockerField)
            fetchOptions(field.field, {
              type: "keywordSearch",
              keyWord: "",
              objectName: field.objectName,
              subField: field.subField
            });
          break
        case "objectList":
          //if (!field.blockerField)
            fetchOptions(field.field, {
              type: "keywordSearch",
              keyWord: "",
              objectName: field.objectName,
              subField: field.subField
            });
          break
        case "searchable":
          if (!field.blockerField)
            fetchSearchableOptions(field.field, {
              type: "keywordSearch",
              keyWord: "",
              objectName: template.query.objectName,
              subField: field.field
            });
          break
      }
    })
  }


  async function fetchOptions(field, body) {
    const response = await apiService.cmnSearch(body);
    if (response.status === 200) {
      setDynamicOptions({...dynamicOptions, [field]: response.data.payload})
      dynamicOptions = {...dynamicOptions, [field]: response.data.payload};
    }
  }

  async function fetchSearchableOptions(field, body) {
    const response = await apiService.cmnSearchField(body);
    if (response.status === 200) {
      setDynamicOptions({
        ...dynamicOptions, [field]: response.data.payload?.map((i) => {
          return {key: i[field]}
        })
      })
      dynamicOptions = {
        ...dynamicOptions, [field]: response.data.payload?.map((i) => {
          return {key: i[field]}
        })
      }
    }
  }

  async function create(event) {
    event.preventDefault();

    if (!template.id) {
      const createResponse = await apiService.cmnCreate({...template.query, recordValues: values, associationValues});
      if (createResponse.status === 200) {
        if (template.returnPage !== "back")
          navigate(`/${template.returnPage}`)
        else
          navigate(-1)
      }
    } else {
      const editResponse = await apiService.cmnEdit({...template.query, recordValues: values, associationValues});
      if (editResponse.status === 200) {
        if (template.returnPage !== "back")
          navigate(`/${template.returnPage}`)
        else
          navigate(-1)
      }
    }
  }

  const EditableFieldElement = ({field}) => {
    if(field.visible && field.visible !== true && field.visible !== true && !field.visible.values?.includes(values[field.visible.field])){
      return null;
    }

    switch (field.type) {
      case "text":
        return <TextField key={field.field} required={field.required} id={field.field} label={field.labelName}
                          variant="filled"
                          size="small"
                          onChange={(event) => {
                            values[field.field] = event.target.value
                            setValues({...values});
                          }}
                          value={values[field.field] || ""}/>
      case "textarea":
        return <TextField key={field.field} required={field.required} id={field.field} label={field.labelName}
                          variant="filled"
                          size="small"
                          multiline
                          placeholder={field.placeholder}
                          rows={3}
                          onChange={(event) => {
                            values[field.field] = event.target.value
                            setValues({...values});
                          }}
                          value={values[field.field] || ""}/>
      case "number":
        return <TextField key={field.field} required={field.required} id={field.field} label={field.labelName}
                          variant="filled"
                          type="number"
                          size="small"
                          onChange={(event) => {
                            values[field.field] = event.target.value
                            setValues({...values});
                          }}
                          value={values[field.field] || ""}/>
      case "autocomplete":
        const optionsAutoComplete = field.blockerField ? (template.fields.find((f)=>f.field === field.blockerField)?.options?.find((o)=> o.key === values[field.blockerField])?.subOptions) : field.options;
        const disabledAutoComplete = field.blockerField ? values[field.blockerField] === undefined || values[field.blockerField] === null : false;

        return <Autocomplete key={field.field}
                             value={optionsAutoComplete?.find((o) => o.key === values[field.field]) || null}
                             onChange={(event, newValue) => {
                               setValues({...values, [field.field]: newValue.key});
                             }}
                             defaultValue={optionsAutoComplete?.find((o) => o.key === values[field.field])}
                             getOptionLabel={(option) => option.key}
                             options={optionsAutoComplete}
                             disabled={disabledAutoComplete}
                             disableClearable
                             sx={{width: "100%"}}
                             renderInput={(params) => <TextField {...params} id={field.field} variant="filled"
                                                                 required={field.required}
                                                                 size="small"
                                                                 label={field.labelName}/>}
        />
      case "date":
        if (!values[field.field]) {
          values[field.field] = dayjs(new Date())
          setValues({...values});
        }
        return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr" localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
          <DatePicker label={field.labelName}
                      id={field.field}
                      value={dayjs(values[field.field])}
                      defaultValue={dayjs(values[field.field]) || dayjs(new Date())}
                      closeOnSelect
                      size="small"
                      onChange={(newValue) => {
                        values[field.field] = newValue
                        setValues({...values});
                      }}
          />
        </LocalizationProvider>
      case "datetime":
        if (!values[field.field]) {
          values[field.field] = dayjs(new Date())
          setValues({...values});
        }
        return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr" localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
          <DateTimePicker label={field.labelName}
                      id={field.field}
                      value={dayjs(values[field.field])}
                      defaultValue={dayjs(values[field.field]) || dayjs(new Date())}
                      closeOnSelect
                      size="small"
                      onChange={(newValue) => {
                        values[field.field] = newValue
                        setValues({...values});
                      }}
          />
        </LocalizationProvider>
      case "object":
        if (!field.visible)
          return null

        const options = dynamicOptions[field.field] ? dynamicOptions[field.field] : [];
        const disabled = field.blockerField ? values[field.blockerField] === undefined || values[field.blockerField] === null : false;

        return <Autocomplete key={field.field}
                             value={options?.find((o) => o.id === values[field.field]) || null}
                             onChange={(event, newValue) => {
                               setValues({...values, [field.field]: newValue.id});

                               template.fields?.filter((f) => f.blockerField === field.field).forEach((f, i) => {
                                 fetchOptions(f.field, {
                                   type: "keywordSearch",
                                   keyWord: "",
                                   objectName: f.objectName,
                                   subField: f.subField,
                                   where: {
                                     [f.blockerField]: newValue.id
                                   }
                                 });
                               })
                             }}
                             defaultValue={options?.find((o) => o.id === values[field.field])}
                             getOptionLabel={(option) => !field.additionalField ? option[field.subField] : (option[field.subField] + " - " + option[field.additionalField])}
                             options={options}
                             disabled={disabled}
                             disableClearable
                             sx={{width: "100%"}}
                             renderInput={(params) => <TextField {...params} variant="filled" id={field.field} size="small"
                                                                 onChange={(event) => {
                                                                   setValues({...values, [field.field]: ""});
                                                                   fetchOptions(field.field, {
                                                                     type: "keywordSearch",
                                                                     keyWord: event.target.value,
                                                                     objectName: field.objectName,
                                                                     subField: field.subField,
                                                                     where: {
                                                                       [field.blockerField]: values[field.blockerField]
                                                                     }
                                                                   })
                                                                 }} required={field.required}
                                                                 label={field.labelName}/>}/>
      case "objectList":
        if (!field.visible)
          return null

        const objectListOptions = dynamicOptions[field.field] ? dynamicOptions[field.field] : [];
        const objectListDisabled = field.blockerField ? values[field.blockerField] === undefined || values[field.blockerField] === null : false;

        return <Autocomplete key={field.field}
                             multiple
                             value={objectListOptions?.filter((o) => associationValues[field.objectName]?.includes(o.id)) || null}
                             onChange={(event, newValues) => {
                               setAssociationValues({...associationValues, [field.objectName]: newValues.map((v)=> v.id)});

                               template.fields?.filter((f) => f.blockerField === field.field).forEach((f, i) => {
                                 fetchOptions(f.field, {
                                   type: "keywordSearch",
                                   keyWord: "",
                                   objectName: f.objectName,
                                   subField: f.subField,
                                   where: {
                                     [f.blockerField]: newValues[0].id
                                   }
                                 });
                               })
                             }}
                             defaultValue={objectListOptions?.filter((o) => associationValues[field.objectName]?.includes(o.id)) || null}
                             getOptionLabel={(option) => !field.additionalField ? option[field.subField] : (option[field.subField] + " - " + option[field.additionalField])}
                             options={objectListOptions}
                             disabled={objectListDisabled}
                             disableClearable
                             freeSolo
                             sx={{width: "100%"}}
                             renderInput={(params) => <TextField {...params} variant="filled" id={field.field} size="small"
                                                                 onChange={(event) => {
                                                                   setAssociationValues({...associationValues, [field.objectName]: associationValues[field.objectName]});
                                                                   fetchOptions(field.field, {
                                                                     type: "keywordSearch",
                                                                     keyWord: event.target.value,
                                                                     objectName: field.objectName,
                                                                     subField: field.subField,
                                                                     where: {
                                                                       [field.blockerField]: values[field.blockerField]
                                                                     }
                                                                   })
                                                                 }}

                                                                 //required={field.required}
                                                                 label={field.labelName}/>}/>
      case "searchable":
        const searchableOptions = field.options;
        if (dynamicOptions[field.field]) {
          dynamicOptions[field.field].forEach((dynOpt) => {
            if (!(searchableOptions.find((so) => so.key === dynOpt.key))) {
              searchableOptions.push(dynOpt);
            }
          })
        }

        return <Autocomplete key={field.field}
                             value={searchableOptions.find((o) => o.key === values[field.field]) || null}
                             onChange={(event, newValue) => {
                               setValues({...values, [field.field]: newValue.key});
                             }}
                             freeSolo
                             defaultValue={searchableOptions.find((o) => o.key === values[field.field])}
                             getOptionLabel={(option) => option.key}
                             options={searchableOptions}
                             disableClearable
                             sx={{width: "100%"}}
                             renderInput={(params) =>
                               <TextField {...params} required={field.required} label={field.labelName} variant="filled" id={field.field} size="small"
                                          onChange={(event) => {
                                            setValues({...values, [field.field]: ""});
                                            fetchSearchableOptions(field.field, {
                                              type: "keywordSearch",
                                              keyWord: event.target.value,
                                              objectName: template.query.objectName,
                                              subField: field.field,
                                            }, true)
                                            setValues({...values, [field.field]: event.target.value});
                                          }}/>}/>
      default:
        return <span>No Element</span>
    }
  }

  return (
    <div>
      <form style={{display: "flex", justifyContent: "center"}} onSubmit={create}>
        <Box sx={{width: {xs: '100%', sm: '80%', md: '50%', lg: '%40'}}}>
          <FormGroup
            style={{padding: 0, height: "100%", width: "100%", display: "flex", gap: 12}}>
            <h3 style={{textAlign: "center", padding: 0, margin: 0, marginTop: -4, marginBottom: 6
            }}>{!temp.id ? temp?.messages?.newRecord : temp?.messages?.editRecord}</h3>

            {temp?.fields?.map((field) => {
              if(field.sectionLabel){
                if(field.visible && !field.visible.values?.includes(values[field.visible.field])){
                  return <></>;
                }
                return <Accordion>
                  <AccordionSummary sx={{
                    backgroundColor: "#e8eff9",
                    '.MuiAccordionSummary-content.Mui-expanded': {
                      margin: 1
                    },
                    '.MuiAccordionSummary-root.Mui-expanded': {
                      minHeight: '40px'
                    },
                  }}
                                    style={{height: 40, minHeight: 40}}
                                    expandIcon={<ExpandMoreIcon/>}
                                    id={field.sectionLabel}
                  >
                    <span style={{fontWeight: "bold", fontSize: 20}}>{field.sectionLabel}</span>
                  </AccordionSummary>
                  <AccordionDetails style={{padding: 0}}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 12
                    }}>
                    {field.fields?.map((subField) => {
                      return EditableFieldElement({field: subField})
                    })}
                    </div>
                  </AccordionDetails>
                </Accordion>



/*                return <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12
                }}>
                  <span style={{fontWeight: "bold", fontSize: 18, marginTop:16}}>{field.sectionLabel}</span>
                  {field.fields?.map((subField) => {
                    return EditableFieldElement({field: subField})
                  })}
                </div>*/
              }
              return EditableFieldElement({field})
            })}

            <Button style={{height: 50, marginTop: 20}} variant="contained" type="submit"
            >{temp.id ? "Düzenle" : "Oluştur"}</Button>

          </FormGroup>
        </Box>
      </form>
    </div>
  );
}

export default CrCreateEdit;
