import * as React from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import CrDisplayOne from "../components/crud/CrDisplayOne";
import CrList from "../components/crud/CrList";
import {styled, useTheme} from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import {addressType, allowedCityAddressType} from "../Data/optionsData";

export default function Client() {
  const clientId = new URLSearchParams(window.location.search).get('id')

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const lessThanSm = useMediaQuery(theme.breakpoints.down("sm"));

  const clientFetchTemplate1 = {
    id: clientId,
    fields: [
      {field: 'createdAt', labelName: 'Kayıt Tarihi', type: "date"},
      {field: 'no', labelName: 'Müvekkil No', type: "text"},
      {field: 'type', labelName: 'Tür', type: "text"},
      {field: 'fullName', labelName: 'Adı Soyadı', type: "text", visible: {field: 'type', values: ['Şahıs']}},
      {field: 'fullName', labelName: 'Kurum Adı', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'tcTaxNo', labelName: 'TC No', type: "text", visible: {field: 'type', values: ['Şahıs']}},
      {field: 'tcTaxNo', labelName: 'TC No', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'nationality', labelName: 'Uyruk', type: "text", visible: {field: 'type', values: ['Şahıs']}},
      {field: 'mersisNo', labelName: 'MERSİS No', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'gsm1', labelName: 'Tel1', type: "text"},
      {field: 'gsm2', labelName: 'Tel2', type: "text"},
      {field: 'email', labelName: 'E-Posta', type: "text"},
      {field: 'note', labelName: 'Notlar', type: "textarea", hide: lessThanSm},
    ],
    query: {
      objectName: "Client",
      recordId: clientId,
    },
    messages: {}
  }

  const clientFetchTemplate2 = {
    id: clientId,
    fields: [
      {
        sectionLabel: "Adres 1",
        fields:[
          {field: 'addressType1', labelName: 'Adres tipi', type: "text"},
          {field: 'city1', labelName: 'İl', type: "text"},
          {field: 'district1', labelName: 'İlçe', type: "text"},
          {field: 'address1', labelName: 'Adres', type: "text"},
        ]
      },
      {
        sectionLabel: "Adres 2",
        fields:[
          {field: 'addressType2', labelName: 'Adres tipi', type: "text"},
          {field: 'city2', labelName: 'İl', type: "text"},
          {field: 'district2', labelName: 'İlçe', type: "text"},
          {field: 'address2', labelName: 'Adres', type: "text"},
        ]
      },
      {
        sectionLabel: "Banka 1",
        fields:[
          {field: 'bankName1', labelName: 'Banka Adı', type: "text"},
          {field: 'iban1', labelName: 'IBAN', type: "text"},
        ]
      },
      {
        sectionLabel: "Banka 2",
        fields:[
          {field: 'bankName2', labelName: 'Banka Adı', type: "text"},
          {field: 'iban2', labelName: 'IBAN', type: "text"},
        ]
      },
      {
        sectionLabel: "Yetkili 1",
        visible: {field: 'type', values: ['Tüzel']},
        fields:[
          {field: 'authorized_1_FullName', labelName: 'Adı Soyadı', type: "text"},
          {field: 'authorized_1_TC', labelName: 'TC Kimlik No', type: "text"},
          {field: 'authorized_1_GSM1', labelName: 'Tel1', type: "text"},
          {field: 'authorized_1_GSM2', labelName: 'Tel2', type: "text"},
          {field: 'authorized_1_addressType1', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_1_city1', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_1_district1', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_1_address1', labelName: 'Adres', type: "text"},
          {field: 'authorized_1_addressType2', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_1_city2', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_1_district2', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_1_address2', labelName: 'Adres', type: "text"}
        ]
      },
      {
        sectionLabel: "Yetkili 2",
        visible: {field: 'type', values: ['Tüzel']},
        fields:[
          {field: 'authorized_2_FullName', labelName: 'Adı Soyadı', type: "text"},
          {field: 'authorized_2_TC', labelName: 'TC Kimlik No', type: "text"},
          {field: 'authorized_2_GSM1', labelName: 'Tel1', type: "text"},
          {field: 'authorized_2_GSM2', labelName: 'Tel2', type: "text"},
          {field: 'authorized_2_addressType1', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_2_city1', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_2_district1', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_2_address1', labelName: 'Adres', type: "text"},
          {field: 'authorized_2_addressType2', labelName: 'Adres Türü', type: "text"},
          {field: 'authorized_2_city2', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_2_district2', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_2_address2', labelName: 'Adres', type: "text"}
        ]
      },
      {field: 'note', labelName: 'Notlar', type: "textarea", hide: greaterThanSm},
    ],
    query: {
      objectName: "Client",
      recordId: clientId,
    },
    messages: {}
  }

  const warrantListTemplate = {
    subPageKey: "warrant",
    subPageParams: "clientId=" + clientId,
    returnPage: "client?id=" + clientId,
    fields: [
      {field: 'no', headerName: 'Vekaletname Belge No', type: "text", flex: 1, visible: true,},
      {field: 'notaryName', headerName: 'Noter Adı', type: "text", flex: 1, visible: true,},
      {field: 'wageNo', headerName: 'Yevmiye No', type: "text", flex: 1, visible: greaterThanSm,},
      {field: 'wageDate', headerName: 'Yevmiye Tarihi', type: "date", flex: 1, visible: greaterThanSm},
      {field: 'note', headerName: 'Note', type: "text", flex: 1, visible: greaterThanSm},
    ],
    query:{
      objectName: "Warrant",
      order: [
        ['notaryName', 'ASC']
      ],
      where: {
        clientId
      }
    },
    messages: {
      sectionHeader: "Vekalet Listesi",
      newRecord: "Yeni Vekalet"
    }
  }

  const caseFileListTemplate = {
    subPageKey: "case-file",
    returnPage: "back",
    subPageParams: (clientId)  ? "clientId=" + clientId : "",

    fields: [
      {field: 'officeFileNo', headerName: 'Ofis Dosya No', type: "text", flex: 1, visible: true, mobileHeader: "onlyName"},
      {field: 'opponents',subField: "fullName", headerName: 'Karşı Taraf', type: "objectList", flex: 1, visible: true, linkedTo: {path: "opponent",param: "id"}},
      {field: 'courtName', headerName: 'Mahkeme Adı', type: "text", flex: 1, visible: true},
      {field: 'courtFileName', headerName: 'Mahkeme Dosya No', type: "text", flex: 1, visible: true},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true},
    ],
    query:{
      objectName: "CaseFile",
      order: [
        ['createdAt', 'DESC']
      ],
      where: {

      },
      include: [
        {
          objectName: "Client",
          fieldName: 'clients',
          where: { id: clientId }
        },
        {
          objectName: "Opponent",
          fieldName: 'opponents'
        }
      ]
    },
    messages: {
      sectionHeader: "Dosya Listesi",
      newRecord: "Yeni Dosya"
    },
    disabledActions: {
      remove: true
    }
  }

  const listTemplates = [
    warrantListTemplate,
    caseFileListTemplate,
  ]

  return (
    <div>

      <Grid container spacing={0}>
        <Grid xs={12} sm={6} md={4} >
          <div><CrDisplayOne template={clientFetchTemplate1}/></div>
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <div><CrDisplayOne template={clientFetchTemplate2}/></div>
        </Grid>
      </Grid>

      <br/>
      {listTemplates.map((listTemplate) => {
        return <Accordion
          defaultExpanded={JSON.parse(sessionStorage.getItem('epanded_list_client'))?.[listTemplate.subPageKey]}
          onChange={(e,expanded) => {
            if (e !== null) {
              sessionStorage.setItem('epanded_list_client', JSON.stringify({...JSON.parse(sessionStorage.getItem('epanded_list_client')), [listTemplate.subPageKey]:expanded}));
            }
          }}>
          <AccordionSummary sx={{
            backgroundColor: "#e8eff9",
            '.MuiAccordionSummary-content.Mui-expanded': {
              margin: 1
            },
            '.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '40px'
            },
          }}
                            style={{height: 40, minHeight: 40}}
                            expandIcon={<ExpandMoreIcon/>}
                            id={listTemplate.subPageKey}
          >
            <span style={{fontWeight: "bold", fontSize: 20}}>{listTemplate.messages.sectionHeader}</span>
          </AccordionSummary>
          <AccordionDetails style={{padding: 0}}>
            <Box>

              <CrList template={listTemplate}/>
            </Box>
          </AccordionDetails>
        </Accordion>
      })}

    </div>
  );
}
