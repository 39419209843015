import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Link, useMediaQuery} from "@mui/material";
import apiService from "../../api/apiService";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import DesktopDatagrid from "../DesktopDatagrid";
import MobileCardList from "../MobileCardList";
import AddIcon from "@mui/icons-material/Add";
import Switch from '@mui/material/Switch';
import {publish, subscribe} from "../../util/eventBus";
import {useNavigate, useLocation} from "react-router-dom";
import CrLink from "../CrLink";
import moment from "moment";
import ExpenseTotal from "../ExpenseTotal";


const kebabCase = str => str
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .join('-')
  .toLowerCase();

function CrList({template}) {
  let [list, setList] = useState([]);
  const {pathname} = useLocation();
  const navigate = useNavigate();

  let columns = template.fields.filter((field) => field.visible);
  columns.forEach((c) => {
    switch (c.type) {
      case "date":
      case "datetime":
        c.valueGetter = (params) => {
          return new Date(params.value);
        }
        if(c.type === "datetime")
          c.renderCell = (params) => {
            return <span>{new Date(params.value).toLocaleString()}</span>
          }
        break;
        case "currency":
        c.valueGetter = (params) => {
          return params.value;
        }
          c.renderCell = (params) => {
            return <span>{Number(params.value).toLocaleString()}</span>
          }
        break;
        case "role":
        c.valueGetter = (params) => {
          switch (params.value) {
            case "ADMIN":
              return "Yönetici"
            case "USER":
              return "Kullanıcı"
          }
        }
        break;
      case "object":
        c.valueGetter = (params) => {
          if (!(c?.parentFieldName)) {
            return params.row?.[c?.field]?.[c?.subField];
          } else {
            const item = list.find(item => item.id === params.id);
            if (c?.subField)
              return item?.[c?.parentFieldName]?.[c?.field]?.[c?.subField];
            else
              return item?.[c?.parentFieldName]?.[c?.field];
          }
        }
        c.idGetter = (params) => {
          if (!(c?.parentFieldName)) {
            return params.row?.[c?.field]?.id;
          } else {
            const item = list.find(item => item.id === params.id);
            if (c?.subField)
              return item?.[c?.parentFieldName]?.[c?.field]?.id;
            else
              return item?.[c?.parentFieldName]?.id;
          }
        }
        break;
        case "objectCount":
        c.valueGetter = (params) => {
          return params.row?.[c?.field].length;
        }
        break;
        case "disabled":
        c.valueGetter = (params) => {
          return params.row?.[c?.field] === false ? "Aktif" : "Pasif";
        }
        break;
      case "objectList":
        c.renderCell = (params) => {
          let objectListRow = params.row;
          if (c.parentFieldName) {
            objectListRow = params.row[c.parentFieldName]
          }
          return <div style={{display: "flex", flexDirection: "column", gap: 0}}>
            {objectListRow[c.field].map((object) => {
              return <CrLink style={{}}
                             href={"/" + (kebabCase(c.linkedTo.path)) + "?id=" + object.id}>{object[c.subField]}</CrLink>
            })}
          </div>
        }
        break
      case "switch":
        c.renderCell = (params) => {
          return <Switch
            checked={params.row?.[c?.field] !== null}
            onChange={async (event) => {
              const query = {
                objectName: template.query.objectName,
                recordId: params.row?.id
              };
              await apiService.cmnEdit({
                ...query,
                recordValues: {[c.field]: event.target.checked ? params.row?.[c.relatedValueFieldName]?.[c.relatedValueSubFieldName] : null}
              });
              init();
              publish("refresh_" + params.row?.[c.relatedValueFieldName]?.[c.relatedValueSubFieldName])
            }}
          />
        }
        break
      case "switchTrueFalse":
        c.renderCell = (params) => {
          return <Switch
            checked={params.row?.[c?.field]}
            onChange={async (event) => {
              const query = {
                objectName: template.query.objectName,
                recordId: params.row?.id
              };
              await apiService.cmnEdit({
                ...query,
                recordValues: {[c.field]: event.target.checked}
              });
              init();
            }}
          />
        }
        break
      case "barcode":
        c.renderCell = (params) => {
          return <Link onClick={async () => {
            await navigator.clipboard.writeText(params.value);
            window.open("https://gonderitakip.ptt.gov.tr/Track", '_blank', 'noreferrer')
          }} style={{paddingTop: 6, paddingBottom: 6}}>{params.value}</Link>
        }
        break
    }

    if (c.linkedTo && c.type !== "objectList") {
      c.renderCell = (params) => {
        return <CrLink style={{paddingTop: 6, paddingBottom: 6}}
                       href={"/" + (c.parentFieldName ? kebabCase(c.parentFieldName) : kebabCase(c.field)) + "?id=" + c.idGetter(params)}>{c.valueGetter(params)}</CrLink>
      }
    }
  });


  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  async function init() {
    const response = await apiService.cmnList(template.query);
    setList(response.data.payload);
  }

  useEffect(() => {
    init();
    subscribe("refresh_" + template.query.objectName, () => {
      init();
    });
  }, [pathname]);


  return (<div style={{marginBottom: 0}}>
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 12}}>
      {template.messages.pageHeader && <h2>{template.messages.pageHeader}</h2>}
      <Box flexGrow={1}></Box>
      <Button style={{}} variant="text" startIcon={<AddIcon/>}
              onClick={() => {
                navigate(`/create-${template.subPageKey}?${template.subPageParams ? template.subPageParams : ""}`)
              }
              }
      >{template.messages.newRecord}</Button>
    </div>
    {greaterThanSm ? <DesktopDatagrid columns={columns} rows={list} template={template}/> :
      <MobileCardList columns={columns} rows={list} template={template}/>}

  </div>);
}

export default CrList;

