import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {caseSteps, taskType} from "../Data/optionsData";


function TaskCreate() {
  const taskId = new URLSearchParams(window.location.search).get('id')
  const caseFileId = new URLSearchParams(window.location.search).get('caseFileId')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const caseFileEditTemplate = {
    id: taskId,
    returnPage: "back",
    fields: [
      {field: 'caseFileId', subField: "courtName", additionalField: "courtFileName", associatedFieldName: "caseFile", labelName: 'Dava', type: "object", objectName: "CaseFile", required: true, visible: caseFileId === null},
      {field: 'taskDefinition', labelName: 'Görev Tanımı', type: "autocomplete", required: true, options: taskType},
/*
      {field: 'tDate', labelName: 'Görevlendirme Tarihi', type: "datetime", required: false},
*/
      {field: 'date', labelName: 'Görevin Yapılacağı Tarih', type: "date", required: false},
      {field: 'dueDate', labelName: 'Görev Son Gün', type: "date", required: false},
      {field: 'appointer', labelName: 'Görevlendiren Kişi', type: "text", required: false},
      {field: 'responsible', labelName: 'Görevli Kişi', type: "text", required: false},
      {field: 'description', labelName: 'Görev Detayı', type: "text", required: false}
    ],
    defaultRecordValues:{
      clientId,
      caseFileId
    },
    query: {
      objectName: "Task",
      recordId: taskId,
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={caseFileEditTemplate}/>

    </div>
  );
}

export default TaskCreate;
