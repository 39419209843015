import * as React from "react";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CrList from "../components/crud/CrList";

function CaseFiles() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));

  const caseFileListTemplate = {
    subPageKey: "case-file",

    returnPage: "back",
    fields: [
      {field: 'officeFileNo', headerName: 'Ofis Dosya No', type: "text", flex: 1, visible: true, mobileHeader: "onlyName"},
      {field: 'clients',subField: "fullName", headerName: 'Müvekkil', type: "objectList", flex: 1, visible: true, linkedTo: {path: "client",param: "id"}},
      {field: 'opponents',subField: "fullName", headerName: 'Karşı Taraf', type: "objectList", flex: 1, visible: true, linkedTo: {path: "opponent",param: "id"}},
      {field: 'courtName', headerName: 'Mahkeme Adı', type: "text", flex: 1, visible: true},
      {field: 'courtFileName', headerName: 'Mahkeme Dosya No', type: "text", flex: 1, visible: true},
      {field: 'note', headerName: 'Notlar', type: "text", flex: 1, visible: true},
    ],
    query:{
      objectName: "CaseFile",
      order: [
        ['createdAt', 'DESC']
      ],
      include: [
        {
          objectName: "Client",
          fieldName: 'clients'
        },
        {
          objectName: "Opponent",
          fieldName: 'opponents'
        }
        ]
    },
    messages: {
      pageHeader: "Dosya Listesi",
      newRecord: "Yeni Dosya"
    },
    disabledActions: {
      remove: true
    }
  }

  return (
    <div>
      <CrList template={caseFileListTemplate}/>

    </div>
  );
}

export default CaseFiles;
