import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import trLocale from '@fullcalendar/core/locales/tr';
import listPlugin from '@fullcalendar/list';
import {Button, ButtonGroup, useMediaQuery} from "@mui/material";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";
import Box from "@mui/material/Box";
import './BigCalendar.css';
import apiService from "../api/apiService";
import EventDetail from "../components/EventDetail";
import {useLocation} from "react-router-dom";


export default function BigCalendar() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const greaterThanSm = useMediaQuery(theme.breakpoints.up("sm"));
  const calendarRef = React.createRef();
  let [view, setView] = useState(greaterThanSm ? 'timeGridMonth' : 'timeGridDay');
  const [events, setEvents] = useState([]);
  const [newEventOpt, setNewEventOpt] = useState(false);
  const [visStartEnd, setVisStartEnd] = useState({});
  const {pathname} = useLocation();

  useEffect(() => {
    init()
  }, [visStartEnd, pathname]);

  async function init() {
    const listEventResponse = await apiService.fetchEvents({
      start: visStartEnd.start,
      end: visStartEnd.end
    });

    if (listEventResponse.status === 200) {
      setEvents(listEventResponse.data.payload)
    }
  }

  function renderEventContent(eventInfo) {
    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <b>{eventInfo.timeText}</b>
        <span style={{wordWrap: false}}>{eventInfo.event.title}</span>
      </div>
    )
  }

//https://fullcalendar.io/docs#toc
  const workSpec = [
    {
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      startTime: '05:00',
      endTime: '23:00'
    },
  ]

  /*ialo
   * calculate the following:
   * - minimum "opening time"
   * - maximum "opening time"
   * - working days
   * - non-working days
   */
  const workMin = workSpec.map(item => item.startTime).sort().shift()
  const workMax = workSpec.map(item => item.endTime).sort().pop()
  const workDays = [...new Set(workSpec.flatMap(item => item.daysOfWeek))]
  const hideDays = [...Array(7).keys()].filter(day => !workDays.includes(day))

  return (
    <div>

      <div style={{
        display: "flex",
        marginBottom: 12,
        position: greaterThanSm ? "absolute" : "relative",
        width: "-webkit-fill-available",
        marginRight: greaterThanSm ? 24 : 0
      }}>
        <div style={{display: "flex"}}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button style={{minWidth: 48, width: 48}}
                    startIcon={<NavigateBefore style={{marginLeft: 12, fontSize: 28}}/>}
                    onClick={() => {
                      calendarRef.current.getApi().prev()
                      //init(calendarRef.current.getApi().view.activeStart, calendarRef.current.getApi().view.activeStart)
                    }
                    }></Button>
            <Button style={{minWidth: 48, width: 48}} startIcon={<NavigateNext style={{marginLeft: 12, fontSize: 28}}/>}
                    onClick={() => calendarRef.current.getApi().next()}></Button>
          </ButtonGroup>
        </div>
        <Box flexGrow={1}></Box>
        <div style={{display: "flex"}}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              onClick={() => {
                calendarRef.current.getApi().changeView('timeGridDay');
                setView('timeGridDay');
              }}>{greaterThanSm ? "GÜN" : "GÜN"}</Button>
            {greaterThanSm && <Button
              onClick={() => {
                calendarRef.current.getApi().changeView('timeGridWeek');
                setView('timeGridWeek');
              }}>{greaterThanSm ? "HAFTA" : "H"}</Button>}
            <Button
              onClick={() => {
                calendarRef.current.getApi().changeView('dayGridMonth');
                setView('dayGridMonth');
              }}>{greaterThanSm ? "AY" : "AY"}</Button>
            <Button
              onClick={() => {
                calendarRef.current.getApi().changeView('listWeek');
                setView('listWeek');
              }}>{greaterThanSm ? "AJANDA" : "LİSTE"}</Button>
          </ButtonGroup>
        </div>
      </div>

      <div minWidth={900}>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, listPlugin, dayGridPlugin, interactionPlugin]}
          initialView={greaterThanSm ? 'dayGridMonth' : 'timeGridDay'}
          headerToolbar={{
            left: '',
            center: 'title',
            right: ''
          }}
          height="auto"
          weekends={greaterThanSm}
          locale={trLocale}
          events={events}
          allDaySlot={false}
          expandRows={false}
          nowIndicator={true}
          businessHours={workSpec}
          slotMinTime={workMin}
          slotMaxTime={workMax}
          hiddenDays={hideDays}
          datesSet={(arg) => {
            if (arg.start.toString() === visStartEnd?.start?.toString() && arg.end.toString() === visStartEnd?.end?.toString())
              return

            setVisStartEnd({
              start: arg.start,
              end: arg.end
            })
          }}
          dateClick={(info) => {
            setNewEventOpt({opType: "create", start: info.date})
          }}
          eventClick={(info) => {
            let event = events.find((e) => e.id === info.event.id)
            setNewEventOpt({opType: "edit", ...event})
          }}
        />
      </div>

      <EventDetail options={newEventOpt} onClose={() => init()}/>
    </div>
  );
}
