import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {
  caseSteps,
  documentGeneralType,
  documentType,
} from "../Data/optionsData";


function DocumentCreate() {
  const documentId = new URLSearchParams(window.location.search).get('id')
  const caseFileId = new URLSearchParams(window.location.search).get('caseFileId')
  const clientId = new URLSearchParams(window.location.search).get('clientId')

  const documentEditTemplate = {
    id: documentId,
    returnPage: "back",
    fields: [
      {field: 'no', labelName: 'Evrak No', type: "text", required: true, options: caseSteps},
      {field: 'date', labelName: 'Evrak Kayıt Tarihi', type: "date", required: true, options: caseSteps},
      {field: 'type', labelName: 'Türü', type: "searchable", required: true, options: documentGeneralType},
      {field: 'receiver', labelName: 'Teslim Alan', type: "text", required: true},
      {field: 'receiverTC', labelName: 'Teslim Alan TC No', type: "text", required: false},
      {field: 'deliverer', labelName: 'Teslim Eden', type: "text", required: true},
      {field: 'delivererTC', labelName: 'Teslim Eden TC No', type: "text", required: false},
      {field: 'docType', labelName: 'Evrak Türü', type: "searchable", required: true, options: documentType},
      {field: 'documentTopic', labelName: 'Evrak Konusu', type: "text", required: false},
      {field: 'note', labelName: 'Notlar', type: "textarea", required: false},
    ],
    defaultRecordValues:{
      clientId,
      caseFileId
    },
    query: {
      objectName: "Document",
      recordId: documentId,
      include: [
        {
          objectName: "CaseFile",
          fieldName: 'caseFile'
        }
      ]
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={documentEditTemplate}/>

    </div>
  );
}

export default DocumentCreate;
