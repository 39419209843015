import * as React from "react";
import CrCreateEdit from "../components/crud/CrCreateEdit";
import {TextField} from "@mui/material";
import {useState} from "react";
import {addressType, allowedCityAddressType, nationalityType} from "../Data/optionsData";
import {cities} from "../Data/cityData";


function CreateClient() {
  const clientId = new URLSearchParams(window.location.search).get('id')

  const clientEditTemplate = {
    id: clientId,
    returnPage: "clients",
    fields: [
      {field: 'no', labelName: 'No', type: "number", required: true},
      {
        field: 'type', labelName: 'Tür', type: "autocomplete", required: true,
        options: [{key: "Şahıs"}, {key: "Tüzel"}]
      },
      {field: 'fullName', labelName: 'Adı Soyadı', type: "text", required: true, visible: {field: 'type', values: ['Şahıs']}},
      {field: 'fullName', labelName: 'Kurum Adı', type: "text", required: true, visible: {field: 'type', values: ['Tüzel']}},
      {field: 'tcTaxNo', labelName: 'TC No', type: "text", required: true, visible: {field: 'type', values: ['Şahıs']}},
      {field: 'tcTaxNo', labelName: 'Vergi No', type: "text", required: true, visible: {field: 'type', values: ['Tüzel']}},
      {field: 'nationality', labelName: 'Uyruk', type: "autocomplete", options: nationalityType, visible: {field: 'type', values: ['Şahıs']}},
      {field: 'mersisNo', labelName: 'Mersis No', type: "text", visible: {field: 'type', values: ['Tüzel']}},
      {field: 'gsm1', labelName: 'Tel 1', type: "text"},
      {field: 'gsm2', labelName: 'Tel 2', type: "text"},
      {field: 'email', labelName: 'E-Posta', type: "text"},
      {sectionLabel: "Adres 1",
        fields:[
          {field: 'addressType1', labelName: 'Adres tipi', type: "autocomplete", options: addressType},
          {field: 'city1', labelName: 'İl', type: "autocomplete", options: cities, visible: {field: 'addressType1', values: allowedCityAddressType}},
          {field: 'district1', labelName: 'İlçe', type: "autocomplete", associatedFieldName: "city1", blockerField: 'city1', options:[], visible: {field: 'addressType1', values: allowedCityAddressType}},
          {field: 'address1', labelName: 'Adres', type: "text"},
        ]
      },
      {sectionLabel: "Adres 2",
        fields:[
          {field: 'addressType2', labelName: 'Adres tipi', type: "autocomplete", options: addressType},
          {field: 'city2', labelName: 'İl', type: "autocomplete", options: cities, visible: {field: 'addressType2', values: allowedCityAddressType}},
          {field: 'district2', labelName: 'İlçe', type: "autocomplete", associatedFieldName: "city2", blockerField: 'city2', options:[], visible: {field: 'addressType2', values: allowedCityAddressType}},
          {field: 'address2', labelName: 'Adres', type: "text"},
        ]
      },
      {sectionLabel: "Banka 1",
        fields:[
          {field: 'bankName1', labelName: 'Banka Adı', type: "text"},
          {field: 'iban1', labelName: 'IBAN', type: "text"}
        ]
      },
      {sectionLabel: "Banka 2",
        fields:[
          {field: 'bankName2', labelName: 'Banka Adı', type: "text"},
          {field: 'iban2', labelName: 'IBAN', type: "text"}
        ]
      },
      {field: 'note', labelName: 'Notlar', type: "textarea"},
      {sectionLabel: "Yetkili 1", visible: {field: 'type', values: ['Tüzel']},
        fields:[
          {field: 'authorized_1_FullName', labelName: 'Adı Soyadı', type: "text"},
          {field: 'authorized_1_TC', labelName: 'TC Kimlik No', type: "text"},
          {field: 'authorized_1_GSM1', labelName: 'Tel1', type: "text"},
          {field: 'authorized_1_GSM2', labelName: 'Tel2', type: "text"},
          {field: 'authorized_1_addressType1', labelName: 'Adres Türü', type: "autocomplete", options: addressType},
          {field: 'authorized_1_city1', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_1_district1', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_1_address1', labelName: 'Adres', type: "text"},
          {field: 'authorized_1_addressType2', labelName: 'Adres Türü', type: "autocomplete", options: addressType},
          {field: 'authorized_1_city2', labelName: 'İl', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_1_district2', labelName: 'İlçe', type: "text", visible: {field: 'authorized_1_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_1_address2', labelName: 'Adres', type: "text"}
        ]
      },
      {sectionLabel: "Yetkili 2", visible: {field: 'type', values: ['Tüzel']},
        fields:[
          {field: 'authorized_2_FullName', labelName: 'Adı Soyadı', type: "text"},
          {field: 'authorized_2_TC', labelName: 'TC Kimlik No', type: "text"},
          {field: 'authorized_2_GSM1', labelName: 'Tel1', type: "text"},
          {field: 'authorized_2_GSM2', labelName: 'Tel2', type: "text"},
          {field: 'authorized_2_addressType1', labelName: 'Adres Türü', type: "autocomplete", options: addressType},
          {field: 'authorized_2_city1', labelName: 'İl', type: "text", visible: {field: 'authorized_2_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_2_district1', labelName: 'İlçe', type: "text", visible: {field: 'authorized_2_addressType1', values: allowedCityAddressType}},
          {field: 'authorized_2_address1', labelName: 'Adres', type: "text"},
          {field: 'authorized_2_addressType2', labelName: 'Adres Türü', type: "autocomplete", options: addressType},
          {field: 'authorized_2_city2', labelName: 'İl', type: "text", visible: {field: 'authorized_2_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_2_district2', labelName: 'İlçe', type: "text", visible: {field: 'authorized_2_addressType2', values: allowedCityAddressType}},
          {field: 'authorized_2_address2', labelName: 'Adres', type: "text"}
        ]
      },
    ],
    query: {
      objectName: "Client",
      recordId: clientId,
    },
    messages: {
    }
  }

  return (
    <div>
      <CrCreateEdit template={clientEditTemplate}/>

    </div>
  );
}

export default CreateClient;

