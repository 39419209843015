export const cities = [
  {
    "value": 1,
    "key": "ADANA",
    "subOptions": [
      {
        "value": 1757,
        "key": "ALADAĞ"
      },
      {
        "value": 1219,
        "key": "CEYHAN"
      },
      {
        "value": 2033,
        "key": "ÇUKUROVA"
      },
      {
        "value": 1329,
        "key": "FEKE"
      },
      {
        "value": 1806,
        "key": "İMAMOĞLU"
      },
      {
        "value": 1437,
        "key": "KARAİSALI"
      },
      {
        "value": 1443,
        "key": "KARATAŞ"
      },
      {
        "value": 1486,
        "key": "KOZAN"
      },
      {
        "value": 1580,
        "key": "POZANTI"
      },
      {
        "value": 1588,
        "key": "SAİMBEYLİ"
      },
      {
        "value": 2032,
        "key": "SARIÇAM"
      },
      {
        "value": 1104,
        "key": "SEYHAN"
      },
      {
        "value": 1687,
        "key": "TUFANBEYLİ"
      },
      {
        "value": 1734,
        "key": "YUMURTALIK"
      },
      {
        "value": 1748,
        "key": "YÜREĞİR"
      }
    ]
  },
  {
    "value": 2,
    "key": "ADIYAMAN",
    "subOptions": [
      {
        "value": 1182,
        "key": "BESNİ"
      },
      {
        "value": 1246,
        "key": "ÇELİKHAN"
      },
      {
        "value": 1347,
        "key": "GERGER"
      },
      {
        "value": 1354,
        "key": "GÖLBAŞI"
      },
      {
        "value": 1425,
        "key": "KAHTA"
      },
      {
        "value": 1105,
        "key": "MERKEZ"
      },
      {
        "value": 1592,
        "key": "SAMSAT"
      },
      {
        "value": 1985,
        "key": "SİNCİK"
      },
      {
        "value": 1989,
        "key": "TUT"
      }
    ]
  },
  {
    "value": 3,
    "key": "AFYONKARAHİSAR",
    "subOptions": [
      {
        "value": 1771,
        "key": "BAŞMAKÇI"
      },
      {
        "value": 1773,
        "key": "BAYAT"
      },
      {
        "value": 1200,
        "key": "BOLVADİN"
      },
      {
        "value": 1239,
        "key": "ÇAY"
      },
      {
        "value": 1906,
        "key": "ÇOBANLAR"
      },
      {
        "value": 1267,
        "key": "DAZKIRI"
      },
      {
        "value": 1281,
        "key": "DİNAR"
      },
      {
        "value": 1306,
        "key": "EMİRDAĞ"
      },
      {
        "value": 1923,
        "key": "EVCİLER"
      },
      {
        "value": 1944,
        "key": "HOCALAR"
      },
      {
        "value": 1404,
        "key": "İHSANİYE"
      },
      {
        "value": 1809,
        "key": "İSCEHİSAR"
      },
      {
        "value": 1961,
        "key": "KIZILÖREN"
      },
      {
        "value": 1108,
        "key": "MERKEZ"
      },
      {
        "value": 1594,
        "key": "SANDIKLI"
      },
      {
        "value": 1626,
        "key": "SİNANPAŞA"
      },
      {
        "value": 1664,
        "key": "ŞUHUT"
      },
      {
        "value": 1639,
        "key": "SULTANDAĞI"
      }
    ]
  },
  {
    "value": 4,
    "key": "AĞRI",
    "subOptions": [
      {
        "value": 1283,
        "key": "DİYADİN"
      },
      {
        "value": 1287,
        "key": "DOĞUBAYAZIT"
      },
      {
        "value": 1301,
        "key": "ELEŞKİRT"
      },
      {
        "value": 1379,
        "key": "HAMUR"
      },
      {
        "value": 1111,
        "key": "MERKEZ"
      },
      {
        "value": 1568,
        "key": "PATNOS"
      },
      {
        "value": 1667,
        "key": "TAŞLIÇAY"
      },
      {
        "value": 1691,
        "key": "TUTAK"
      }
    ]
  },
  {
    "value": 68,
    "key": "AKSARAY",
    "subOptions": [
      {
        "value": 1860,
        "key": "AĞAÇÖREN"
      },
      {
        "value": 1921,
        "key": "ESKİL"
      },
      {
        "value": 1932,
        "key": "GÜLAĞAÇ"
      },
      {
        "value": 1861,
        "key": "GÜZELYURT"
      },
      {
        "value": 1120,
        "key": "MERKEZ"
      },
      {
        "value": 1557,
        "key": "ORTAKÖY"
      },
      {
        "value": 1866,
        "key": "SARIYAHŞİ"
      },
      {
        "value": 2106,
        "key": "SULTANHANI"
      }
    ]
  },
  {
    "value": 5,
    "key": "AMASYA",
    "subOptions": [
      {
        "value": 1363,
        "key": "GÖYNÜCEK"
      },
      {
        "value": 1368,
        "key": "GÜMÜŞHACIKÖY"
      },
      {
        "value": 1938,
        "key": "HAMAMÖZÜ"
      },
      {
        "value": 1134,
        "key": "MERKEZ"
      },
      {
        "value": 1524,
        "key": "MERZİFON"
      },
      {
        "value": 1641,
        "key": "SULUOVA"
      },
      {
        "value": 1668,
        "key": "TAŞOVA"
      }
    ]
  },
  {
    "value": 6,
    "key": "ANKARA",
    "subOptions": [
      {
        "value": 1872,
        "key": "AKYURT"
      },
      {
        "value": 1130,
        "key": "ALTINDAĞ"
      },
      {
        "value": 1157,
        "key": "AYAŞ"
      },
      {
        "value": 1167,
        "key": "BALA"
      },
      {
        "value": 1187,
        "key": "BEYPAZARI"
      },
      {
        "value": 1227,
        "key": "ÇAMLIDERE"
      },
      {
        "value": 1231,
        "key": "ÇANKAYA"
      },
      {
        "value": 1260,
        "key": "ÇUBUK"
      },
      {
        "value": 1302,
        "key": "ELMADAĞ"
      },
      {
        "value": 1922,
        "key": "ETİMESGUT"
      },
      {
        "value": 1924,
        "key": "EVREN"
      },
      {
        "value": 1744,
        "key": "GÖLBAŞI"
      },
      {
        "value": 1365,
        "key": "GÜDÜL"
      },
      {
        "value": 1387,
        "key": "HAYMANA"
      },
      {
        "value": 1815,
        "key": "KAHRAMANKAZAN"
      },
      {
        "value": 1427,
        "key": "KALECİK"
      },
      {
        "value": 1745,
        "key": "KEÇİÖREN"
      },
      {
        "value": 1473,
        "key": "KIZILCAHAMAM"
      },
      {
        "value": 1746,
        "key": "MAMAK"
      },
      {
        "value": 1539,
        "key": "NALLIHAN"
      },
      {
        "value": 1578,
        "key": "POLATLI"
      },
      {
        "value": 2034,
        "key": "PURSAKLAR"
      },
      {
        "value": 1658,
        "key": "ŞEREFLİKOÇHİSAR"
      },
      {
        "value": 1747,
        "key": "SİNCAN"
      },
      {
        "value": 1723,
        "key": "YENİMAHALLE"
      }
    ]
  },
  {
    "value": 7,
    "key": "ANTALYA",
    "subOptions": [
      {
        "value": 1121,
        "key": "AKSEKİ"
      },
      {
        "value": 2035,
        "key": "AKSU"
      },
      {
        "value": 1126,
        "key": "ALANYA"
      },
      {
        "value": 1811,
        "key": "DEMRE"
      },
      {
        "value": 2036,
        "key": "DÖŞEMEALTI"
      },
      {
        "value": 1303,
        "key": "ELMALI"
      },
      {
        "value": 1333,
        "key": "FİNİKE"
      },
      {
        "value": 1337,
        "key": "GAZİPAŞA"
      },
      {
        "value": 1370,
        "key": "GÜNDOĞMUŞ"
      },
      {
        "value": 1946,
        "key": "İBRADI"
      },
      {
        "value": 1451,
        "key": "KAŞ"
      },
      {
        "value": 1959,
        "key": "KEMER"
      },
      {
        "value": 2037,
        "key": "KEPEZ"
      },
      {
        "value": 2038,
        "key": "KONYAALTI"
      },
      {
        "value": 1483,
        "key": "KORKUTELİ"
      },
      {
        "value": 1492,
        "key": "KUMLUCA"
      },
      {
        "value": 1512,
        "key": "MANAVGAT"
      },
      {
        "value": 2039,
        "key": "MURATPAŞA"
      },
      {
        "value": 1616,
        "key": "SERİK"
      }
    ]
  },
  {
    "value": 75,
    "key": "ARDAHAN",
    "subOptions": [
      {
        "value": 1252,
        "key": "ÇILDIR"
      },
      {
        "value": 2008,
        "key": "DAMAL"
      },
      {
        "value": 1356,
        "key": "GÖLE"
      },
      {
        "value": 1380,
        "key": "HANAK"
      },
      {
        "value": 1144,
        "key": "MERKEZ"
      },
      {
        "value": 1579,
        "key": "POSOF"
      }
    ]
  },
  {
    "value": 8,
    "key": "ARTVİN",
    "subOptions": [
      {
        "value": 1145,
        "key": "ARDANUÇ"
      },
      {
        "value": 1147,
        "key": "ARHAVİ"
      },
      {
        "value": 1202,
        "key": "BORÇKA"
      },
      {
        "value": 1395,
        "key": "HOPA"
      },
      {
        "value": 2105,
        "key": "KEMALPAŞA"
      },
      {
        "value": 1152,
        "key": "MERKEZ"
      },
      {
        "value": 1828,
        "key": "MURGUL"
      },
      {
        "value": 1653,
        "key": "ŞAVŞAT"
      },
      {
        "value": 1736,
        "key": "YUSUFELİ"
      }
    ]
  },
  {
    "value": 9,
    "key": "AYDIN",
    "subOptions": [
      {
        "value": 1206,
        "key": "BOZDOĞAN"
      },
      {
        "value": 1781,
        "key": "BUHARKENT"
      },
      {
        "value": 1256,
        "key": "ÇİNE"
      },
      {
        "value": 2000,
        "key": "DİDİM"
      },
      {
        "value": 2076,
        "key": "EFELER"
      },
      {
        "value": 1348,
        "key": "GERMENCİK"
      },
      {
        "value": 1807,
        "key": "İNCİRLİOVA"
      },
      {
        "value": 1435,
        "key": "KARACASU"
      },
      {
        "value": 1957,
        "key": "KARPUZLU"
      },
      {
        "value": 1479,
        "key": "KOÇARLI"
      },
      {
        "value": 1968,
        "key": "KÖŞK"
      },
      {
        "value": 1497,
        "key": "KUŞADASI"
      },
      {
        "value": 1498,
        "key": "KUYUCAK"
      },
      {
        "value": 1542,
        "key": "NAZİLLİ"
      },
      {
        "value": 1637,
        "key": "SÖKE"
      },
      {
        "value": 1640,
        "key": "SULTANHİSAR"
      },
      {
        "value": 1724,
        "key": "YENİPAZAR"
      }
    ]
  },
  {
    "value": 10,
    "key": "BALIKESİR",
    "subOptions": [
      {
        "value": 2077,
        "key": "ALTIEYLÜL"
      },
      {
        "value": 1161,
        "key": "AYVALIK"
      },
      {
        "value": 1169,
        "key": "BALYA"
      },
      {
        "value": 1171,
        "key": "BANDIRMA"
      },
      {
        "value": 1191,
        "key": "BİGADİÇ"
      },
      {
        "value": 1216,
        "key": "BURHANİYE"
      },
      {
        "value": 1291,
        "key": "DURSUNBEY"
      },
      {
        "value": 1294,
        "key": "EDREMİT"
      },
      {
        "value": 1310,
        "key": "ERDEK"
      },
      {
        "value": 1928,
        "key": "GÖMEÇ"
      },
      {
        "value": 1360,
        "key": "GÖNEN"
      },
      {
        "value": 1384,
        "key": "HAVRAN"
      },
      {
        "value": 1418,
        "key": "İVRİNDİ"
      },
      {
        "value": 2078,
        "key": "KARESİ"
      },
      {
        "value": 1462,
        "key": "KEPSUT"
      },
      {
        "value": 1514,
        "key": "MANYAS"
      },
      {
        "value": 1824,
        "key": "MARMARA"
      },
      {
        "value": 1608,
        "key": "SAVAŞTEPE"
      },
      {
        "value": 1619,
        "key": "SINDIRGI"
      },
      {
        "value": 1644,
        "key": "SUSURLUK"
      }
    ]
  },
  {
    "value": 74,
    "key": "BARTIN",
    "subOptions": [
      {
        "value": 1761,
        "key": "AMASRA"
      },
      {
        "value": 1496,
        "key": "KURUCAŞİLE"
      },
      {
        "value": 1172,
        "key": "MERKEZ"
      },
      {
        "value": 1701,
        "key": "ULUS"
      }
    ]
  },
  {
    "value": 72,
    "key": "BATMAN",
    "subOptions": [
      {
        "value": 1184,
        "key": "BEŞİRİ"
      },
      {
        "value": 1345,
        "key": "GERCÜŞ"
      },
      {
        "value": 1941,
        "key": "HASANKEYF"
      },
      {
        "value": 1487,
        "key": "KOZLUK"
      },
      {
        "value": 1174,
        "key": "MERKEZ"
      },
      {
        "value": 1607,
        "key": "SASON"
      }
    ]
  },
  {
    "value": 69,
    "key": "BAYBURT",
    "subOptions": [
      {
        "value": 1767,
        "key": "AYDINTEPE"
      },
      {
        "value": 1788,
        "key": "DEMİRÖZÜ"
      },
      {
        "value": 1176,
        "key": "MERKEZ"
      }
    ]
  },
  {
    "value": 11,
    "key": "BİLECİK",
    "subOptions": [
      {
        "value": 1210,
        "key": "BOZÜYÜK"
      },
      {
        "value": 1359,
        "key": "GÖLPAZARI"
      },
      {
        "value": 1948,
        "key": "İNHİSAR"
      },
      {
        "value": 1192,
        "key": "MERKEZ"
      },
      {
        "value": 1559,
        "key": "OSMANELİ"
      },
      {
        "value": 1571,
        "key": "PAZARYERİ"
      },
      {
        "value": 1636,
        "key": "SÖĞÜT"
      },
      {
        "value": 1857,
        "key": "YENİPAZAR"
      }
    ]
  },
  {
    "value": 12,
    "key": "BİNGÖL",
    "subOptions": [
      {
        "value": 1750,
        "key": "ADAKLI"
      },
      {
        "value": 1344,
        "key": "GENÇ"
      },
      {
        "value": 1446,
        "key": "KARLIOVA"
      },
      {
        "value": 1475,
        "key": "KİĞI"
      },
      {
        "value": 1193,
        "key": "MERKEZ"
      },
      {
        "value": 1633,
        "key": "SOLHAN"
      },
      {
        "value": 1855,
        "key": "YAYLADERE"
      },
      {
        "value": 1996,
        "key": "YEDİSU"
      }
    ]
  },
  {
    "value": 13,
    "key": "BİTLİS",
    "subOptions": [
      {
        "value": 1106,
        "key": "ADİLCEVAZ"
      },
      {
        "value": 1112,
        "key": "AHLAT"
      },
      {
        "value": 1798,
        "key": "GÜROYMAK"
      },
      {
        "value": 1394,
        "key": "HİZAN"
      },
      {
        "value": 1196,
        "key": "MERKEZ"
      },
      {
        "value": 1537,
        "key": "MUTKİ"
      },
      {
        "value": 1669,
        "key": "TATVAN"
      }
    ]
  },
  {
    "value": 14,
    "key": "BOLU",
    "subOptions": [
      {
        "value": 1916,
        "key": "DÖRTDİVAN"
      },
      {
        "value": 1346,
        "key": "GEREDE"
      },
      {
        "value": 1364,
        "key": "GÖYNÜK"
      },
      {
        "value": 1466,
        "key": "KIBRISCIK"
      },
      {
        "value": 1522,
        "key": "MENGEN"
      },
      {
        "value": 1199,
        "key": "MERKEZ"
      },
      {
        "value": 1531,
        "key": "MUDURNU"
      },
      {
        "value": 1610,
        "key": "SEBEN"
      },
      {
        "value": 1997,
        "key": "YENİÇAĞA"
      }
    ]
  },
  {
    "value": 15,
    "key": "BURDUR",
    "subOptions": [
      {
        "value": 1109,
        "key": "AĞLASUN"
      },
      {
        "value": 1874,
        "key": "ALTINYAYLA"
      },
      {
        "value": 1211,
        "key": "BUCAK"
      },
      {
        "value": 1899,
        "key": "ÇAVDIR"
      },
      {
        "value": 1903,
        "key": "ÇELTİKÇİ"
      },
      {
        "value": 1357,
        "key": "GÖLHİSAR"
      },
      {
        "value": 1813,
        "key": "KARAMANLI"
      },
      {
        "value": 1816,
        "key": "KEMER"
      },
      {
        "value": 1215,
        "key": "MERKEZ"
      },
      {
        "value": 1672,
        "key": "TEFENNİ"
      },
      {
        "value": 1728,
        "key": "YEŞİLOVA"
      }
    ]
  },
  {
    "value": 16,
    "key": "BURSA",
    "subOptions": [
      {
        "value": 1783,
        "key": "BÜYÜKORHAN"
      },
      {
        "value": 1343,
        "key": "GEMLİK"
      },
      {
        "value": 1935,
        "key": "GÜRSU"
      },
      {
        "value": 1799,
        "key": "HARMANCIK"
      },
      {
        "value": 1411,
        "key": "İNEGÖL"
      },
      {
        "value": 1420,
        "key": "İZNİK"
      },
      {
        "value": 1434,
        "key": "KARACABEY"
      },
      {
        "value": 1457,
        "key": "KELES"
      },
      {
        "value": 1960,
        "key": "KESTEL"
      },
      {
        "value": 1535,
        "key": "M.KEMALPAŞA"
      },
      {
        "value": 1530,
        "key": "MUDANYA"
      },
      {
        "value": 1829,
        "key": "NİLÜFER"
      },
      {
        "value": 1553,
        "key": "ORHANELİ"
      },
      {
        "value": 1554,
        "key": "ORHANGAZİ"
      },
      {
        "value": 1832,
        "key": "OSMANGAZİ"
      },
      {
        "value": 1725,
        "key": "YENİŞEHİR"
      },
      {
        "value": 1859,
        "key": "YILDIRIM"
      }
    ]
  },
  {
    "value": 17,
    "key": "ÇANAKKALE",
    "subOptions": [
      {
        "value": 1160,
        "key": "AYVACIK"
      },
      {
        "value": 1180,
        "key": "BAYRAMİÇ"
      },
      {
        "value": 1190,
        "key": "BİGA"
      },
      {
        "value": 1205,
        "key": "BOZCAADA"
      },
      {
        "value": 1229,
        "key": "ÇAN"
      },
      {
        "value": 1293,
        "key": "ECEABAT"
      },
      {
        "value": 1326,
        "key": "EZİNE"
      },
      {
        "value": 1340,
        "key": "GELİBOLU"
      },
      {
        "value": 1408,
        "key": "GÖKÇEADA"
      },
      {
        "value": 1503,
        "key": "LAPSEKİ"
      },
      {
        "value": 1230,
        "key": "MERKEZ"
      },
      {
        "value": 1722,
        "key": "YENİCE"
      }
    ]
  },
  {
    "value": 18,
    "key": "ÇANKIRI",
    "subOptions": [
      {
        "value": 1765,
        "key": "ATKARACALAR"
      },
      {
        "value": 1885,
        "key": "BAYRAMÖREN"
      },
      {
        "value": 1248,
        "key": "ÇERKEŞ"
      },
      {
        "value": 1300,
        "key": "ELDİVAN"
      },
      {
        "value": 1399,
        "key": "ILGAZ"
      },
      {
        "value": 1817,
        "key": "KIZILIRMAK"
      },
      {
        "value": 1963,
        "key": "KORGUN"
      },
      {
        "value": 1494,
        "key": "KURŞUNLU"
      },
      {
        "value": 1232,
        "key": "MERKEZ"
      },
      {
        "value": 1555,
        "key": "ORTA"
      },
      {
        "value": 1649,
        "key": "ŞABANÖZÜ"
      },
      {
        "value": 1718,
        "key": "YAPRAKLI"
      }
    ]
  },
  {
    "value": 19,
    "key": "ÇORUM",
    "subOptions": [
      {
        "value": 1124,
        "key": "ALACA"
      },
      {
        "value": 1177,
        "key": "BAYAT"
      },
      {
        "value": 1778,
        "key": "BOĞAZKALE"
      },
      {
        "value": 1911,
        "key": "DODURGA"
      },
      {
        "value": 1414,
        "key": "İSKİLİP"
      },
      {
        "value": 1445,
        "key": "KARGI"
      },
      {
        "value": 1972,
        "key": "LAÇİN"
      },
      {
        "value": 1520,
        "key": "MECİTÖZÜ"
      },
      {
        "value": 1259,
        "key": "MERKEZ"
      },
      {
        "value": 1976,
        "key": "OĞUZLAR"
      },
      {
        "value": 1556,
        "key": "ORTAKÖY"
      },
      {
        "value": 1558,
        "key": "OSMANCIK"
      },
      {
        "value": 1642,
        "key": "SUNGURLU"
      },
      {
        "value": 1850,
        "key": "UĞURLUDAĞ"
      }
    ]
  },
  {
    "value": 20,
    "key": "DENİZLİ",
    "subOptions": [
      {
        "value": 1102,
        "key": "ACIPAYAM"
      },
      {
        "value": 1769,
        "key": "BABADAĞ"
      },
      {
        "value": 1881,
        "key": "BAKLAN"
      },
      {
        "value": 1774,
        "key": "BEKİLLİ"
      },
      {
        "value": 1888,
        "key": "BEYAĞAÇ"
      },
      {
        "value": 1889,
        "key": "BOZKURT"
      },
      {
        "value": 1214,
        "key": "BULDAN"
      },
      {
        "value": 1224,
        "key": "ÇAL"
      },
      {
        "value": 1226,
        "key": "ÇAMELİ"
      },
      {
        "value": 1233,
        "key": "ÇARDAK"
      },
      {
        "value": 1257,
        "key": "ÇİVRİL"
      },
      {
        "value": 1371,
        "key": "GÜNEY"
      },
      {
        "value": 1803,
        "key": "HONAZ"
      },
      {
        "value": 1426,
        "key": "KALE"
      },
      {
        "value": 2079,
        "key": "MERKEZEFENDİ"
      },
      {
        "value": 1871,
        "key": "PAMUKKALE"
      },
      {
        "value": 1597,
        "key": "SARAYKÖY"
      },
      {
        "value": 1840,
        "key": "SERİNHİSAR"
      },
      {
        "value": 1670,
        "key": "TAVAS"
      }
    ]
  },
  {
    "value": 21,
    "key": "DİYARBAKIR",
    "subOptions": [
      {
        "value": 2040,
        "key": "BAĞLAR"
      },
      {
        "value": 1195,
        "key": "BİSMİL"
      },
      {
        "value": 1249,
        "key": "ÇERMİK"
      },
      {
        "value": 1253,
        "key": "ÇINAR"
      },
      {
        "value": 1263,
        "key": "ÇÜNGÜŞ"
      },
      {
        "value": 1278,
        "key": "DİCLE"
      },
      {
        "value": 1791,
        "key": "EĞİL"
      },
      {
        "value": 1315,
        "key": "ERGANİ"
      },
      {
        "value": 1381,
        "key": "HANİ"
      },
      {
        "value": 1389,
        "key": "HAZRO"
      },
      {
        "value": 2041,
        "key": "KAYAPINAR"
      },
      {
        "value": 1962,
        "key": "KOCAKÖY"
      },
      {
        "value": 1490,
        "key": "KULP"
      },
      {
        "value": 1504,
        "key": "LİCE"
      },
      {
        "value": 1624,
        "key": "SİLVAN"
      },
      {
        "value": 2042,
        "key": "SUR"
      },
      {
        "value": 2043,
        "key": "YENİŞEHİR"
      }
    ]
  },
  {
    "value": 81,
    "key": "DÜZCE",
    "subOptions": [
      {
        "value": 1116,
        "key": "AKÇAKOCA"
      },
      {
        "value": 1905,
        "key": "ÇİLİMLİ"
      },
      {
        "value": 1784,
        "key": "CUMAYERİ"
      },
      {
        "value": 1794,
        "key": "GÖLYAKA"
      },
      {
        "value": 2017,
        "key": "GÜMÜŞOVA"
      },
      {
        "value": 2031,
        "key": "KAYNAŞLI"
      },
      {
        "value": 1292,
        "key": "MERKEZ"
      },
      {
        "value": 1730,
        "key": "YIĞILCA"
      }
    ]
  },
  {
    "value": 22,
    "key": "EDİRNE",
    "subOptions": [
      {
        "value": 1307,
        "key": "ENEZ"
      },
      {
        "value": 1385,
        "key": "HAVSA"
      },
      {
        "value": 1412,
        "key": "İPSALA"
      },
      {
        "value": 1464,
        "key": "KEŞAN"
      },
      {
        "value": 1502,
        "key": "LALAPAŞA"
      },
      {
        "value": 1523,
        "key": "MERİÇ"
      },
      {
        "value": 1295,
        "key": "MERKEZ"
      },
      {
        "value": 1988,
        "key": "SÜLOĞLU"
      },
      {
        "value": 1705,
        "key": "UZUNKÖPRÜ"
      }
    ]
  },
  {
    "value": 23,
    "key": "ELAZIĞ",
    "subOptions": [
      {
        "value": 1110,
        "key": "AĞIN"
      },
      {
        "value": 1873,
        "key": "ALACAKAYA"
      },
      {
        "value": 1762,
        "key": "ARICAK"
      },
      {
        "value": 1173,
        "key": "BASKİL"
      },
      {
        "value": 1438,
        "key": "KARAKOÇAN"
      },
      {
        "value": 1455,
        "key": "KEBAN"
      },
      {
        "value": 1820,
        "key": "KOVANCILAR"
      },
      {
        "value": 1506,
        "key": "MADEN"
      },
      {
        "value": 1298,
        "key": "MERKEZ"
      },
      {
        "value": 1566,
        "key": "PALU"
      },
      {
        "value": 1631,
        "key": "SİVRİCE"
      }
    ]
  },
  {
    "value": 24,
    "key": "ERZİNCAN",
    "subOptions": [
      {
        "value": 1243,
        "key": "ÇAYIRLI"
      },
      {
        "value": 1406,
        "key": "İLİÇ"
      },
      {
        "value": 1459,
        "key": "KEMAH"
      },
      {
        "value": 1460,
        "key": "KEMALİYE"
      },
      {
        "value": 1318,
        "key": "MERKEZ"
      },
      {
        "value": 1977,
        "key": "OTLUKBELİ"
      },
      {
        "value": 1583,
        "key": "REFAHİYE"
      },
      {
        "value": 1675,
        "key": "TERCAN"
      },
      {
        "value": 1853,
        "key": "ÜZÜMLÜ"
      }
    ]
  },
  {
    "value": 25,
    "key": "ERZURUM",
    "subOptions": [
      {
        "value": 1153,
        "key": "AŞKALE"
      },
      {
        "value": 1945,
        "key": "AZİZİYE"
      },
      {
        "value": 1235,
        "key": "ÇAT"
      },
      {
        "value": 1392,
        "key": "HINIS"
      },
      {
        "value": 1396,
        "key": "HORASAN"
      },
      {
        "value": 1416,
        "key": "İSPİR"
      },
      {
        "value": 1812,
        "key": "KARAÇOBAN"
      },
      {
        "value": 1444,
        "key": "KARAYAZI"
      },
      {
        "value": 1967,
        "key": "KÖPRÜKÖY"
      },
      {
        "value": 1540,
        "key": "NARMAN"
      },
      {
        "value": 1550,
        "key": "OLTU"
      },
      {
        "value": 1551,
        "key": "OLUR"
      },
      {
        "value": 2044,
        "key": "PALANDÖKEN"
      },
      {
        "value": 1567,
        "key": "PASİNLER"
      },
      {
        "value": 1865,
        "key": "PAZARYOLU"
      },
      {
        "value": 1657,
        "key": "ŞENKAYA"
      },
      {
        "value": 1674,
        "key": "TEKMAN"
      },
      {
        "value": 1683,
        "key": "TORTUM"
      },
      {
        "value": 1851,
        "key": "UZUNDERE"
      },
      {
        "value": 2045,
        "key": "YAKUTİYE"
      }
    ]
  },
  {
    "value": 26,
    "key": "ESKİŞEHİR",
    "subOptions": [
      {
        "value": 1759,
        "key": "ALPU"
      },
      {
        "value": 1777,
        "key": "BEYLİKOVA"
      },
      {
        "value": 1255,
        "key": "ÇİFTELER"
      },
      {
        "value": 1934,
        "key": "GÜNYÜZÜ"
      },
      {
        "value": 1939,
        "key": "HAN"
      },
      {
        "value": 1808,
        "key": "İNÖNÜ"
      },
      {
        "value": 1508,
        "key": "MAHMUDİYE"
      },
      {
        "value": 1973,
        "key": "MİHALGAZİ"
      },
      {
        "value": 1527,
        "key": "MİHALIÇÇIK"
      },
      {
        "value": 2046,
        "key": "ODUNPAZARI"
      },
      {
        "value": 1599,
        "key": "SARICAKAYA"
      },
      {
        "value": 1618,
        "key": "SEYİTGAZİ"
      },
      {
        "value": 1632,
        "key": "SİVRİHİSAR"
      },
      {
        "value": 2047,
        "key": "TEPEBAŞI"
      }
    ]
  },
  {
    "value": 27,
    "key": "GAZİANTEP",
    "subOptions": [
      {
        "value": 1139,
        "key": "ARABAN"
      },
      {
        "value": 1415,
        "key": "İSLAHİYE"
      },
      {
        "value": 1956,
        "key": "KARKAMIŞ"
      },
      {
        "value": 1546,
        "key": "NİZİP"
      },
      {
        "value": 1974,
        "key": "NURDAĞI"
      },
      {
        "value": 1549,
        "key": "OĞUZELİ"
      },
      {
        "value": 1841,
        "key": "ŞAHİNBEY"
      },
      {
        "value": 1844,
        "key": "ŞEHİTKAMİL"
      },
      {
        "value": 1720,
        "key": "YAVUZELİ"
      }
    ]
  },
  {
    "value": 28,
    "key": "GİRESUN",
    "subOptions": [
      {
        "value": 1133,
        "key": "ALUCRA"
      },
      {
        "value": 1212,
        "key": "BULANCAK"
      },
      {
        "value": 1893,
        "key": "ÇAMOLUK"
      },
      {
        "value": 1894,
        "key": "ÇANAKÇI"
      },
      {
        "value": 1272,
        "key": "DERELİ"
      },
      {
        "value": 1912,
        "key": "DOĞANKENT"
      },
      {
        "value": 1320,
        "key": "ESPİYE"
      },
      {
        "value": 1324,
        "key": "EYNESİL"
      },
      {
        "value": 1361,
        "key": "GÖRELE"
      },
      {
        "value": 1930,
        "key": "GÜCE"
      },
      {
        "value": 1465,
        "key": "KEŞAP"
      },
      {
        "value": 1352,
        "key": "MERKEZ"
      },
      {
        "value": 1837,
        "key": "PİRAZİZ"
      },
      {
        "value": 1654,
        "key": "ŞEBİNKARAHİSAR"
      },
      {
        "value": 1678,
        "key": "TİREBOLU"
      },
      {
        "value": 1854,
        "key": "YAĞLIDERE"
      }
    ]
  },
  {
    "value": 29,
    "key": "GÜMÜŞHANE",
    "subOptions": [
      {
        "value": 1458,
        "key": "KELKİT"
      },
      {
        "value": 1822,
        "key": "KÖSE"
      },
      {
        "value": 1971,
        "key": "KÜRTÜN"
      },
      {
        "value": 1369,
        "key": "MERKEZ"
      },
      {
        "value": 1660,
        "key": "ŞİRAN"
      },
      {
        "value": 1684,
        "key": "TORUL"
      }
    ]
  },
  {
    "value": 30,
    "key": "HAKKARİ",
    "subOptions": [
      {
        "value": 1261,
        "key": "ÇUKURCA"
      },
      {
        "value": 2107,
        "key": "DERECİK"
      },
      {
        "value": 1377,
        "key": "MERKEZ"
      },
      {
        "value": 1656,
        "key": "ŞEMDİNLİ"
      },
      {
        "value": 1737,
        "key": "YÜKSEKOVA"
      }
    ]
  },
  {
    "value": 31,
    "key": "HATAY",
    "subOptions": [
      {
        "value": 1131,
        "key": "ALTINÖZÜ"
      },
      {
        "value": 2080,
        "key": "ANTAKYA"
      },
      {
        "value": 2081,
        "key": "ARSUZ"
      },
      {
        "value": 1887,
        "key": "BELEN"
      },
      {
        "value": 2082,
        "key": "DEFNE"
      },
      {
        "value": 1289,
        "key": "DÖRTYOL"
      },
      {
        "value": 1792,
        "key": "ERZİN"
      },
      {
        "value": 1382,
        "key": "HASSA"
      },
      {
        "value": 1413,
        "key": "İSKENDERUN"
      },
      {
        "value": 1468,
        "key": "KIRIKHAN"
      },
      {
        "value": 1970,
        "key": "KUMLU"
      },
      {
        "value": 2083,
        "key": "PAYAS"
      },
      {
        "value": 1585,
        "key": "REYHANLI"
      },
      {
        "value": 1591,
        "key": "SAMANDAĞ"
      },
      {
        "value": 1721,
        "key": "YAYLADAĞI"
      }
    ]
  },
  {
    "value": 76,
    "key": "IĞDIR",
    "subOptions": [
      {
        "value": 1142,
        "key": "ARALIK"
      },
      {
        "value": 2011,
        "key": "KARAKOYUNLU"
      },
      {
        "value": 1398,
        "key": "MERKEZ"
      },
      {
        "value": 1692,
        "key": "TUZLUCA"
      }
    ]
  },
  {
    "value": 32,
    "key": "ISPARTA",
    "subOptions": [
      {
        "value": 1755,
        "key": "AKSU"
      },
      {
        "value": 1154,
        "key": "ATABEY"
      },
      {
        "value": 1297,
        "key": "EĞİRDİR"
      },
      {
        "value": 1341,
        "key": "GELENDOST"
      },
      {
        "value": 1929,
        "key": "GÖNEN"
      },
      {
        "value": 1456,
        "key": "KEÇİBORLU"
      },
      {
        "value": 1401,
        "key": "MERKEZ"
      },
      {
        "value": 1651,
        "key": "ŞARKİKARAAĞAÇ"
      },
      {
        "value": 1615,
        "key": "SENİRKENT"
      },
      {
        "value": 1648,
        "key": "SÜTÇÜLER"
      },
      {
        "value": 1699,
        "key": "ULUBORLU"
      },
      {
        "value": 1717,
        "key": "YALVAÇ"
      },
      {
        "value": 2001,
        "key": "YENİŞARBADEMLİ"
      }
    ]
  },
  {
    "value": 34,
    "key": "İSTANBUL",
    "subOptions": [
      {
        "value": 1103,
        "key": "ADALAR"
      },
      {
        "value": 2048,
        "key": "ARNAVUTKÖY"
      },
      {
        "value": 2049,
        "key": "ATAŞEHİR"
      },
      {
        "value": 2003,
        "key": "AVCILAR"
      },
      {
        "value": 2004,
        "key": "BAĞCILAR"
      },
      {
        "value": 2005,
        "key": "BAHÇELİEVLER"
      },
      {
        "value": 1166,
        "key": "BAKIRKÖY"
      },
      {
        "value": 2050,
        "key": "BAŞAKŞEHİR"
      },
      {
        "value": 1886,
        "key": "BAYRAMPAŞA"
      },
      {
        "value": 1183,
        "key": "BEŞİKTAŞ"
      },
      {
        "value": 1185,
        "key": "BEYKOZ"
      },
      {
        "value": 2051,
        "key": "BEYLİKDÜZÜ"
      },
      {
        "value": 1186,
        "key": "BEYOĞLU"
      },
      {
        "value": 1782,
        "key": "BÜYÜKÇEKMECE"
      },
      {
        "value": 1237,
        "key": "ÇATALCA"
      },
      {
        "value": 2052,
        "key": "ÇEKMEKÖY"
      },
      {
        "value": 2016,
        "key": "ESENLER"
      },
      {
        "value": 2053,
        "key": "ESENYURT"
      },
      {
        "value": 1325,
        "key": "EYÜPSULTAN"
      },
      {
        "value": 1327,
        "key": "FATİH"
      },
      {
        "value": 1336,
        "key": "GAZİOSMANPAŞA"
      },
      {
        "value": 2010,
        "key": "GÜNGÖREN"
      },
      {
        "value": 1421,
        "key": "KADIKÖY"
      },
      {
        "value": 1810,
        "key": "KAĞITHANE"
      },
      {
        "value": 1449,
        "key": "KARTAL"
      },
      {
        "value": 1823,
        "key": "KÜÇÜKÇEKMECE"
      },
      {
        "value": 2012,
        "key": "MALTEPE"
      },
      {
        "value": 1835,
        "key": "PENDİK"
      },
      {
        "value": 2054,
        "key": "SANCAKTEPE"
      },
      {
        "value": 1604,
        "key": "SARIYER"
      },
      {
        "value": 1659,
        "key": "ŞİLE"
      },
      {
        "value": 1622,
        "key": "SİLİVRİ"
      },
      {
        "value": 1663,
        "key": "ŞİŞLİ"
      },
      {
        "value": 2014,
        "key": "SULTANBEYLİ"
      },
      {
        "value": 2055,
        "key": "SULTANGAZİ"
      },
      {
        "value": 2015,
        "key": "TUZLA"
      },
      {
        "value": 1852,
        "key": "ÜMRANİYE"
      },
      {
        "value": 1708,
        "key": "ÜSKÜDAR"
      },
      {
        "value": 1739,
        "key": "ZEYTİNBURNU"
      }
    ]
  },
  {
    "value": 35,
    "key": "İZMİR",
    "subOptions": [
      {
        "value": 1128,
        "key": "ALİAĞA"
      },
      {
        "value": 2006,
        "key": "BALÇOVA"
      },
      {
        "value": 1178,
        "key": "BAYINDIR"
      },
      {
        "value": 2056,
        "key": "BAYRAKLI"
      },
      {
        "value": 1181,
        "key": "BERGAMA"
      },
      {
        "value": 1776,
        "key": "BEYDAĞ"
      },
      {
        "value": 1203,
        "key": "BORNOVA"
      },
      {
        "value": 1780,
        "key": "BUCA"
      },
      {
        "value": 1251,
        "key": "ÇEŞME"
      },
      {
        "value": 2007,
        "key": "ÇİĞLİ"
      },
      {
        "value": 1280,
        "key": "DİKİLİ"
      },
      {
        "value": 1334,
        "key": "FOÇA"
      },
      {
        "value": 2009,
        "key": "GAZİEMİR"
      },
      {
        "value": 2018,
        "key": "GÜZELBAHÇE"
      },
      {
        "value": 2057,
        "key": "KARABAĞLAR"
      },
      {
        "value": 1432,
        "key": "KARABURUN"
      },
      {
        "value": 1448,
        "key": "KARŞIYAKA"
      },
      {
        "value": 1461,
        "key": "KEMALPAŞA"
      },
      {
        "value": 1467,
        "key": "KINIK"
      },
      {
        "value": 1477,
        "key": "KİRAZ"
      },
      {
        "value": 1819,
        "key": "KONAK"
      },
      {
        "value": 1826,
        "key": "MENDERES"
      },
      {
        "value": 1521,
        "key": "MENEMEN"
      },
      {
        "value": 2013,
        "key": "NARLIDERE"
      },
      {
        "value": 1563,
        "key": "ÖDEMİŞ"
      },
      {
        "value": 1611,
        "key": "SEFERİHİSAR"
      },
      {
        "value": 1612,
        "key": "SELÇUK"
      },
      {
        "value": 1677,
        "key": "TİRE"
      },
      {
        "value": 1682,
        "key": "TORBALI"
      },
      {
        "value": 1703,
        "key": "URLA"
      }
    ]
  },
  {
    "value": 46,
    "key": "KAHRAMANMARAŞ",
    "subOptions": [
      {
        "value": 1107,
        "key": "AFŞİN"
      },
      {
        "value": 1136,
        "key": "ANDIRIN"
      },
      {
        "value": 1785,
        "key": "ÇAĞLAYANCERİT"
      },
      {
        "value": 2084,
        "key": "DULKADİROĞLU"
      },
      {
        "value": 1919,
        "key": "EKİNÖZÜ"
      },
      {
        "value": 1299,
        "key": "ELBİSTAN"
      },
      {
        "value": 1353,
        "key": "GÖKSUN"
      },
      {
        "value": 1975,
        "key": "NURHAK"
      },
      {
        "value": 2085,
        "key": "ONİKİŞUBAT"
      },
      {
        "value": 1570,
        "key": "PAZARCIK"
      },
      {
        "value": 1694,
        "key": "TÜRKOĞLU"
      }
    ]
  },
  {
    "value": 78,
    "key": "KARABÜK",
    "subOptions": [
      {
        "value": 1296,
        "key": "EFLANİ"
      },
      {
        "value": 1321,
        "key": "ESKİPAZAR"
      },
      {
        "value": 1433,
        "key": "MERKEZ"
      },
      {
        "value": 1561,
        "key": "OVACIK"
      },
      {
        "value": 1587,
        "key": "SAFRANBOLU"
      },
      {
        "value": 1856,
        "key": "YENİCE"
      }
    ]
  },
  {
    "value": 70,
    "key": "KARAMAN",
    "subOptions": [
      {
        "value": 1768,
        "key": "AYRANCI"
      },
      {
        "value": 1884,
        "key": "BAŞYAYLA"
      },
      {
        "value": 1316,
        "key": "ERMENEK"
      },
      {
        "value": 1862,
        "key": "KAZIMKARABEKİR"
      },
      {
        "value": 1439,
        "key": "MERKEZ"
      },
      {
        "value": 1983,
        "key": "SARIVELİLER"
      }
    ]
  },
  {
    "value": 36,
    "key": "KARS",
    "subOptions": [
      {
        "value": 1756,
        "key": "AKYAKA"
      },
      {
        "value": 1149,
        "key": "ARPAÇAY"
      },
      {
        "value": 1279,
        "key": "DİGOR"
      },
      {
        "value": 1424,
        "key": "KAĞIZMAN"
      },
      {
        "value": 1447,
        "key": "MERKEZ"
      },
      {
        "value": 1601,
        "key": "SARIKAMIŞ"
      },
      {
        "value": 1614,
        "key": "SELİM"
      },
      {
        "value": 1645,
        "key": "SUSUZ"
      }
    ]
  },
  {
    "value": 37,
    "key": "KASTAMONU",
    "subOptions": [
      {
        "value": 1101,
        "key": "ABANA"
      },
      {
        "value": 1867,
        "key": "AĞLI"
      },
      {
        "value": 1140,
        "key": "ARAÇ"
      },
      {
        "value": 1162,
        "key": "AZDAVAY"
      },
      {
        "value": 1208,
        "key": "BOZKURT"
      },
      {
        "value": 1238,
        "key": "ÇATALZEYTİN"
      },
      {
        "value": 1221,
        "key": "CİDE"
      },
      {
        "value": 1264,
        "key": "DADAY"
      },
      {
        "value": 1277,
        "key": "DEVREKANİ"
      },
      {
        "value": 1915,
        "key": "DOĞANYURT"
      },
      {
        "value": 1940,
        "key": "HANÖNÜ"
      },
      {
        "value": 1805,
        "key": "İHSANGAZİ"
      },
      {
        "value": 1410,
        "key": "İNEBOLU"
      },
      {
        "value": 1499,
        "key": "KÜRE"
      },
      {
        "value": 1450,
        "key": "MERKEZ"
      },
      {
        "value": 1836,
        "key": "PINARBAŞI"
      },
      {
        "value": 1845,
        "key": "ŞENPAZAR"
      },
      {
        "value": 1984,
        "key": "SEYDİLER"
      },
      {
        "value": 1666,
        "key": "TAŞKÖPRÜ"
      },
      {
        "value": 1685,
        "key": "TOSYA"
      }
    ]
  },
  {
    "value": 38,
    "key": "KAYSERİ",
    "subOptions": [
      {
        "value": 1752,
        "key": "AKKIŞLA"
      },
      {
        "value": 1218,
        "key": "BÜNYAN"
      },
      {
        "value": 1275,
        "key": "DEVELİ"
      },
      {
        "value": 1330,
        "key": "FELAHİYE"
      },
      {
        "value": 1936,
        "key": "HACILAR"
      },
      {
        "value": 1409,
        "key": "İNCESU"
      },
      {
        "value": 1863,
        "key": "KOCASİNAN"
      },
      {
        "value": 1864,
        "key": "MELİKGAZİ"
      },
      {
        "value": 1978,
        "key": "ÖZVATAN"
      },
      {
        "value": 1576,
        "key": "PINARBAŞI"
      },
      {
        "value": 1603,
        "key": "SARIOĞLAN"
      },
      {
        "value": 1605,
        "key": "SARIZ"
      },
      {
        "value": 1846,
        "key": "TALAS"
      },
      {
        "value": 1680,
        "key": "TOMARZA"
      },
      {
        "value": 1715,
        "key": "YAHYALI"
      },
      {
        "value": 1727,
        "key": "YEŞİLHİSAR"
      }
    ]
  },
  {
    "value": 79,
    "key": "KİLİS",
    "subOptions": [
      {
        "value": 2023,
        "key": "ELBEYLİ"
      },
      {
        "value": 1476,
        "key": "MERKEZ"
      },
      {
        "value": 2024,
        "key": "MUSABEYLİ"
      },
      {
        "value": 2025,
        "key": "POLATELİ"
      }
    ]
  },
  {
    "value": 71,
    "key": "KIRIKKALE",
    "subOptions": [
      {
        "value": 1880,
        "key": "BAHŞİLİ"
      },
      {
        "value": 1882,
        "key": "BALIŞEYH"
      },
      {
        "value": 1901,
        "key": "ÇELEBİ"
      },
      {
        "value": 1268,
        "key": "DELİCE"
      },
      {
        "value": 1954,
        "key": "KARAKEÇİLİ"
      },
      {
        "value": 1463,
        "key": "KESKİN"
      },
      {
        "value": 1469,
        "key": "MERKEZ"
      },
      {
        "value": 1638,
        "key": "SULAKYURT"
      },
      {
        "value": 1992,
        "key": "YAHŞİHAN"
      }
    ]
  },
  {
    "value": 39,
    "key": "KIRKLARELİ",
    "subOptions": [
      {
        "value": 1163,
        "key": "BABAESKİ"
      },
      {
        "value": 1270,
        "key": "DEMİRKÖY"
      },
      {
        "value": 1480,
        "key": "KOFÇAZ"
      },
      {
        "value": 1505,
        "key": "LÜLEBURGAZ"
      },
      {
        "value": 1471,
        "key": "MERKEZ"
      },
      {
        "value": 1572,
        "key": "PEHLİVANKÖY"
      },
      {
        "value": 1577,
        "key": "PINARHİSAR"
      },
      {
        "value": 1714,
        "key": "VİZE"
      }
    ]
  },
  {
    "value": 40,
    "key": "KIRŞEHİR",
    "subOptions": [
      {
        "value": 1869,
        "key": "AKÇAKENT"
      },
      {
        "value": 1754,
        "key": "AKPINAR"
      },
      {
        "value": 1890,
        "key": "BOZTEPE"
      },
      {
        "value": 1254,
        "key": "ÇİÇEKDAĞI"
      },
      {
        "value": 1429,
        "key": "KAMAN"
      },
      {
        "value": 1472,
        "key": "MERKEZ"
      },
      {
        "value": 1529,
        "key": "MUCUR"
      }
    ]
  },
  {
    "value": 41,
    "key": "KOCAELİ",
    "subOptions": [
      {
        "value": 2058,
        "key": "BAŞİSKELE"
      },
      {
        "value": 2059,
        "key": "ÇAYIROVA"
      },
      {
        "value": 2060,
        "key": "DARICA"
      },
      {
        "value": 2030,
        "key": "DERİNCE"
      },
      {
        "value": 2061,
        "key": "DİLOVASI"
      },
      {
        "value": 1338,
        "key": "GEBZE"
      },
      {
        "value": 1355,
        "key": "GÖLCÜK"
      },
      {
        "value": 2062,
        "key": "İZMİT"
      },
      {
        "value": 1430,
        "key": "KANDIRA"
      },
      {
        "value": 1440,
        "key": "KARAMÜRSEL"
      },
      {
        "value": 2063,
        "key": "KARTEPE"
      },
      {
        "value": 1821,
        "key": "KÖRFEZ"
      }
    ]
  },
  {
    "value": 42,
    "key": "KONYA",
    "subOptions": [
      {
        "value": 1868,
        "key": "AHIRLI"
      },
      {
        "value": 1753,
        "key": "AKÖREN"
      },
      {
        "value": 1122,
        "key": "AKŞEHİR"
      },
      {
        "value": 1760,
        "key": "ALTINEKİN"
      },
      {
        "value": 1188,
        "key": "BEYŞEHİR"
      },
      {
        "value": 1207,
        "key": "BOZKIR"
      },
      {
        "value": 1902,
        "key": "ÇELTİK"
      },
      {
        "value": 1222,
        "key": "CİHANBEYLİ"
      },
      {
        "value": 1262,
        "key": "ÇUMRA"
      },
      {
        "value": 1907,
        "key": "DERBENT"
      },
      {
        "value": 1789,
        "key": "DEREBUCAK"
      },
      {
        "value": 1285,
        "key": "DOĞANHİSAR"
      },
      {
        "value": 1920,
        "key": "EMİRGAZİ"
      },
      {
        "value": 1312,
        "key": "EREĞLİ"
      },
      {
        "value": 1933,
        "key": "GÜNEYSINIR"
      },
      {
        "value": 1375,
        "key": "HADİM"
      },
      {
        "value": 1937,
        "key": "HALKAPINAR"
      },
      {
        "value": 1804,
        "key": "HÜYÜK"
      },
      {
        "value": 1400,
        "key": "ILGIN"
      },
      {
        "value": 1422,
        "key": "KADINHANI"
      },
      {
        "value": 1441,
        "key": "KARAPINAR"
      },
      {
        "value": 1814,
        "key": "KARATAY"
      },
      {
        "value": 1491,
        "key": "KULU"
      },
      {
        "value": 1827,
        "key": "MERAM"
      },
      {
        "value": 1598,
        "key": "SARAYÖNÜ"
      },
      {
        "value": 1839,
        "key": "SELÇUKLU"
      },
      {
        "value": 1617,
        "key": "SEYDİŞEHİR"
      },
      {
        "value": 1848,
        "key": "TAŞKENT"
      },
      {
        "value": 1990,
        "key": "TUZLUKÇU"
      },
      {
        "value": 1994,
        "key": "YALIHÜYÜK"
      },
      {
        "value": 1735,
        "key": "YUNAK"
      }
    ]
  },
  {
    "value": 43,
    "key": "KÜTAHYA",
    "subOptions": [
      {
        "value": 1132,
        "key": "ALTINTAŞ"
      },
      {
        "value": 1764,
        "key": "ASLANAPA"
      },
      {
        "value": 1898,
        "key": "ÇAVDARHİSAR"
      },
      {
        "value": 1288,
        "key": "DOMANİÇ"
      },
      {
        "value": 1790,
        "key": "DUMLUPINAR"
      },
      {
        "value": 1304,
        "key": "EMET"
      },
      {
        "value": 1339,
        "key": "GEDİZ"
      },
      {
        "value": 1802,
        "key": "HİSARCIK"
      },
      {
        "value": 1500,
        "key": "MERKEZ"
      },
      {
        "value": 1979,
        "key": "PAZARLAR"
      },
      {
        "value": 1843,
        "key": "ŞAPHANE"
      },
      {
        "value": 1625,
        "key": "SİMAV"
      },
      {
        "value": 1671,
        "key": "TAVŞANLI"
      }
    ]
  },
  {
    "value": 44,
    "key": "MALATYA",
    "subOptions": [
      {
        "value": 1114,
        "key": "AKÇADAĞ"
      },
      {
        "value": 1143,
        "key": "ARAPGİR"
      },
      {
        "value": 1148,
        "key": "ARGUVAN"
      },
      {
        "value": 1772,
        "key": "BATTALGAZİ"
      },
      {
        "value": 1265,
        "key": "DARENDE"
      },
      {
        "value": 1286,
        "key": "DOĞANŞEHİR"
      },
      {
        "value": 1914,
        "key": "DOĞANYOL"
      },
      {
        "value": 1390,
        "key": "HEKİMHAN"
      },
      {
        "value": 1953,
        "key": "KALE"
      },
      {
        "value": 1969,
        "key": "KULUNCAK"
      },
      {
        "value": 1582,
        "key": "PÜTÜRGE"
      },
      {
        "value": 1995,
        "key": "YAZIHAN"
      },
      {
        "value": 1729,
        "key": "YEŞİLYURT"
      }
    ]
  },
  {
    "value": 45,
    "key": "MANİSA",
    "subOptions": [
      {
        "value": 1751,
        "key": "AHMETLİ"
      },
      {
        "value": 1118,
        "key": "AKHİSAR"
      },
      {
        "value": 1127,
        "key": "ALAŞEHİR"
      },
      {
        "value": 1269,
        "key": "DEMİRCİ"
      },
      {
        "value": 1793,
        "key": "GÖLMARMARA"
      },
      {
        "value": 1362,
        "key": "GÖRDES"
      },
      {
        "value": 1470,
        "key": "KIRKAĞAÇ"
      },
      {
        "value": 1965,
        "key": "KÖPRÜBAŞI"
      },
      {
        "value": 1489,
        "key": "KULA"
      },
      {
        "value": 1590,
        "key": "SALİHLİ"
      },
      {
        "value": 1600,
        "key": "SARIGÖL"
      },
      {
        "value": 1606,
        "key": "SARUHANLI"
      },
      {
        "value": 2086,
        "key": "ŞEHZADELER"
      },
      {
        "value": 1613,
        "key": "SELENDİ"
      },
      {
        "value": 1634,
        "key": "SOMA"
      },
      {
        "value": 1689,
        "key": "TURGUTLU"
      },
      {
        "value": 2087,
        "key": "YUNUSEMRE"
      }
    ]
  },
  {
    "value": 47,
    "key": "MARDİN",
    "subOptions": [
      {
        "value": 2088,
        "key": "ARTUKLU"
      },
      {
        "value": 1787,
        "key": "DARGEÇİT"
      },
      {
        "value": 1273,
        "key": "DERİK"
      },
      {
        "value": 1474,
        "key": "KIZILTEPE"
      },
      {
        "value": 1519,
        "key": "MAZIDAĞI"
      },
      {
        "value": 1526,
        "key": "MİDYAT"
      },
      {
        "value": 1547,
        "key": "NUSAYBİN"
      },
      {
        "value": 1564,
        "key": "ÖMERLİ"
      },
      {
        "value": 1609,
        "key": "SAVUR"
      },
      {
        "value": 2002,
        "key": "YEŞİLLİ"
      }
    ]
  },
  {
    "value": 33,
    "key": "MERSİN",
    "subOptions": [
      {
        "value": 2064,
        "key": "AKDENİZ"
      },
      {
        "value": 1135,
        "key": "ANAMUR"
      },
      {
        "value": 1766,
        "key": "AYDINCIK"
      },
      {
        "value": 1779,
        "key": "BOZYAZI"
      },
      {
        "value": 1892,
        "key": "ÇAMLIYAYLA"
      },
      {
        "value": 1311,
        "key": "ERDEMLİ"
      },
      {
        "value": 1366,
        "key": "GÜLNAR"
      },
      {
        "value": 2065,
        "key": "MEZİTLİ"
      },
      {
        "value": 1536,
        "key": "MUT"
      },
      {
        "value": 1621,
        "key": "SİLİFKE"
      },
      {
        "value": 1665,
        "key": "TARSUS"
      },
      {
        "value": 2066,
        "key": "TOROSLAR"
      },
      {
        "value": 2067,
        "key": "YENİŞEHİR"
      }
    ]
  },
  {
    "value": 48,
    "key": "MUĞLA",
    "subOptions": [
      {
        "value": 1197,
        "key": "BODRUM"
      },
      {
        "value": 1742,
        "key": "DALAMAN"
      },
      {
        "value": 1266,
        "key": "DATÇA"
      },
      {
        "value": 1331,
        "key": "FETHİYE"
      },
      {
        "value": 1958,
        "key": "KAVAKLIDERE"
      },
      {
        "value": 1488,
        "key": "KÖYCEĞİZ"
      },
      {
        "value": 1517,
        "key": "MARMARİS"
      },
      {
        "value": 2089,
        "key": "MENTEŞE"
      },
      {
        "value": 1528,
        "key": "MİLAS"
      },
      {
        "value": 1831,
        "key": "ORTACA"
      },
      {
        "value": 2090,
        "key": "SEYDİKEMER"
      },
      {
        "value": 1695,
        "key": "ULA"
      },
      {
        "value": 1719,
        "key": "YATAĞAN"
      }
    ]
  },
  {
    "value": 49,
    "key": "MUŞ",
    "subOptions": [
      {
        "value": 1213,
        "key": "BULANIK"
      },
      {
        "value": 1801,
        "key": "HASKÖY"
      },
      {
        "value": 1964,
        "key": "KORKUT"
      },
      {
        "value": 1510,
        "key": "MALAZGİRT"
      },
      {
        "value": 1534,
        "key": "MERKEZ"
      },
      {
        "value": 1711,
        "key": "VARTO"
      }
    ]
  },
  {
    "value": 50,
    "key": "NEVŞEHİR",
    "subOptions": [
      {
        "value": 1749,
        "key": "ACIGÖL"
      },
      {
        "value": 1155,
        "key": "AVANOS"
      },
      {
        "value": 1274,
        "key": "DERİNKUYU"
      },
      {
        "value": 1367,
        "key": "GÜLŞEHİR"
      },
      {
        "value": 1374,
        "key": "HACIBEKTAŞ"
      },
      {
        "value": 1485,
        "key": "KOZAKLI"
      },
      {
        "value": 1543,
        "key": "MERKEZ"
      },
      {
        "value": 1707,
        "key": "ÜRGÜP"
      }
    ]
  },
  {
    "value": 51,
    "key": "NİĞDE",
    "subOptions": [
      {
        "value": 1876,
        "key": "ALTUNHİSAR"
      },
      {
        "value": 1201,
        "key": "BOR"
      },
      {
        "value": 1225,
        "key": "ÇAMARDI"
      },
      {
        "value": 1904,
        "key": "ÇİFTLİK"
      },
      {
        "value": 1544,
        "key": "MERKEZ"
      },
      {
        "value": 1700,
        "key": "ULUKIŞLA"
      }
    ]
  },
  {
    "value": 52,
    "key": "ORDU",
    "subOptions": [
      {
        "value": 1119,
        "key": "AKKUŞ"
      },
      {
        "value": 2103,
        "key": "ALTINORDU"
      },
      {
        "value": 1158,
        "key": "AYBASTI"
      },
      {
        "value": 1891,
        "key": "ÇAMAŞ"
      },
      {
        "value": 1897,
        "key": "ÇATALPINAR"
      },
      {
        "value": 1900,
        "key": "ÇAYBAŞI"
      },
      {
        "value": 1328,
        "key": "FATSA"
      },
      {
        "value": 1358,
        "key": "GÖLKÖY"
      },
      {
        "value": 1795,
        "key": "GÜLYALI"
      },
      {
        "value": 1797,
        "key": "GÜRGENTEPE"
      },
      {
        "value": 1947,
        "key": "İKİZCE"
      },
      {
        "value": 1950,
        "key": "KABADÜZ"
      },
      {
        "value": 1951,
        "key": "KABATAŞ"
      },
      {
        "value": 1482,
        "key": "KORGAN"
      },
      {
        "value": 1493,
        "key": "KUMRU"
      },
      {
        "value": 1525,
        "key": "MESUDİYE"
      },
      {
        "value": 1573,
        "key": "PERŞEMBE"
      },
      {
        "value": 1696,
        "key": "ULUBEY"
      },
      {
        "value": 1706,
        "key": "ÜNYE"
      }
    ]
  },
  {
    "value": 80,
    "key": "OSMANİYE",
    "subOptions": [
      {
        "value": 1165,
        "key": "BAHÇE"
      },
      {
        "value": 1743,
        "key": "DÜZİÇİ"
      },
      {
        "value": 2027,
        "key": "HASANBEYLİ"
      },
      {
        "value": 1423,
        "key": "KADİRLİ"
      },
      {
        "value": 1560,
        "key": "MERKEZ"
      },
      {
        "value": 2028,
        "key": "SUMBAS"
      },
      {
        "value": 2029,
        "key": "TOPRAKKALE"
      }
    ]
  },
  {
    "value": 53,
    "key": "RİZE",
    "subOptions": [
      {
        "value": 1146,
        "key": "ARDEŞEN"
      },
      {
        "value": 1228,
        "key": "ÇAMLIHEMŞİN"
      },
      {
        "value": 1241,
        "key": "ÇAYELİ"
      },
      {
        "value": 1908,
        "key": "DEREPAZARI"
      },
      {
        "value": 1332,
        "key": "FINDIKLI"
      },
      {
        "value": 1796,
        "key": "GÜNEYSU"
      },
      {
        "value": 1943,
        "key": "HEMŞİN"
      },
      {
        "value": 1405,
        "key": "İKİZDERE"
      },
      {
        "value": 1949,
        "key": "İYİDERE"
      },
      {
        "value": 1428,
        "key": "KALKANDERE"
      },
      {
        "value": 1586,
        "key": "MERKEZ"
      },
      {
        "value": 1569,
        "key": "PAZAR"
      }
    ]
  },
  {
    "value": 54,
    "key": "SAKARYA",
    "subOptions": [
      {
        "value": 2068,
        "key": "ADAPAZARI"
      },
      {
        "value": 1123,
        "key": "AKYAZI"
      },
      {
        "value": 2069,
        "key": "ARİFİYE"
      },
      {
        "value": 2070,
        "key": "ERENLER"
      },
      {
        "value": 1925,
        "key": "FERİZLİ"
      },
      {
        "value": 1351,
        "key": "GEYVE"
      },
      {
        "value": 1391,
        "key": "HENDEK"
      },
      {
        "value": 1955,
        "key": "KARAPÜRÇEK"
      },
      {
        "value": 1442,
        "key": "KARASU"
      },
      {
        "value": 1453,
        "key": "KAYNARCA"
      },
      {
        "value": 1818,
        "key": "KOCAALİ"
      },
      {
        "value": 1833,
        "key": "PAMUKOVA"
      },
      {
        "value": 1595,
        "key": "SAPANCA"
      },
      {
        "value": 2071,
        "key": "SERDİVAN"
      },
      {
        "value": 1986,
        "key": "SÖĞÜTLÜ"
      },
      {
        "value": 1847,
        "key": "TARAKLI"
      }
    ]
  },
  {
    "value": 55,
    "key": "SAMSUN",
    "subOptions": [
      {
        "value": 1830,
        "key": "19MAYIS"
      },
      {
        "value": 1125,
        "key": "ALAÇAM"
      },
      {
        "value": 1763,
        "key": "ASARCIK"
      },
      {
        "value": 2072,
        "key": "ATAKUM"
      },
      {
        "value": 1879,
        "key": "AYVACIK"
      },
      {
        "value": 1164,
        "key": "BAFRA"
      },
      {
        "value": 2073,
        "key": "CANİK"
      },
      {
        "value": 1234,
        "key": "ÇARŞAMBA"
      },
      {
        "value": 1386,
        "key": "HAVZA"
      },
      {
        "value": 2074,
        "key": "İLKADIM"
      },
      {
        "value": 1452,
        "key": "KAVAK"
      },
      {
        "value": 1501,
        "key": "LADİK"
      },
      {
        "value": 1838,
        "key": "SALIPAZARI"
      },
      {
        "value": 1849,
        "key": "TEKKEKÖY"
      },
      {
        "value": 1676,
        "key": "TERME"
      },
      {
        "value": 1712,
        "key": "VEZİRKÖPRÜ"
      },
      {
        "value": 1993,
        "key": "YAKAKENT"
      }
    ]
  },
  {
    "value": 63,
    "key": "ŞANLIURFA",
    "subOptions": [
      {
        "value": 1115,
        "key": "AKÇAKALE"
      },
      {
        "value": 1194,
        "key": "BİRECİK"
      },
      {
        "value": 1209,
        "key": "BOZOVA"
      },
      {
        "value": 1220,
        "key": "CEYLANPINAR"
      },
      {
        "value": 2091,
        "key": "EYYÜBİYE"
      },
      {
        "value": 1378,
        "key": "HALFETİ"
      },
      {
        "value": 2092,
        "key": "HALİLİYE"
      },
      {
        "value": 1800,
        "key": "HARRAN"
      },
      {
        "value": 1393,
        "key": "HİLVAN"
      },
      {
        "value": 2093,
        "key": "KARAKÖPRÜ"
      },
      {
        "value": 1630,
        "key": "SİVEREK"
      },
      {
        "value": 1643,
        "key": "SURUÇ"
      },
      {
        "value": 1713,
        "key": "VİRANŞEHİR"
      }
    ]
  },
  {
    "value": 56,
    "key": "SİİRT",
    "subOptions": [
      {
        "value": 1179,
        "key": "BAYKAN"
      },
      {
        "value": 1317,
        "key": "ERUH"
      },
      {
        "value": 1495,
        "key": "KURTALAN"
      },
      {
        "value": 1620,
        "key": "MERKEZ"
      },
      {
        "value": 1575,
        "key": "PERVARİ"
      },
      {
        "value": 1662,
        "key": "ŞİRVAN"
      },
      {
        "value": 1878,
        "key": "TİLLO"
      }
    ]
  },
  {
    "value": 57,
    "key": "SİNOP",
    "subOptions": [
      {
        "value": 1156,
        "key": "AYANCIK"
      },
      {
        "value": 1204,
        "key": "BOYABAT"
      },
      {
        "value": 1910,
        "key": "DİKMEN"
      },
      {
        "value": 1290,
        "key": "DURAĞAN"
      },
      {
        "value": 1314,
        "key": "ERFELEK"
      },
      {
        "value": 1349,
        "key": "GERZE"
      },
      {
        "value": 1627,
        "key": "MERKEZ"
      },
      {
        "value": 1981,
        "key": "SARAYDÜZÜ"
      },
      {
        "value": 1693,
        "key": "TÜRKELİ"
      }
    ]
  },
  {
    "value": 73,
    "key": "ŞIRNAK",
    "subOptions": [
      {
        "value": 1189,
        "key": "BEYTÜŞŞEBAP"
      },
      {
        "value": 1223,
        "key": "CİZRE"
      },
      {
        "value": 1931,
        "key": "GÜÇLÜKONAK"
      },
      {
        "value": 1403,
        "key": "İDİL"
      },
      {
        "value": 1661,
        "key": "MERKEZ"
      },
      {
        "value": 1623,
        "key": "SİLOPİ"
      },
      {
        "value": 1698,
        "key": "ULUDERE"
      }
    ]
  },
  {
    "value": 58,
    "key": "SİVAS",
    "subOptions": [
      {
        "value": 1870,
        "key": "AKINCILAR"
      },
      {
        "value": 1875,
        "key": "ALTINYAYLA"
      },
      {
        "value": 1282,
        "key": "DİVRİĞİ"
      },
      {
        "value": 1913,
        "key": "DOĞANŞAR"
      },
      {
        "value": 1342,
        "key": "GEMEREK"
      },
      {
        "value": 1927,
        "key": "GÖLOVA"
      },
      {
        "value": 1373,
        "key": "GÜRÜN"
      },
      {
        "value": 1376,
        "key": "HAFİK"
      },
      {
        "value": 1407,
        "key": "İMRANLI"
      },
      {
        "value": 1431,
        "key": "KANGAL"
      },
      {
        "value": 1484,
        "key": "KOYULHİSAR"
      },
      {
        "value": 1628,
        "key": "MERKEZ"
      },
      {
        "value": 1650,
        "key": "ŞARKIŞLA"
      },
      {
        "value": 1646,
        "key": "SUŞEHRİ"
      },
      {
        "value": 1991,
        "key": "ULAŞ"
      },
      {
        "value": 1731,
        "key": "YILDIZELİ"
      },
      {
        "value": 1738,
        "key": "ZARA"
      }
    ]
  },
  {
    "value": 59,
    "key": "TEKİRDAĞ",
    "subOptions": [
      {
        "value": 1250,
        "key": "ÇERKEZKÖY"
      },
      {
        "value": 1258,
        "key": "ÇORLU"
      },
      {
        "value": 2094,
        "key": "ERGENE"
      },
      {
        "value": 1388,
        "key": "HAYRABOLU"
      },
      {
        "value": 2095,
        "key": "KAPAKLI"
      },
      {
        "value": 1511,
        "key": "MALKARA"
      },
      {
        "value": 1825,
        "key": "MARMARAEREĞLİSİ"
      },
      {
        "value": 1538,
        "key": "MURATLI"
      },
      {
        "value": 1596,
        "key": "SARAY"
      },
      {
        "value": 1652,
        "key": "ŞARKÖY"
      },
      {
        "value": 2096,
        "key": "SÜLEYMANPAŞA"
      }
    ]
  },
  {
    "value": 60,
    "key": "TOKAT",
    "subOptions": [
      {
        "value": 1129,
        "key": "ALMUS"
      },
      {
        "value": 1151,
        "key": "ARTOVA"
      },
      {
        "value": 1883,
        "key": "BAŞÇİFTLİK"
      },
      {
        "value": 1308,
        "key": "ERBAA"
      },
      {
        "value": 1679,
        "key": "MERKEZ"
      },
      {
        "value": 1545,
        "key": "NİKSAR"
      },
      {
        "value": 1834,
        "key": "PAZAR"
      },
      {
        "value": 1584,
        "key": "REŞADİYE"
      },
      {
        "value": 1987,
        "key": "SULUSARAY"
      },
      {
        "value": 1690,
        "key": "TURHAL"
      },
      {
        "value": 1858,
        "key": "YEŞİLYURT"
      },
      {
        "value": 1740,
        "key": "ZİLE"
      }
    ]
  },
  {
    "value": 61,
    "key": "TRABZON",
    "subOptions": [
      {
        "value": 1113,
        "key": "AKÇAABAT"
      },
      {
        "value": 1141,
        "key": "ARAKLI"
      },
      {
        "value": 1150,
        "key": "ARSİN"
      },
      {
        "value": 1775,
        "key": "BEŞİKDÜZÜ"
      },
      {
        "value": 1896,
        "key": "ÇARŞIBAŞI"
      },
      {
        "value": 1244,
        "key": "ÇAYKARA"
      },
      {
        "value": 1909,
        "key": "DERNEKPAZARI"
      },
      {
        "value": 1917,
        "key": "DÜZKÖY"
      },
      {
        "value": 1942,
        "key": "HAYRAT"
      },
      {
        "value": 1966,
        "key": "KÖPRÜBAŞI"
      },
      {
        "value": 1507,
        "key": "MAÇKA"
      },
      {
        "value": 1548,
        "key": "OF"
      },
      {
        "value": 2097,
        "key": "ORTAHİSAR"
      },
      {
        "value": 1842,
        "key": "ŞALPAZARI"
      },
      {
        "value": 1647,
        "key": "SÜRMENE"
      },
      {
        "value": 1681,
        "key": "TONYA"
      },
      {
        "value": 1709,
        "key": "VAKFIKEBİR"
      },
      {
        "value": 1732,
        "key": "YOMRA"
      }
    ]
  },
  {
    "value": 62,
    "key": "TUNCELİ",
    "subOptions": [
      {
        "value": 1247,
        "key": "ÇEMİŞGEZEK"
      },
      {
        "value": 1397,
        "key": "HOZAT"
      },
      {
        "value": 1518,
        "key": "MAZGİRT"
      },
      {
        "value": 1688,
        "key": "MERKEZ"
      },
      {
        "value": 1541,
        "key": "NAZIMİYE"
      },
      {
        "value": 1562,
        "key": "OVACIK"
      },
      {
        "value": 1574,
        "key": "PERTEK"
      },
      {
        "value": 1581,
        "key": "PÜLÜMÜR"
      }
    ]
  },
  {
    "value": 64,
    "key": "UŞAK",
    "subOptions": [
      {
        "value": 1170,
        "key": "BANAZ"
      },
      {
        "value": 1323,
        "key": "EŞME"
      },
      {
        "value": 1436,
        "key": "KARAHALLI"
      },
      {
        "value": 1704,
        "key": "MERKEZ"
      },
      {
        "value": 1629,
        "key": "SİVASLI"
      },
      {
        "value": 1697,
        "key": "ULUBEY"
      }
    ]
  },
  {
    "value": 65,
    "key": "VAN",
    "subOptions": [
      {
        "value": 1770,
        "key": "BAHÇESARAY"
      },
      {
        "value": 1175,
        "key": "BAŞKALE"
      },
      {
        "value": 1786,
        "key": "ÇALDIRAN"
      },
      {
        "value": 1236,
        "key": "ÇATAK"
      },
      {
        "value": 1918,
        "key": "EDREMİT"
      },
      {
        "value": 1309,
        "key": "ERCİŞ"
      },
      {
        "value": 1350,
        "key": "GEVAŞ"
      },
      {
        "value": 1372,
        "key": "GÜRPINAR"
      },
      {
        "value": 2098,
        "key": "İPEKYOLU"
      },
      {
        "value": 1533,
        "key": "MURADİYE"
      },
      {
        "value": 1565,
        "key": "ÖZALP"
      },
      {
        "value": 1980,
        "key": "SARAY"
      },
      {
        "value": 2099,
        "key": "TUŞBA"
      }
    ]
  },
  {
    "value": 77,
    "key": "YALOVA",
    "subOptions": [
      {
        "value": 2019,
        "key": "ALTINOVA"
      },
      {
        "value": 2020,
        "key": "ARMUTLU"
      },
      {
        "value": 2022,
        "key": "ÇİFTLİKKÖY"
      },
      {
        "value": 2021,
        "key": "ÇINARCIK"
      },
      {
        "value": 1716,
        "key": "MERKEZ"
      },
      {
        "value": 2026,
        "key": "TERMAL"
      }
    ]
  },
  {
    "value": 66,
    "key": "YOZGAT",
    "subOptions": [
      {
        "value": 1117,
        "key": "AKDAĞMADENİ"
      },
      {
        "value": 1877,
        "key": "AYDINCIK"
      },
      {
        "value": 1198,
        "key": "BOĞAZLIYAN"
      },
      {
        "value": 1895,
        "key": "ÇANDIR"
      },
      {
        "value": 1242,
        "key": "ÇAYIRALAN"
      },
      {
        "value": 1245,
        "key": "ÇEKEREK"
      },
      {
        "value": 1952,
        "key": "KADIŞEHRİ"
      },
      {
        "value": 1733,
        "key": "MERKEZ"
      },
      {
        "value": 1982,
        "key": "SARAYKENT"
      },
      {
        "value": 1602,
        "key": "SARIKAYA"
      },
      {
        "value": 1655,
        "key": "ŞEFAATLİ"
      },
      {
        "value": 1635,
        "key": "SORGUN"
      },
      {
        "value": 1998,
        "key": "YENİFAKILI"
      },
      {
        "value": 1726,
        "key": "YERKÖY"
      }
    ]
  },
  {
    "value": 67,
    "key": "ZONGULDAK",
    "subOptions": [
      {
        "value": 1758,
        "key": "ALAPLI"
      },
      {
        "value": 1240,
        "key": "ÇAYCUMA"
      },
      {
        "value": 1276,
        "key": "DEVREK"
      },
      {
        "value": 1313,
        "key": "EREĞLİ"
      },
      {
        "value": 1926,
        "key": "GÖKÇEBEY"
      },
      {
        "value": 2100,
        "key": "KİLİMLİ"
      },
      {
        "value": 2101,
        "key": "KOZLU"
      },
      {
        "value": 1741,
        "key": "MERKEZ"
      }
    ]
  }
]
